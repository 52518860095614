interface ILabel {
  name: string;
  type?: "coming-soon" | "hot";
}

export const Label = (props: ILabel) => {
  const { name, type = "hot" } = props;

  const bgColor = () => {
    if (type === "coming-soon") return "#737373";
    if (type === "hot") return "#D40C48";
    return "#D40C48";
  };

  const borderColor = () => {
    if (type === "coming-soon") return "#F7C926";
    if (type === "hot") return "#FF6D99";
    return "#FF6D99";
  };

  return (
    <div
      className="absolute top-[10px] right-[0px] rounded-tl-xxs rounded-tr-s rounded-bl-s rounded-br-xxs px-space-2"
      style={{
        backgroundColor: bgColor(),
        boxShadow: `0 0 10px ${borderColor}, 0 0 20px ${borderColor}, inset 0 0 5px ${borderColor}`,
        border: `1px solid ${borderColor}`,
        animation: "glowAnimation 2s ease-in-out infinite alternate",
        transform: "rotate(15deg)",
      }}
    >
      <p className="text-white text-xs font-[700]">{name}</p>
    </div>
  );
};
