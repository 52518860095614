export interface IRarityColorObj {
  legendary: string,
  epic: string,
  rare: string,
  uncommon: string,
  common: string,
}

export const rarityColorObj = {
  'legendary': '#FF134B',
  'epic': '#A218EE',
  'rare': '#009CFF',
  'uncommon': '#00C78F',
  'common': '#749BC1',
}

export const getRarityColor = (rarity: string) => {
  const formattedRarity = rarity.toLowerCase() as keyof IRarityColorObj;
  const color = rarityColorObj[formattedRarity] || "#FFF";

  return color;
};
