import { IMysteryBoxItem } from "src/types/mysteryBox";
import { CaseOpeningMysteryBoxItem } from "./case-opening-mystery-box-item";
import clsx from "clsx";
interface ICaseOpeningMysteryBoxItemList {
  className?: string;
  layoutClassName?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  items?: IMysteryBoxItem[] | null;
  type?: string | null;
}

const CaseOpeningMysteryBoxItemList = ({
  className,
  layoutClassName,
  title,
  children,
  items = [],
  type = null,
}: ICaseOpeningMysteryBoxItemList) => {
  return (
    <section className={clsx(className)}>
      {title && (
        <div className="text-l md:text-xxxxxl text-grey-600 blender-medium font-[700] leading-none mb-space-4">
          {title}
        </div>
      )}
      {children || (
        <div className={clsx(layoutClassName, "grid gap-space-2")}>
          {items?.map((item, i) => {
            return (
              <CaseOpeningMysteryBoxItem
                key={i}
                item={item}
                type={type}
                className="h-[148px] sm:h-[192px] md:h-auto md:aspect-square"
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

export { CaseOpeningMysteryBoxItemList };
