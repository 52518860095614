import { ContainerV3 } from "src/components/container-v3";
import { LandingBanner } from "src/components/landing-banner";
import { LandingEarn } from "src/components/landing-earn";
import { LandingHotGames } from "src/components/landing-hot-games";
import { LandingMarketplace } from "src/components/landing-marketplace";
import { LandingMission } from "src/components/landing-mission";
import { LandingNews } from "src/components/landing-news";
import { LandingPartners } from "src/components/landing-partners";
import { LandingPredict } from "src/components/landing-predict";
import { LandingRaffles } from "src/components/landing-raffles";
import { useWindowSize } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";
import { LandingBannerCaseOpen } from "src/components/landing-banner-case-open";

export const HomeV4 = () => {
  const size: any = useWindowSize();

  return (
    <ContainerV3 withBg={!(isMobile || size?.width < 1080)} isFixedNav={true}>
      <div className="block sm:hidden relative z-[2] px-space-2">
        <LandingBannerCaseOpen />
      </div>
      <LandingBanner />
      <div className="relative mx-space-2 md:mx-space-4 z-[2]">
        <LandingHotGames />
        <div className="md:flex md:items-start md:items-stretch md:flex-row md:mb-space-4">
          <div className="md:flex-[1]">
            <LandingPredict />
          </div>
          <div className="md:flex-[2]">
            <LandingRaffles />
          </div>
        </div>
        <div className="md:flex md:items-start md:items-stretch md:flex-row md:mb-space-4">
          <div className="md:flex-[1]">
            <LandingMarketplace />
          </div>
          <div className="md:flex-[1]">
            <LandingEarn />
          </div>
          <div className="md:flex-[1]">
            <LandingMission />
          </div>
        </div>
        <LandingPartners />
        <LandingNews />
      </div>
    </ContainerV3>
  );
};
