import { useCallback, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useGameStore } from "src/stores";
import { RouteComponentProps } from "react-router";
import { ContainerV3 } from "src/components/container-v3";
import { PredictionV4GameSeries } from "src/components/prediction-v4-game-series";
import { PredictionV4GameStream } from "src/components/prediction-v4-game-stream";
import { IGameSeries, IPredictionGame } from "src/types/prediction";
import { PredictionV4GamePrediction } from "src/components/prediction-v4-game-prediction";

interface RouteParams {
  id: string;
}

interface IPredictionV4Detail extends RouteComponentProps<RouteParams> {
  // Define other props here if necessary
}

export const PredictionV4Detail = (props: IPredictionV4Detail) => {
  const { getPredictionList, getGameSeries } = useGameStore();

  const size: any = useWindowSize();

  const [game, setGame] = useState<IGameSeries | null>(null);
  const [predictionGames, setPredictionGames] = useState<
    IPredictionGame[] | null
  >(null);

  const fetchGame = useCallback(
    async (gameId) => {
      try {
        const game = await getGameSeries(gameId);
        const gameList = await getPredictionList(gameId);
        setPredictionGames(gameList);
        setGame(game?.length > 0 ? game[0] : null);
      } catch (e) {
        setPredictionGames([]);
      }
    },
    [getPredictionList, getGameSeries]
  );

  useEffect(() => {
    fetchGame(props.match?.params?.id);
  }, [fetchGame, props.match?.params?.id]);

  return (
    <ContainerV3>
      <div className="flex flex-col md:flex-row px-space-2 sm:px-space-6 py-space-4 sm:py-space-6 items-stretch justify-start gap-space-4 items-scretch">
        <div className="md:flex-1 flex flex-col gap-space-4">
          {/* Video */}
          <PredictionV4GameStream streamUrl={game?.stream_url || ""} />
          {/* Recommended */}
          <PredictionV4GameSeries
            selectedGameIds={""}
            title={"Recommended"}
            gridColsClassName={"grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 l:grid-cols-3"}
            isTurnOnExpandFunction={size?.width < 1080}
          />
        </div>

        {/* Preidction Section */}
        <div className="md:min-w-[560px]">
          <PredictionV4GamePrediction
            id={props.match?.params?.id}
            predictionGames={predictionGames}
            fetchGame={fetchGame}
          />
        </div>
      </div>
    </ContainerV3>
  );
};
