import clsx from "clsx";
import { IMysteryBox } from "src/types/mysteryBox";
import { CaseOpeningModal } from "src/components/case-opening-modal";
import { gedaCaseGifMap } from "src/utils/case";
import { useIsPcScreenWidth } from "src/utils/rwd";
import { getCurrencyIcon } from "src/utils/image";

interface ICaseOpeningBoxSelectedModal {
  className?: string;
  box?: IMysteryBox | null;
  selectedNum: number;
  isVisible: boolean;
  onClose: () => void;
  onClickNum: (num: number) => void;
  onClickOpen: () => void;
}

const numberOfBoxes = [1, 2, 3, 4, 5, 10];

const CaseOpeningBoxSelectedModal = ({
  className,
  box,
  selectedNum,
  isVisible,
  onClose,
  onClickNum,
  onClickOpen,
}: ICaseOpeningBoxSelectedModal) => {
  const isPC = useIsPcScreenWidth();
  const {
    name = "",
    price,
    img_url,
    gif_url,
    img_file,
    is_new,
    currency = "USDT",
    final_price,
  } = box || {};
  const openingBoxImgUrl =
    img_url || img_file || "https://media.geda.gg/default_case.png";
  const finalPrice = currency === "USDT" ? price : final_price;
  const calculatedPrice = (selectedNum * (finalPrice || 0)).toFixed(2);

  return (
    <CaseOpeningModal
      className={className}
      isVisible={isVisible}
      tag={
        <div
          className={clsx(
            "w-fit mb-space-1 rounded-xs bg-orange-800 text-white text-s md:py-space-0 px-space-1",
            { invisible: !is_new }
          )}
        >
          New
        </div>
      }
      title={name}
      content={
        <div
          className={clsx(
            "relative w-full h-[272px] md:h-[348px] flex justify-center items-center",
            { "case-opening-case": isPC }
          )}
        >
          <img
            className={clsx(
              { "case-opening-case-img-0": isPC },
              "h-[230px] md:h-[500px] aspect-square absolute pointer-events-none"
            )}
            src={openingBoxImgUrl}
            alt={name}
          />
          {isPC && (
            <img
              className={clsx(
                "case-opening-case-img-1",
                "h-[230px] md:h-[500px] aspect-square absolute object-contain object-center pointer-events-none"
              )}
              src={gif_url || gedaCaseGifMap[name] || openingBoxImgUrl}
              alt=""
            />
          )}
        </div>
      }
      footerClassName="pt-space-2 mx-space-0 md:mx-0 md:px-space-9 md:px-space-6 px-space-2"
      footer={
        <>
          <div className="flex justify-between items-center mb-space-2">
            <p className="text-m leading-none">Select number of boxes</p>
            <div className="hidden md:flex gap-space-2 items-center">
              {box?.currency === "MAX" && (
                <div className="flex flex-row gap-space-1 items-center">
                  <p className="text-m text-grey-800">GeDa Diamond:</p>
                  <img
                    className="w-[10px] h-[10px]"
                    src={"/assets/v4/gdd.png"}
                    alt="gdd"
                  />
                  <p className="text-m text-orange-800 font-[900]">
                    20% Drop Chance
                  </p>
                </div>
              )}

              <div className="flex flex-row gap-space-1 items-center">
                <p className="text-m text-grey-800">Total:</p>
                <img
                  className="w-[10px] h-[10px]"
                  src={getCurrencyIcon(currency || "USDT")}
                  alt="currency-icon"
                />
                <p className="text-m text-orange-800 font-[900]">
                  {calculatedPrice}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-space-2 md:gap-0 md:justify-between">
            {/* Number Buttons */}
            <div className="flex flex-row gap-space-2 md:justify-start">
              {numberOfBoxes.map((num) => (
                <div
                  key={num}
                  className={clsx(
                    "md:w-[48px] md:h-[48px] w-[36px] h-[36px] text-l md:text-xxml rounded-s border flex md:flex-1 items-center justify-center cursor-pointer bg-grey-100/10",
                    "hover:border-orange-800 hover:text-orange-800",
                    {
                      "border-black-200": selectedNum !== num,
                      "text-grey-600": selectedNum !== num,
                      "border-orange-800": selectedNum === num,
                      "text-orange-800": selectedNum === num,
                    }
                  )}
                  onClick={() => onClickNum(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            {/* Total & Open Button */}
            <div
              className="basis-[30px] md:basis-[220px] md:h-[48px] shrink-0 flex flex-row items-center justify-around py-[6px] px-space-4 md:p-space-4 border border-orange-800 rounded-xs cursor-pointer bg-orange-800/30"
              onClick={onClickOpen}
            >
              {box ? (
                <>
                  <div className="flex items-center gap-space-1">
                    <span className="text-l text-white">
                      OPEN ({selectedNum})
                    </span>
                  </div>
                </>
              ) : (
                <span className="text-l text-orange-800">Calculating...</span>
              )}
            </div>
          </div>
        </>
      }
      onClose={onClose}
    />
  );
};

export { CaseOpeningBoxSelectedModal };
