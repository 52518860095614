import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useState } from "react";
import { LoadingIcon } from "./loading-icon";
import { useAuthStore } from "src/stores";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";

export const ModalClaimCaseForm = (props: any) => {
  const { isModalOpen, setIsModalOpen, item, getMyBagCb } = props;

  const [address, setAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { claimCasePrize } = useAuthStore();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitForm = async () => {
    if (!address) return openWarningNotification("Please fill in all fields");
    if (isLoading) return;
    setIsLoading(true);
    const result = await claimCasePrize({
      id: item?._id,
      form: {
        address,
      },
    });
    setIsLoading(false);
    if (!result || typeof result === "string")
      return openWarningNotification("Please try again later.");
    getMyBagCb();
    openSuccessNotification("You have submitted the form, please stay tuned!");
    setIsModalOpen(false);
  };

  const getCongrazTxt = () => {
    if (item?.claim_status === "pending_to_airdrop") {
      return "We will airdrop the prize to your wallet soon.";
    }

    return "Congratulations! We will airdrop the prize to your wallet soon.";
  };

  const getChainName = () => {
    if (["nyannft", "guardiannft", "mixmobnft"].indexOf(item?.extra) > -1) {
      return "SOL";
    }
    if (["pomeriumnft", "firebox", "kukubox"].indexOf(item?.extra) > -1) {
      return "Polygon";
    }
    if (
      ["pomeriumnft", "pomeriumtoken", "cellulanft"].indexOf(item?.extra) > -1
    ) {
      return "BNB";
    }
    return "ERC20";
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-center min-h-[200px]">
        {/* Title */}
        <h2 className="page-subtitle">Claim your Prize</h2>
        {/* Form */}
        <div className="mt-s">
          <div>
            <p className={$labelStyle}>
              Wallet Address for Minting ({getChainName()})
            </p>
            <input
              type="text"
              name="wallet-address"
              className={$inputClass}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
          {/* Confirm Button */}
          <div className="mt-[24px]">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
              onClick={() => submitForm()}
            >
              {isLoading ? <LoadingIcon color="#fff" size={20} /> : "Confirm"}
            </button>
          </div>
          {/* Notes */}
          <p className="mt-s text-center small-content">{getCongrazTxt()}</p>
        </div>
      </div>
    </Modal>
  );
};

const $labelStyle = "content";
const $inputClass =
  "mt-1 px-s py-[6px] bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#783CC7] focus:ring-[#783CC7] block w-full rounded-md text-s focus:ring-1 rounded-m";
