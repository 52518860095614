import { useSpring, animated } from "react-spring/web";

import { useCallback, useEffect, useMemo, useState } from "react";

import { ContainerV3 } from "src/components/container-v3";
import { ProfilePointsV2 } from "src/components/profile-points-v2";
import { RaffleV4 } from "src/components/raffle-v4";
import { RaffleV4MissionList } from "src/components/raffle-v4-mission-list";
import { RaffleV4PrizeList } from "src/components/raffle-v4-prize-list";

import { AuthStore } from "src/stores/auth";
import {
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { useTwitterStore } from "src/stores";
import { getQueryParam } from "src/utils";
import { useAirdropStore } from "src/stores";
import { useWindowSize } from "@uidotdev/usehooks";
import { ModalConfirmUnlockReferral } from "src/components/modal-confirm-unlock-referral";
import { useAuth } from "src/components/auth-context";

export const AirdropV2 = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openNFTBoost, setOpenNFTBoost] = useState(false);
  const [profile, setProfile] = useState<any | null>({
    twitter_connected: false,
  });
  const [airdropProfile, setAirdropProfile] = useState<{} | null>(null);
  const [prizeList, setPrizeList] = useState<[]>([]);
  const [unlockReferralModal, setUnlockReferralModal] =
    useState<boolean>(false);

  const authStore = useMemo(() => new AuthStore(), []);
  const { getTwitterAuthUrl, connectTwitter } = useTwitterStore();
  const { getAirdropProfile, getPrizeList } = useAirdropStore();
  const { loggedIn, setOpenLoginModal } = useAuth();

  const [missionListExpendState, setMissionListExpendState] =
    useState<number>(0.5); // 0: none, 0.5:less, 1:more
  const [prizeListExpendState, setPrizeListExpendState] = useState<number>(0.5); // 0: none, 0.5:less, 1:more

  const size: any = useWindowSize();

  const getAirdropProfileCb = useCallback(() => {
    getAirdropProfile().then((result) => {
      if (!result) return;
      setAirdropProfile(result);
    });
  }, [getAirdropProfile, setAirdropProfile]);

  const getProfileCb = useCallback(() => {
    authStore.getProfile().then((user: any) => {
      if (!user) return;
      setProfile(user);
    });
  }, [authStore, setProfile]);

  const expandMoreMissionList = () => {
    setMissionListExpendState(1);
    setPrizeListExpendState(0);
  };

  const expandLessMissionList = () => {
    setMissionListExpendState(0.5);
    setPrizeListExpendState(0.5);
  };

  const expandMorePrizeList = () => {
    setPrizeListExpendState(1);
    setMissionListExpendState(0);
  };

  const expandLessPrizeList = () => {
    setPrizeListExpendState(0.5);
    setMissionListExpendState(0.5);
  };

  const missionListExpendSpring = useSpring({
    opacity: missionListExpendState > 0 ? 1 : 0,
    width:
      missionListExpendState === 0
        ? "calc(0% - 0px)"
        : missionListExpendState === 1
        ? "calc(100% - 33px)"
        : "calc(68% - 33px)",
  });

  const prizeListExpendSpring = useSpring({
    opacity: prizeListExpendState > 0 ? 1 : 0,
    width:
      prizeListExpendState === 0
        ? "0%"
        : prizeListExpendState === 1
        ? "100%"
        : "32%",
  });

  const prizeListAndMissionListSeperationLineExpendSpring = useSpring({
    opacity: missionListExpendState > 0.5 || prizeListExpendState > 0.5 ? 0 : 1,
    width:
      missionListExpendState > 0.5 || prizeListExpendState > 0.5
        ? "0px"
        : "33px",
  });

  useEffect(() => {
    const { code = "" } = getQueryParam(window.location.search);

    // doing the twitter auth
    if (code && profile._id) {
      connectTwitter({ token: code, id: profile._id }).then((result) => {
        if (result === "twitter_connect_success") {
          openSuccessNotification("Connected to Twitter");
          setTimeout(() => {
            window.history.replaceState(null, "", window.location.pathname);
            getProfileCb();
            getAirdropProfileCb();
            return;
          }, 1000);
        }
      });
    }

    if (window.location.hash === "#task") {
      setTimeout(() => {
        const aboutPageNode = document.getElementById("task");
        if (aboutPageNode != null) {
          aboutPageNode.scrollIntoView({ behavior: "smooth" });
        }
      }, 200);
    }
  }, [profile, connectTwitter, getProfileCb, getAirdropProfileCb]);

  useEffect(() => {
    getPrizeList().then((result: any) => {
      setPrizeList(result);
    });
  }, [getPrizeList]);

  useEffect(() => {
    // Fetch Profile
    if (!loggedIn) {
      setProfile({});
    }
    if (loggedIn) {
      setTimeout(() => {
        getProfileCb();
        // Fetch Airdrop Profile
        getAirdropProfileCb();
      }, 1000);
    }
  }, [loggedIn, getProfileCb, getAirdropProfileCb]);

  const handleTwitterLogin = (tryAgain = false) => {
    // check wallet connected first
    if (!loggedIn) {
      setOpenLoginModal(true);
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (profile?.twitter_connected && !tryAgain) {
      openSuccessNotification("Please authorize Twiiter Login");
      return;
    }

    getTwitterAuthUrl().then((url: string) => {
      window.open(url, "_self");
      return true;
    });
  };

  return (
    <ContainerV3>
      <div className="relative mr-space-4 md:mr-space-6">
        {/* Profile Points */}
        <ProfilePointsV2
          openModal={openModal}
          setOpenModal={setOpenModal}
          openNFTBoost={openNFTBoost}
          setOpenNFTBoost={setOpenNFTBoost}
          airdropProfile={airdropProfile}
          userId={profile._id}
          handleTwitterLogin={handleTwitterLogin}
          getAirdropProfileCb={getAirdropProfileCb}
          profile={profile}
          setUnlockReferralModal={setUnlockReferralModal}
        />
      </div>
      <div className="relative mx-space-4 md:mx-space-6">
        {/* Lucky Spin */}
        <RaffleV4
          airdropProfile={airdropProfile}
          userId={profile._id}
          getProfileCb={getProfileCb}
          getAirdropProfileCb={getAirdropProfileCb}
          profile={profile}
          prizeList={prizeList}
        />
        <div className="relative md:mb-space-4">
          <animated.div
            className="md:w-[32%] md:float-left overflow-hidden"
            style={{
              ...(size?.width > 1080 && prizeListExpendSpring),
            }}
          >
            <RaffleV4PrizeList
              prizeList={prizeList}
              isExpandMoreComponent={prizeListExpendState === 1}
              expandMoreComponent={expandMorePrizeList}
              expandLessComponent={expandLessPrizeList}
            />
          </animated.div>
          <animated.div
            className="w-[33px] h-[1px] md:float-left hidden md:block"
            style={{
              ...(size?.width > 1080 &&
                prizeListAndMissionListSeperationLineExpendSpring),
            }}
          >
            <div
              className="absolute w-[1px] h-full mx-space-4"
              style={{
                background:
                  "linear-gradient(180deg, rgba(115, 115, 115, 0) 0%, #737373 50%, rgba(115, 115, 115, 0) 100%)",
              }}
            />
          </animated.div>
          <animated.div
            className="md:w-[calc(68%-33px)] md:float-left overflow-hidden"
            style={{
              ...(size?.width > 1080 && missionListExpendSpring),
            }}
          >
            <div id="task"></div>
            <RaffleV4MissionList
              userId={profile._id}
              handleTwitterLogin={handleTwitterLogin}
              getAirdropProfileCb={getAirdropProfileCb}
              profile={profile}
              setUnlockReferralModal={setUnlockReferralModal}
              airdropProfile={airdropProfile}
              isExpandMoreComponent={missionListExpendState === 1}
              expandMoreComponent={expandMoreMissionList}
              expandLessComponent={expandLessMissionList}
            />
          </animated.div>
          <div className="clear-both" />
        </div>
      </div>

      {/* Modals */}
      <ModalConfirmUnlockReferral
        isModalOpen={unlockReferralModal}
        setIsModalOpen={setUnlockReferralModal}
        handleTwitterLogin={handleTwitterLogin}
        profile={profile}
      />
    </ContainerV3>
  );
};
