import { iNews } from "src/types/landing";
import { news } from "src/config/landing";

interface iLandingNews {}

export const LandingNews = (props: iLandingNews) => {
  return (
    <div className="mx-0 md:mx-space-2">
      {news.map((item: iNews, i: number) => {
        return (
          <a
            href={item?.url ? item?.url : ""}
            target="_blank"
            rel="noreferrer"
            key={i}
          >
            {item?.image && (
              <img src={item?.image} alt="news" className="mb-space-4" />
            )}
          </a>
        );
      })}
    </div>
  );
};
