import { IGameList } from "src/types/prediction";

interface IHotGame {
  item: IGameList;
  isExpand?: boolean
  goIconDirection?: string; // down, right
  action?: () => void;
}

export const HotGame = (props: IHotGame) => {
  const { item, isExpand=false, goIconDirection, action } = props;

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        if (item?.url) return window.open(item?.url, "_blank");
        if (action) return action();
      }}
    >
      <div className="relative w-full h-full overflow-hidden rounded-s">
        {isExpand && (
          <img
            src={item?.image ? item?.image : ""}
            alt="game"
            className="w-full object-cover"
            style={{
              aspectRatio: "1.42 / 1",
              maskImage: "url(/assets/v4/landing-hot-game-expand-more-mask.png)",
              maskRepeat: "no-repeat",
              maskSize: "cover",
            }}
          />
        )}

        {!isExpand && (
          <img
            src={item?.image ? item?.image : ""}
            alt="game"
            className="w-full object-cover"
            style={{
              aspectRatio: "1 / 1.27",
              maskImage: "url(/assets/v4/landing-hot-game-expand-less-mask.png)",
              maskRepeat: "no-repeat",
              maskSize: "cover",
            }}
          />
        )}

        {isExpand && (
          <img
            src={"/assets/v4/landing-hot-game-expand-more-shadow.png"}
            alt="shadow"
            className="absolute top-0 left-0 w-full object-cover"
            style={{
              aspectRatio: "1.42 / 1",
              maskImage: "url(/assets/v4/landing-hot-game-expand-more-mask.png)",
              maskRepeat: "no-repeat",
              maskSize: "cover",
            }}
          />
        )}

        {!isExpand && (
          <img
            src={"/assets/v4/landing-hot-game-expand-less-shadow.png"}
            alt="shadow"
            className="absolute top-0 left-0 w-full object-cover"
            style={{
              aspectRatio: "1 / 1.27",
              maskImage: "url(/assets/v4/landing-hot-game-expand-less-mask.png)",
              maskRepeat: "no-repeat",
              maskSize: "cover",
            }}
          />
        )}

        <img
          src={"/assets/v4/landing_hot_game_go.png"}
          alt="go"
          className={`absolute bottom-[2%] ${isExpand ? "right-[2%] w-[12%]" : "right-[4%] w-[20%]"} ${goIconDirection === "down" ? "transform rotate-[90deg]" : ""}`}
        />
        <div className="absolute top-[3.6%] right-[3.6%] w-[92.8%]">
          {item?.tags.length > 0 &&
            item?.tags.map((tag: string, q: number) => {
              return (
                <div
                  className="float-right min-w-[50px] border-[1px] border-solid border-white text-[#FAFAFA] text-[12px] leading-[14px] px-space-2 py-space-1 ml-space-2 mb-space-1 text-center rounded-[22px]"
                  style={{
                    backdropFilter: "blur(3px)",
                  }}
                  key={q}
                >
                  {tag}
                </div>
              );
            })}
        </div>
        <div className={`absolute bottom-[3.6%] left-[3.6%] ${isExpand ? "w-[77%]" : "w-[60%]"} whitespace-normal`}>
          {item?.name && (
            <p className="text-[#FEFEFE] text-[16px] leading-[19px] mb-space-1">
              {item?.name}
            </p>
          )}
          {item?.desc && (
            <p className="text-[#A3A3A3] text-[12px] leading-[14px] mb-space-1">
              {item?.desc}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
