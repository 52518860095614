import clsx from "clsx";
import { orderBy } from "src/types/case-opening";
import { IoIosArrowDown } from "react-icons/io";

interface ICaseOpeningSorter {
  className?: string;
  label: string;
  order?: orderBy;
  onChange: (order?: orderBy) => void;
}

const CaseOpeningSorter = ({
  className,
  label,
  order,
  onChange,
}: ICaseOpeningSorter) => {
  const isDefault = order === undefined;
  const isOrderByDesc = order === "desc";
  const isOrderByAsc = order === "asc";

  const handleClick = () => {
    let nextOrder: orderBy | undefined;

    if (isDefault) {
      nextOrder = "asc";
    }

    if (isOrderByAsc) {
      nextOrder = "desc";
    }

    if (isOrderByDesc) {
      nextOrder = undefined;
    }

    onChange(nextOrder);
  };

  return (
    <div
      className={clsx(className, "flex items-center hover:cursor-pointer", {
        "text-grey-600": isDefault,
        "text-orange-800": !isDefault,
      })}
      onClick={handleClick}
    >
      <span className="md:mr-space-1 blender-medium md:text-m text-xs">
        {label}
      </span>
      <IoIosArrowDown
        className={clsx(
          "md:w-[20px] md:h-[20px] w-[16px] h-[16px] transition-transform",
          {
            "rotate-0": isOrderByDesc || isDefault,
            "rotate-180": isOrderByAsc,
          }
        )}
      />
      {/* <img
        src="/assets/v4/dropdown.png"
        alt="dropdown"
        className={clsx(
          "md:w-[20px] md:h-[20px] w-[16px] h-[16px] transition-transform",
          {
            "rotate-0 ": isOrderByDesc,
            "rotate-180 ": isOrderByAsc,
          }
        )}
      /> */}
    </div>
  );
};

export { CaseOpeningSorter };
