import React, { useEffect, useMemo, useState } from "react";
import { openWarningNotification } from "./notification";
import { useDashboardStore } from "src/stores";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { STRIPE_PK } from "src/config";
import { Elements } from "@stripe/react-stripe-js";
import { StripeCheckout } from "./stripe-checkout";
import { ModalDepositCard } from "./modal-deposit-card";
import { ModalDepositCrypto } from "./modal-deposit-crypto";
import { ModalDepositCryptoV2 } from "./modal-deposit-crypto-v2";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "@uidotdev/usehooks";
import { useLocation } from "react-router";
import { Token } from "src/types/currency";
import { getCurrencyIcon } from "src/utils/image";

type PaymentType = "card" | "crypto";

const buttons = [9, 29, 79, 199, 399, 799];
const emptyButtonsArray = Array(4).fill(null);
const paymentTypes: PaymentType[] = ["card", "crypto"];
const stripePromise = loadStripe(STRIPE_PK);
const currencies = [Token.MAX, Token.STAR];

interface IModalDeposit {
  setIsModalOpen: (val: boolean) => void;
  depositCurrency?: string | null;
}

interface ILocationState {
  currency: string;
}

export const ModalDeposit: React.FC<IModalDeposit> = ({ depositCurrency }) => {
  const location = useLocation<ILocationState>();
  const passCurrency = location.state?.currency;

  const [amount, setAmount] = useState(5);
  const [type, setType] = useState<PaymentType>("card"); // card
  const [step, setStep] = useState<string>("select_payment"); // select_currency, select_payment, stripe
  const [secret, setSecret] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>("MAX");

  const { createDepositOrder } = useDashboardStore();
  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  useEffect(() => {
    if (!depositCurrency) return;
    setCurrency(depositCurrency);
  }, [depositCurrency]);

  useEffect(() => {
    if (!passCurrency) return;
    setCurrency(passCurrency);
  }, [passCurrency]);

  const next = async () => {
    if (amount < buttons[0] || amount > buttons[buttons.length - 1]) {
      return openWarningNotification(
        `Amount has to be within ${buttons[0]} and ${
          buttons[buttons.length - 1]
        }`
      );
    }

    const result = await createDepositOrder({
      amount: amount,
    });
    if (!result)
      return openWarningNotification("Failed to create deposit order");

    setSecret(result?.client_secret);
    setStep("stripe");
  };

  const stripeOptions: StripeElementsOptions | null = useMemo(() => {
    if (!secret) return null;
    return {
      // passing the client secret obtained in step 3
      clientSecret: secret,
      // Fully customizable with appearance API.
      appearance: {
        theme: "night",
      },
    };
  }, [secret]);

  const isActive = (paymentType: PaymentType) => {
    if (type === paymentType) return "text-orange-800 border-orange-800";
    return "text-grey-600 border-black-100";
  };

  const modalHeight = useMemo(() => {
    if (currency === "MAX") return "h-[470px]";
    if (type === "card") return "h-[400px]";
    if (type === "crypto") return "h-[470px]";
  }, [type, currency]);

  const getCurrencyBtnStyles = (item: string) => {
    if (!currency) return "border-grey-100";
    if (currency === item) return "border-orange-800";
    return "border-grey-800";
  };

  const getCurrencyTxtStyles = (item: string) => {
    if (!currency) return "text-grey-100";
    if (currency === item) return "text-orange-800";
    return "text-grey-800";
  };

  const renderCurrencySelection = () => {
    const getTitle = () => {
      if (!currency) return "Select token";
      if (isResponsive) return currency;
      return "Select token";
    };

    const getCurrencyStyles = (item: string) => {
      if (!currency) {
        if (isResponsive)
          return "w-[100px] py-space-2 px-space-3 justify-center";
        return "w-[100px] py-space-2 px-space-3 justify-start";
      }
      if (currency === item) {
        if (isResponsive) return "py-space-2 justify-center";
        return "px-space-3 py-space-4 justify-start h-fit";
      }

      if (!isResponsive) return "px-space-3 py-space-2 justify-start h-fit";
      return "w-[30px] py-space-0 px-space-0 h-[30px] justify-center";
    };
    return (
      <div className="flex flex-col gap-space-2">
        <p className="font-[700] text-xs md:text-m">{getTitle()}</p>
        {currencies.map((item, i) => (
          <div
            key={i}
            className={`flex flex-row gap-space-2 cursor-pointer bg-black-800 rounded-xs  border border-solid md:w-[230px] items-center relative overflow-hidden ${getCurrencyStyles(
              item
            )} ${getCurrencyBtnStyles(item)} transition-all duration-300`}
            onClick={() => setCurrency(item)}
            style={{
              boxShadow: "0px 2px 4px 0px #00000012",
            }}
          >
            <div className="flex flex-row items-center gap-space-2 relative z-[1]">
              <img
                src={getCurrencyIcon(item)}
                alt={item}
                className="w-[14px] object-fit"
              />
              {(!currency || !isResponsive) && (
                <p
                  className={`${getCurrencyTxtStyles(item)} text-xs md:text-m`}
                >
                  G{item}
                </p>
              )}
            </div>

            {/* Opacity Image as layer */}
            {!isResponsive && (
              <img
                src={getCurrencyIcon(item)}
                alt={item}
                className="w-[120px] object-fit absolute right-[0%]"
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="mx-space-7">
        {/* Select Currency */}
        {step === "select_currency" && (
          <div
            className={`flex flex-row py-space-2 md:py-space-4 gap-space-2 md:gap-space-4`}
          >
            {/* Currency selection */}
            {renderCurrencySelection()}
            {/* Select token to begin top up */}
            <div
              className={`flex flex-col items-center justify-center gap-space-4 flex-1 bg-black h-full rounded-s`}
              style={{
                boxShadow: `-4px 0px 4px 0px #00000040`,
                backgroundImage: `url(/assets/v4/deposit_bg.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p className="font-[700] text-xs md:text-m text-center">
                {!currency
                  ? "Select token to begin top up"
                  : `You've selected ${currency} to top up`}
              </p>
            </div>
          </div>
        )}
        {/* Select Payment */}
        {step === "select_payment" && (
          <div
            className={`flex flex-row gap-space-2 px-space-0 md:px-space-2 py-space-2 overflow-hidden overflow-y-auto scrollbar-hidden scrollbar-hide pb-space-6 ${modalHeight}`}
          >
            {/* Select Payment Method  */}
            <div className="flex flex-col gap-space-4 py-space-2">
              {renderCurrencySelection()}

              {currency === "STAR" && (
                <>
                  <div className="w-[30px] md:w-[230px]">
                    <p className="blender-medium w-[700] text-xs md:text-l text-grey-100">
                      {isResponsive
                        ? type.toUpperCase()
                        : "Select Payment method"}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-space-2 pt-space-0">
                      {paymentTypes.map((item, i) => {
                        return (
                          <button
                            className={`flex flex-col bg-gray-200/10 px-space-1 py-space-0 md:px-space-3 md:py-space-3 border-[1px] border-solid bg-black-100 rounded-xs text-m blender-medium font-[500] h-[30px] md:h-[64px] capitalize ${isActive(
                              item
                            )} items-center md:items-start justify-center`}
                            key={i}
                            onClick={() => setType(item)}
                          >
                            <img
                              src={`/assets/v4/${item}${
                                type === item ? "-active" : ""
                              }.png`}
                              alt={item}
                              className="w-[14px] object-fit mt-space-0 md:mt-space-1"
                            />
                            {isResponsive ? "" : item}
                          </button>
                        );
                      })}

                      {emptyButtonsArray.map((_, index) => (
                        <button
                          key={index}
                          className="flex flex-col bg-gray-200/10 px-space-1 md:px-xs py-space-1 md:py-space-3 border-[1px] border-solid bg-black-100 rounded-xs text-grey-600 font-[500] normal-case text-m border-black-100 h-[30px] md:h-[64px]"
                          style={{
                            background:
                              "linear-gradient(298.07deg, rgba(0, 0, 0, 0) 15.22%, #000000 84.78%)",
                          }}
                        ></button>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Payment Start */}
            {currency === "STAR" && type === "card" && (
              <ModalDepositCard
                amount={amount}
                setAmount={setAmount}
                amounts={buttons}
              />
            )}

            {currency === "STAR" && type === "crypto" && <ModalDepositCrypto />}

            {currency === "MAX" && <ModalDepositCryptoV2 />}
          </div>
        )}

        {/* Stripe Payment */}
        {step === "stripe" && stripeOptions && (
          <div className="px-space-2 py-space-2 h-[400px] overflow-hidden overflow-y-auto scrollbar-hidden scrollbar-hide pb-space-6">
            <Elements stripe={stripePromise} options={stripeOptions}>
              <StripeCheckout />
            </Elements>
          </div>
        )}
      </div>

      {/* Footer */}
      {(step === "select_currency" ||
        (step === "select_payment" &&
          type !== "crypto" &&
          currency !== "MAX")) && (
        <div className="absolute bottom-space-4 md:bottom-space-6 w-full bg-black-600 border-x border-grey-800">
          {/* Divider */}
          <div className="w-full h-[1px] bg-grey-800" />
          <div className="flex justify-center items-center py-space-2 md:py-space-4 px-space-4">
            <button
              className="border border-orange-600 w-[400px] h-[47px] bg-black-800 flex justify-center items-center rounded-xs blender-medium text-orange-600 font-[500] text-s md:text-m"
              onClick={() => {
                if (step === "select_currency") {
                  if (!currency) return;
                  setStep("select_payment");
                  return;
                }

                next();
              }}
              disabled={step === "select_currency" && !currency}
            >
              {step === "select_currency" ? "Top up" : "Next"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
