import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { openWarningNotification } from "./notification";

export const StripeCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();

  const confirmStripePayment = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const http =
      window?.location?.host.indexOf("localhost") > -1 ? "http" : "https";
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${http}://${window?.location?.host}/dashboard`,
      },
    });

    if (error) return openWarningNotification("Failed to confirm payment.");
  };

  return (
    <div className="flex flex-col gap-space-6 items-center flex-1 py-space-4">
      <div className="w-full">
        <PaymentElement />
      </div>
      <button
        className="border border-orange-600 w-[400px] h-[47px] bg-black-800 flex justify-center items-center rounded-xs blender-medium text-orange-600 font-[500] text-m"
        onClick={(e) => {
          confirmStripePayment(e);
        }}
      >
        Confirm
      </button>
    </div>
  );
};
