import { formatName } from "src/utils";
import { getRarityColor } from "src/utils/color";

interface iDashboardInventoryConfirmCase {
  item: any;
}

export const DashboardInventoryConfirmCase = (
  props: iDashboardInventoryConfirmCase
) => {
  const { item } = props;
  return (
    <div
      className="relative p-space-2 rounded-s overflow-hidden"
      style={{
        background: "linear-gradient(180deg, #BCC3DA 0%, #EEF7FB 100%)",
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "linear-gradient(180deg, rgba(46, 46, 46, 0) 50%, rgba(46, 46, 46, 0.3) 75.62%, #2E2E2E 100%)",
        }}
      />
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "linear-gradient(360deg, rgba(46, 46, 46, 0) 59.5%, rgba(46, 46, 46, 0.3) 79.75%, #2E2E2E 100%)",
        }}
      />
      <div className="relative overflow-hidden">
        <img
          src={item?.item?.imgUrl || "/assets/v4/matrix-fire-default.jpg"}
          alt="prize-img-back"
          className="relative w-[90%] aspect-square mb-space-2 opacity-0"
        />
        <img
          src={item?.item?.imgUrl || "/assets/v4/matrix-fire-default.jpg"}
          alt="prize-img"
          className="absolute transform top-0 left-[50%] translate-x-[-50%] aspect-square rounded-xs transition-all duration-500"
        />
        <div className="absolute top-0 left-0 w-full">
          <p className="text-s leading-[14px] font-[500] blender-medium text-grey-100 mb-space-1">
            {formatName(item?.item?.name || "")}
          </p>
          {item?.item?.quality && (
            <div className="flex flex-row items-center">
              <div
                className="rounded-full w-[8px] h-[8px]"
                style={{ backgroundColor: getRarityColor(item?.item?.quality) }}
              />
              <p className="small-content text-grey-200 blender-medium ml-space-1">
                {formatName(item?.item?.quality)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
