import { useWindowSize } from "@uidotdev/usehooks";

const thStyle = `align-top pb-space-1`;

interface iDashboardTransactionHistoryCaseHeader {}

export const DashboardTransactionHistoryCaseHeader = (props: iDashboardTransactionHistoryCaseHeader) => {
  const size: any = useWindowSize();

  const isShowLess = () => {
    return size?.width < 1080;
  };

  return (
    <thead>
      <th className={`${thStyle} text-left`}>
        <p className="option-label text-grey-100">Transactions</p>
      </th>
      <th className={`${thStyle} text-center`}>
        <p className="option-label text-grey-100">Status</p>
      </th>
      {!isShowLess() && (
        <th className={`${thStyle} text-center`}>
          <p className="option-label text-grey-100">Transaction date</p>
        </th>
      )}
      <th className={`${thStyle} text-right`}>
        <p className="option-label text-grey-100">Value</p>
      </th>
      {!isShowLess() && (
      <th className={`${thStyle} text-right`}>
        <p className="option-label text-grey-100">Completion Date</p>
      </th>
      )}
    </thead>
  );
};
