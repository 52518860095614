import { iBanner, iNews, iPartnerRow, iBannerContent } from "src/types/landing";
import { IGameList } from "src/types/prediction";

export const banners: iBanner[] = [
  {
    buttons: [
      {
        color: "#5B5B5B",
        openUrl: "https://matr1x.io",
        text: "Learn More",
      },
    ],
    content:
      "Matr1x is an innovative cultural and entertainment platform combining Web3, AI, Esports and blockchain Infrastructure.",
    icon: "/assets/games/matrix-fire/t05.png",
    title:
      '<img src="/assets/games/matrix-fire/MATR1X_Horizontal_White.png" style="width:183px;" />',
    video: "https://media.geda.gg/landing_matrix_video.mp4",
  },
  {
    buttons: [
      {
        color: "#5B5B5B",
        openUrl: "https://spacenation.online/",
        text: "Learn More",
      },
    ],
    content: "The Web3 Space Opera MMORPG - Build, Fight, Survive.",
    icon: "/assets/games/spacenation/landing-banner-icon.jpg",
    title:
      '<img src="/assets/games/spacenation/Spacenation_Horizontal_White.png" style="width:173px;" />',
    video: "https://media.geda.gg/landing_space_nation.mp4",
  },
  {
    buttons: [
      {
        color: "#5B5B5B",
        openUrl: "https://www.evermoon.games/",
        text: "Learn More",
      },
    ],
    content: "Next-Gen 3-lane MOBA, 5v5 battles & eSports with Web3.",
    icon: "/assets/games/evermoon/landing-banner-icon.png",
    title:
      '<img src="/assets/games/evermoon/Evermoon_Horizontal.png" style="width:175px;" />',
    video: "https://media.geda.gg/landing_evermoon_v2.mp4",
  },
  {
    buttons: [
      {
        color: "#5B5B5B",
        openUrl: "https://apeironnft.com/",
        text: "Learn More",
      },
    ],
    content:
      "A unique blend of Roguelite x RTS Card Game with a dash of Doodiness.",
    icon: "/assets/games/apeiron/landing-banner-icon.png",
    title:
      '<img src="/assets/games/apeiron/Apeiron_Horizontal_White.png" style="width:175px;" />',
    video: "https://media.geda.gg/landing_asta.mp4",
  },
];

export const hotGames: IGameList[] = [
  {
    desc: "The No.1 FPS Mobile Game In Metaverse",
    image: "/assets/v4/landing_hot_game_matr1x_fire.jpg",
    tags: ["FPS"],
    name: "Matr1x Fire",
    url: "https://matr1x.io",
  },
  {
    desc: "SERAPH: In the Darkness is a web3 native ARPG loot game that incorporates developed by Seraph Studio, backed by the Korean gaming giant Actoz Soft, it melds dark western fantasy medieval settings with classic gameplay and rich content.",
    image: "/assets/v4/landing_hot_game_seraph_in_the_darkness.jpg",
    tags: ["Loot ARPG"],
    name: "Seraph: In The Darkness",
    url: "https://www.seraph.game",
  },
  {
    desc: "Arena of Faith is a web3 MOBA game that allows players to engage in cross-platform battles globally. With proof of framesync and AI technology, the game creates a new paradigm for on-chain games.",
    image: "/assets/v4/landing_hot_game_arena_of_faith.jpg",
    tags: ["MOBA"],
    name: "Arena of Faith",
    url: "https://www.aof.games/",
  },
];

export const news: iNews[] = [
  {
    image: "/assets/v4/news-1.jpg",
    url: "https://game.abs.io",
  },
];

export const partnerRows: iPartnerRow[] = [
  [
    {
      image: "/assets/partners/bitcoin-cats.png",
    },
    {
      image: "/assets/partners/mix-mob.png",
    },
    {
      image: "/assets/partners/bga.png",
    },
    {
      image: "/assets/partners/yuliverse.png",
    },
    {
      image: "/assets/partners/ordz-games.png",
    },
    {
      image: "/assets/partners/arena-of-faith.png",
    },
    {
      image: "/assets/partners/matr1x-fire.png",
    },
    {
      image: "/assets/partners/bitboy.png",
    },
    {
      image: "/assets/partners/apeiron.png",
    },
    {
      image: "/assets/partners/re-dreamer.png",
    },
    {
      image: "/assets/partners/mirror-world.png",
    },
  ],
  [
    {
      image: "/assets/partners/star-symphony.png",
    },
    {
      image: "/assets/partners/ancient8.png",
    },
    {
      image: "/assets/partners/anome.png",
    },
    {
      image: "/assets/partners/yay.png",
    },
    {
      image: "/assets/partners/pomerium.png",
    },
    {
      image: "/assets/partners/block-tempo.png",
    },
    {
      image: "/assets/partners/iagent-protocol.png",
    },
    {
      image: "/assets/partners/double-jump-tokyo.png",
    },
    {
      image: "/assets/partners/partner1.png",
    },
    {
      image: "/assets/partners/castile.png",
    },
  ],
  [
    {
      image: "/assets/partners/sonic.png",
    },
    {
      image: "/assets/partners/soul-land.png",
    },
    {
      image: "/assets/partners/erm-labs.png",
    },
    {
      image: "/assets/partners/mx.png",
    },
    {
      image: "/assets/partners/the-sandbox.png",
    },
    {
      image: "/assets/partners/nyan-heroes.png",
    },
    {
      image: "/assets/partners/arena-of-valor.png",
    },
    {
      image: "/assets/partners/852-web3.png",
    },
    {
      image: "/assets/partners/dynasty.png",
    },
    {
      image: "/assets/partners/terminal.png",
    },
    {
      image: "/assets/partners/jgg.png",
    },
  ],
];

export const bannerContent: iBannerContent[] = [
  {
    id: 1,
    image: "/assets/v4/landing-open-case-banner-image1.png",
    altText: "banner image 1",
    title: "Dominate the Battlefield",
    descriptions: "Open cases and unlock premium firepower for more kills!",
  },
  {
    id: 2,
    image: "/assets/v4/landing-open-case-banner-image2.png",
    altText: "banner image 2",
    title: "Gear up for Victory",
    descriptions: "Unleash powerful weapons and dominate your enemies!",
  },
  {
    id: 3,
    image: "/assets/v4/landing-open-case-banner-image3.png",
    altText: "banner image 3",
    title: "Unlock Unlimited Power",
    descriptions: "Get epic gear and rise to the top!",
  },
  {
    id: 4,
    image: "/assets/v4/landing-open-case-banner-image4.png",
    altText: "banner image 4",
    title: "Join Geda Discord",
    descriptions: "Welcome to our new server!",
  },
];
