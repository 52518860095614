import { useEffect, useState } from "react";
import { useGameStore } from "src/stores";
import { PREDICTION_LIST_V3 } from "src/config/predictions";
import { Tag } from "./tag";

export const LandingPredictRow = (props: any) => {
  const { game } = props;

  const [analysis, setAnalysis] = useState<any>({});

  const { getPredictionAnalysis } = useGameStore();

  useEffect(() => {
    getPredictionAnalysis(game?._id).then((result: any) => {
      if (!result) return;
      setAnalysis(result);
    });
  }, [getPredictionAnalysis, game]);

  return (
    <div>
      <div className="flex flex-row mt-space-4 justify-between mb-space-2">
        <div>
          {analysis && (
            <p className="text-l leading-[24px] blender-medium">
              Total votes:&nbsp;
              {(
                analysis?.team_a_supporters + analysis?.team_b_supporters
              ).toLocaleString()}
            </p>
          )}
        </div>
        <div className="relative top-0 right-[-10px] flex flex-row">
          <Tag tag={PREDICTION_LIST_V3[0]?.type[0]} />
          {PREDICTION_LIST_V3[0]?.isLive && <Tag tag="Live" />}
          {PREDICTION_LIST_V3[0]?.isEnded && <Tag tag="Ended" />}
        </div>
      </div>
      <div className="relative min-h-[68px] mb-space-4">
        <div className="relative flex flex-row items-center z-[1]">
          <div
            className="flex-1 flex flex-row items-center gap-space-2 py-space-4 pl-space-4 pr-space-9"
          >
            <div className="w-[32px] h-[32px] bg-grey-200 rounded-full overflow-hidden">
              <img
                src={
                  game?.team_a_logo !== ""
                    ? game?.team_a_logo
                    : "/assets/GeDa_default.png"
                }
                alt="icon"
                className="w-[32px] h-[32px] object-cover rounded-full"
              />
            </div>
            <div className="flex-1 text-l text-left leading-[19px] blender-medium break-all">
              {game?.team_a}
            </div>
          </div>
          <div
            className="flex-1 flex flex-row items-center gap-space-2 py-space-4 pl-space-9 pr-space-4"
          >
            <div className="flex-1 text-l text-right leading-[19px] blender-medium break-all">
              {game?.team_b}
            </div>
            <div className="w-[32px] h-[32px] bg-grey-200 rounded-full overflow-hidden">
              <img
                src={
                  game?.team_b_logo !== ""
                    ? game?.team_b_logo
                    : "/assets/GeDa_default.png"
                }
                alt="icon"
                className="w-[32px] h-[32px] object-cover rounded-full"
              />
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full z-[0]">
          <div
            className="absolute top-0 left-0 flex flex-row items-center gap-space-2 w-[51%] h-full bg-white py-space-4 pl-space-4 pr-space-9"
            style={{
              borderRadius: "4px 4px 200px 4px",
              boxShadow: "0px 0px 10px 0px #0000004D inset",
            }}
          >
            <div className="w-[32px] h-[32px]" />
            <div className="flex-1 text-l text-left leading-[19px] blender-medium break-all opacity-0">
              {game?.team_a}
            </div>
          </div>
          <div
            className="absolute top-0 right-0 flex flex-row items-center gap-space-2 w-[51%] h-full bg-white py-space-4 pl-space-9 pr-space-4"
            style={{
              borderRadius: "200px 4px 4px 4px",
              boxShadow: "0px 0px 10px 0px #0000004D inset",
            }}
          >
            <div className="flex-1 text-l text-right leading-[19px] blender-medium break-all opacity-0">
              {game?.team_b}
            </div>
            <div className="w-[32px] h-[32px]" />
          </div>
        </div>
        <img
          src={PREDICTION_LIST_V3[0]?.logo}
          alt="icon"
          className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[60px] h-[60px] rounded-full z-[1]"
        />
      </div>
    </div>
  );
};
