import { useMemo } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { LoadingIconV2 } from "./loading-icon-v2";

import { getEarnPrizeItemRenderParameterSet, getModalEarnPrizeBackgroundImage } from "src/utils/earn";

interface iRaffleV4PrizeList {
  prizeList: any;
  isExpandMoreComponent: boolean;
  expandMoreComponent: () => void;
  expandLessComponent: () => void;
}

export const RaffleV4PrizeList = (props: iRaffleV4PrizeList) => {
  const { prizeList, isExpandMoreComponent = false, expandMoreComponent, expandLessComponent } = props;

  const size: any = useWindowSize();

  const formattedPrizeList = useMemo(() => {
    if (!prizeList || prizeList.length === 0) return [];
    let uniqueIds = new Set();
    let uniqueArray = prizeList
      .filter((obj: any) => {
        if (!uniqueIds.has(obj._id)) {
          uniqueIds.add(obj._id);
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => a.probability - b.probability);
    return uniqueArray;
  }, [prizeList]);

  return (
    <div
      className="h-full py-space-4"
    >
      <div className="flex items-start flex-row blender-medium mb-space-4 whitespace-nowrap">
        <div
          className="text-xxl sm:text-[32px] md:text-[28px] l:text-[32px] text-grey-600 leading-[32px]"
          style={{
            fontWeight: 900
          }}
        >FEATURED REWARDS</div>
        <div className="flex-[1]"></div>
        <div
          className="text-l leading-[32px] text-orange-600 cursor-pointer"
          onClick={() => {
            if(isExpandMoreComponent) {
              expandLessComponent();
            }
            else {
              expandMoreComponent();
            }
          }}
        >
          {(isExpandMoreComponent ? "< Show less" : "View more >")}
        </div>
      </div>
      <div>
        {prizeList.length === 0 && (
          <div className="flex items-center justify-center">
            <LoadingIconV2 />
          </div>
        )}

        <div className={`grid grid-cols-1 sm:grid-cols-2 ${!isExpandMoreComponent ? "md:grid-cols-2 l:grid-cols-2" : "md:grid-cols-6 l:grid-cols-6"} gap-x-space-4 sm:gap-x-space-2 gap-y-space-2`}>
          {prizeList.length > 0 &&
            formattedPrizeList.map((prize: any, i: number) => {
              if (!isExpandMoreComponent) {
                if(size?.width < 760) {
                  if (i > 4) {
                    return null;
                  }
                }
                else {
                  if (i > 3) {
                    return null;
                  }
                }
              }

              let prizeItemRenderParameterSet =
                getEarnPrizeItemRenderParameterSet(
                  prize,
                  formattedPrizeList,
                  i
                );

              return (
                <div
                  key={i}
                  className="relative bg-[#303030] border-[1px] border-solid border-black-200 px-space-4 md:px-space-2 py-space-4 rounded-s overflow-hidden"
                >
                  <div className="flex items-start items-stretch flex-row md:flex-col">
                    <div className="relative mr-space-2 md:mr-0 md:mb-space-4">
                      <img
                        src={getModalEarnPrizeBackgroundImage(prize?.type)}
                        alt="icon"
                        className="relative w-[75px] h-[75px] md:w-full md:h-[initial] object-cover rounded-xs aspect-square"
                      />
                      {prizeItemRenderParameterSet?.mainTxt !== "" &&
                        prizeItemRenderParameterSet?.showMainTxt && (
                          <p
                            className={`absolute top-[50%] left-0 transform translate-y-[-50%] w-full text-grey-600 text-center blender-medium text-[42px] md:text-[28vw] md:text-[8vw]`}
                            style={{ fontWeight: "700" }}
                          >
                            {prizeItemRenderParameterSet?.mainTxt}{" "}
                          </p>
                        )}
                    </div>
                    <div className="flex flex-col flex-[1]">
                      <p className="text-l leading-[19px] mb-space-2">
                        {prize?.name}
                      </p>
                      <div className="flex flex-row">
                        <p className="flex-1 small-content uppercase">
                          {prizeItemRenderParameterSet?.prizeType}
                        </p>
                        <p className="small-content">
                          {prize?.probability}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
