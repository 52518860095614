import { useCallback, useEffect, useRef, useState } from "react";

import { AirdropMultiplierV2 } from "./airdrop-multiplier-v2";
import { HoverModal } from "./hover-modal";
import { useReferralStore } from "src/stores";

import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useAuth } from "./auth-context";

interface iProfilePointsV2 {
  openModal: any;
  setOpenModal: any;
  openNFTBoost: any;
  setOpenNFTBoost: any;
  airdropProfile: any;
  userId: any;
  handleTwitterLogin: any;
  getAirdropProfileCb: any;
  profile: any;
  setUnlockReferralModal: any;
}

export const ProfilePointsV2 = (props: iProfilePointsV2) => {
  const {
    openModal,
    setOpenModal,
    openNFTBoost,
    setOpenNFTBoost,
    airdropProfile = {
      points: "--",
      multiplier: 100,
      totalScore: "--",
      taskCompleted: "--",
      referralCode: "",
    },
    userId = "",
    handleTwitterLogin,
    getAirdropProfileCb,
    profile,
    setUnlockReferralModal,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralProfile, setReferralProfile] = useState({
    numOfReferrals: 0,
    canEnterReferralCode: true,
  });
  const [isShowEnergyInfo, setIsShowEnergyInfo] = useState(false);

  const modalRef = useRef<any>(); // Ref for the modal div

  const { loggedIn, setOpenLoginModal } = useAuth();
  const { getReferralProfile, doReferral } = useReferralStore();

  const getReferralProfileCb = useCallback(async () => {
    const result = await getReferralProfile();
    setReferralProfile(result);
  }, [getReferralProfile, setReferralProfile]);

  useEffect(() => {
    getReferralProfileCb();
  }, [getReferralProfileCb]);

  // Event handler to close modal if clicked outside
  const handleClickOutside = useCallback(
    (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        event.target.textContent !== "REFERRAL" &&
        (openModal || openNFTBoost)
      ) {
        setOpenModal(false);
        setOpenNFTBoost(false);
      }
    },
    [openModal, openNFTBoost, setOpenModal, setOpenNFTBoost]
  ); // Dependencies

  useEffect(() => {
    // Attach listener when the modal is open
    if (openModal) {
      document.addEventListener("click", handleClickOutside);
    }

    // Cleanup listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openModal, openNFTBoost, handleClickOutside]); // Effect dependencies

  const handleOpenModal = () => {
    if (!loggedIn) {
      setOpenLoginModal(true);
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (!profile?.twitter_connected) {
      openWarningNotification("Please authorize Twitter Login");
      return;
    }

    if (!airdropProfile?.referralUnlock) {
      setUnlockReferralModal(true);
      return;
    }

    setOpenModal(!openModal);
    setIsHovered(false);
    setOpenNFTBoost(false);
  };

  const handleOpenHover = () => {
    setIsHovered(!isHovered);
    setOpenModal(false);
    setOpenNFTBoost(false);
  };

  const handleOpenNFTBoost = () => {
    setIsHovered(false);
    setOpenModal(false);
    setOpenNFTBoost(!openNFTBoost);
  };

  const handleReferralCodeInput = (value: string) => {
    setReferralCode(value);
  };

  const handleReferral = () => {
    doReferral(userId, referralCode).then((result: any) => {
      if (result === "enter_referral_code_success") {
        openSuccessNotification("Successfully entered a referral code");
        getAirdropProfileCb();
        getReferralProfileCb();
      }
    });
  };

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(airdropProfile?.referralCode);
    openSuccessNotification("You have copied your referral code");
  };

  const copyReferralCode = () => {
    navigator.clipboard.writeText(airdropProfile.referralCode);
    openSuccessNotification("You have copied your referral code");
  };

  return (
    <div className="h-full py-space-4">
      <table className="w-full mb-space-4">
        <tr>
          <td className="p-0">
            <div className="h-full bg-black-600 border-t-[1px] border-r-[1px] border-solid border-t-black-200 border-r-black-200 rounded-tr-s px-space-4 md:px-space-6 py-space-2 sm:py-space-4">
              <p
                className="text-xxl sm:text-[32px] md:text-[28px] l:text-[32px] text-grey-600 leading-[32px] blender-medium whitespace-nowrap"
                style={{
                  fontWeight: 900,
                }}>
                YOUR STATS
              </p>
            </div>
          </td>
          <td className="w-full p-0 text-right">
            {/* Connect to Twitter */}
            <button
              onClick={() => handleTwitterLogin()}
              className="blender-medium text-s !text-white leading-[16px] px-space-4 sm:px-space-9 py-space-2 grey">
              <div className="flex flex-row items-end">
                <div className="mr-space-1">
                  {profile.twitter_connected ? "CONNECTED" : "CONNECT"}
                </div>
                <img
                  src={"/assets/v4/profile-connect-twitter-logo.png"}
                  alt="twitter-logo"
                  className="w-[16px] h-[16px]"
                />
              </div>
            </button>
          </td>
        </tr>
        <tr>
          <td className="p-0">
            <div className="h-[8px] bg-black-600 border-t-[1px] border-solid border-black-600 border-t-black-600" />
          </td>
          <td className="p-0">
            <div className="h-[8px] bg-black-600 border-t-[1px] border-r-[1px] border-solid border-black-600 border-t-black-200 border-r-black-200 rounded-tr-s" />
          </td>
        </tr>
        <tr>
          <td className="p-0" colSpan={2}>
            <div className="h-full bg-black-600 border-b-[1px] border-r-[1px] border-solid border-black-600 border-b-black-200 border-r-black-200 rounded-br-s px-space-4 md:px-space-6 pt-space-2 pb-space-4">
              <div
                className={`grid grid-cols-1 sm:grid-cols-2 l:grid-cols-4 gap-x-space-5 gap-y-space-4 l:ml-space-6`}>
                <table className="w-full">
                  <tr>
                    <td className="w-[8px] p-0">
                      <img
                        src={"/assets/v4/profile-common-bg-left.png"}
                        alt="profile_bg"
                        className="w-[8px] h-[100px]"
                      />
                    </td>
                    <td className="relative w-[calc(100%-110px)] p-0">
                      <img
                        src={"/assets/v4/profile-common-bg-center.png"}
                        alt="profile_bg"
                        className="w-full h-[100px] absolute top-0 left-0"
                      />
                      <div className="relative blender-medium mx-space-2">
                        <p className="text-[13px] text-grey-800 leading-[15px]">
                          POINTS:
                        </p>
                        <p className="text-[36px] leading-[36px] text-orange-600">
                          {airdropProfile?.points || 0}
                        </p>
                      </div>
                    </td>
                    <td className="w-[102px] p-0">
                      <img
                        src={"/assets/v4/profile-geda-bg-right.png"}
                        alt="profile_bg"
                        className="w-[102px] h-[100px]"
                      />
                    </td>
                  </tr>
                </table>
                <div className="relative">
                  {isHovered && (
                    <HoverModal
                      showUp={isHovered}
                      modalRef={modalRef}
                      setIsModalOpen={setIsHovered}>
                      <div className="flex flex-col w-full items-center">
                        <p className="hover_title">Referral</p>
                        <p className="hover_content">
                          Boost your multiplier by referring friends!
                        </p>

                        {/* <p className="hover_title">invite count</p>
                        <p className="hover_content">Permanent</p>

                        <p className="hover_title">lucky spin</p>
                        <p className="hover_content"> Permanent</p> */}
                      </div>
                    </HoverModal>
                  )}

                  {openModal && (
                    <HoverModal
                      showUp={openModal}
                      modalRef={modalRef}
                      setIsModalOpen={setOpenModal}>
                      <div className="flex flex-col w-full items-center">
                        <p className="hover_title text-[24px]">invited:</p>
                        <p className="hover_content !text-[48px] !leading-[57px]">
                          {referralProfile?.numOfReferrals || 0}/50
                        </p>
                        <p
                          onClick={() => handleCopyReferralLink()}
                          className="hover_content mt-[10px] !text-l text-center !leading-[19px] underline cursor-pointer">
                          CLICK TO COPY CODE
                        </p>
                        <p className="hover_content text-center !text-xs mt-[10px] !leading-[12px] px-[15px]">
                          (Only invitees who connected to wallet and twitter
                          account will be counted)
                        </p>

                        <p className="mt-m text-center">
                          Your Referral Code:{" "}
                          <span
                            onClick={() => copyReferralCode()}
                            className="underline cursor-pointer">
                            {airdropProfile.referralCode}
                          </span>
                        </p>

                        {referralProfile?.canEnterReferralCode && (
                          <>
                            <p className="mt-m">Enter Referral Code:</p>
                            <input
                              type="text"
                              value={referralCode}
                              onChange={(e) =>
                                handleReferralCodeInput(e.target.value)
                              }
                              className="p-1 rounded-[4px] mt-1"
                            />

                            <button className="mt-m" onClick={handleReferral}>
                              Enter Referral
                            </button>
                          </>
                        )}

                        {!referralProfile?.canEnterReferralCode && (
                          <>
                            <p className="hover_content text-center !text-xs mt-[10px] !leading-[12px] px-[15px]">
                              You already have entered a referral code
                            </p>
                          </>
                        )}
                      </div>
                    </HoverModal>
                  )}

                  {openNFTBoost && (
                    <HoverModal
                      showUp={openNFTBoost}
                      modalRef={modalRef}
                      setIsModalOpen={setOpenNFTBoost}>
                      <div className="flex flex-row w-full items-center justify-center h-full">
                        <p className="hover_title">Coming Soon</p>

                        {/* <div className="flex flex-1 flex-col items-center">
                          <img
                            className="h-[128px]"
                            src="/assets/v3/nftBoost-ring.png"
                            alt="nft boost"
                          />
                          <p className="hover_content px-[20px] !text-xs text-center !leading-[14px] underline">
                            get Championship Ring nft through activities or companionship
                          </p>
                        </div>
                        <div className="flex flex-1 flex-col items-center">
                          <img
                            className="h-[128px]"
                            src="/assets/v3/nftBoost-ticket.png"
                            alt="ticket"
                          />
                          <p className="hover_content px-[20px] !text-xs text-center !leading-[14px] underline">
                            buy & hold geda pass nft to get extra boost for Permanent{" "}
                          </p>
                        </div> */}
                      </div>
                    </HoverModal>
                  )}

                  <table className="w-full">
                    <tr>
                      <td className="w-[8px] p-0">
                        <img
                          src={"/assets/v4/profile-common-bg-left.png"}
                          alt="profile_bg"
                          className="w-[8px] h-[100px]"
                        />
                      </td>
                      <td className="relative w-[calc(100%-139px)] sm:w-[calc(100%-189px)] p-0">
                        <img
                          src={"/assets/v4/profile-common-bg-center.png"}
                          alt="profile_bg"
                          className="w-full h-[100px] absolute top-0 left-0"
                        />
                        <div className="relative blender-medium mx-space-2">
                          <p className="text-[13px] text-grey-800 leading-[15px]">
                            MULTIPLIER:
                          </p>
                          <div className="flex flex-row items-end">
                            <p
                              className="flex-1 text-[36px] leading-[36px] text-orange-600"
                              style={{
                                textDecoration: "underline",
                                textDecorationThickness: "1px",
                                textUnderlineOffset: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenHover && handleOpenHover()
                              }>
                              {`${(
                                (airdropProfile?.multiplier || 100) / 100
                              ).toLocaleString()}x`}
                            </p>
                            <p className="text-grey-800 small-content">
                              ({`${airdropProfile?.multiplier || 100}%`})
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="relative w-[131px] sm:w-[181px] p-0">
                        <img
                          src={"/assets/v4/profile-multiplier-bg-right.png"}
                          alt="profile_bg"
                          className="w-[131px] sm:w-[181px] h-[100px] absolute top-0 left-0"
                        />
                        <div className="relative w-[89px] sm:w-[139px] ml-[18px]">
                          <AirdropMultiplierV2
                            openModal={handleOpenModal}
                            setOpenNFTBoost={handleOpenNFTBoost}
                          />
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <table className="w-full">
                  <tr>
                    <td className="w-[8px] p-0">
                      <img
                        src={"/assets/v4/profile-common-bg-left.png"}
                        alt="profile_bg"
                        className="w-[8px] h-[100px]"
                      />
                    </td>
                    <td className="relative w-[calc(100%-110px)] p-0">
                      <img
                        src={"/assets/v4/profile-common-bg-center.png"}
                        alt="profile_bg"
                        className="w-full h-[100px] absolute top-0 left-0"
                      />
                      <div className="relative blender-medium mx-space-2">
                        <p className="text-[13px] text-grey-800 leading-[15px]">
                          TOTAL SCORE (SEASON 1):
                        </p>
                        <p className="text-[36px] leading-[36px] text-orange-600">
                          {airdropProfile?.totalScore || 0}
                        </p>
                      </div>
                    </td>
                    <td className="w-[102px] p-0">
                      <img
                        src={"/assets/v4/profile-geda-bg-right.png"}
                        alt="profile_bg"
                        className="w-[102px] h-[100px]"
                      />
                    </td>
                  </tr>
                </table>
                <div className="relative">
                  <HoverModal
                    showUp={isShowEnergyInfo}
                    modalRef={modalRef}
                    setIsModalOpen={setIsShowEnergyInfo}>
                    <div className="flex flex-col w-full items-center">
                      <p
                        className="hover_content"
                        style={{
                          textTransform: "initial",
                        }}>
                        When the energy bar is full,{" "}
                        <span className="text-orange-600">
                          you'll earn an extra 100% of your current total score
                        </span>
                      </p>
                    </div>
                  </HoverModal>
                  <table className="w-full">
                    <tr>
                      <td className="w-[8px] p-0">
                        <img
                          src={"/assets/v4/profile-common-bg-left.png"}
                          alt="profile_bg"
                          className="w-[8px] h-[100px]"
                        />
                      </td>
                      <td className="relative w-[calc(100%-145px)] p-0">
                        <img
                          src={"/assets/v4/profile-common-bg-center.png"}
                          alt="profile_bg"
                          className="w-full h-[100px] absolute top-0 left-0"
                        />
                        <div className="relative blender-medium mx-space-2">
                          <p className="relative text-[13px] text-grey-800 leading-[15px]">
                            ENERGY LEVEL:
                          </p>
                          <p className="relative text-[36px] leading-[36px] text-orange-600">
                            {`${airdropProfile?.energy || 0}%`}
                          </p>
                          <div className="w-[16px] h-[16px] absolute top-0 right-0 cursor-pointer">
                            <img
                              src={"/assets/v4/profile-info-outline.png"}
                              alt="info"
                              className="w-full"
                              onClick={() => setIsShowEnergyInfo(true)}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="relative w-[137px] p-0">
                        <img
                          src={"/assets/v4/profile-energy-bg-right.png"}
                          alt="profile_bg"
                          className="w-[137px] h-[100px] absolute top-0 left-0"
                        />
                        <div className="w-[36px] h-[68px] absolute top-[50%] left-[50px] transform translate-y-[-50%]">
                          <img
                            src={"/assets/v4/profile-energy-battery.png"}
                            alt="battery"
                            className="relative w-[36px] h-[68px]"
                          />
                          <div className="w-[36px] h-[56px] absolute top-[8px] left-0">
                            <div
                              className="w-full absolute bottom-0 left-0"
                              style={{
                                background:
                                  "linear-gradient(90deg, #33C538 0%, #3ED844 4.69%, #82F186 19.79%, #A5FDA7 27.46%, #8EF190 42.83%, #00A501 81.15%, #00A501 87.7%, #0CB50F 96%, #0FAD10 99.13%)",
                                height: (airdropProfile?.energy || 0) + "%",
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};
