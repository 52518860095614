import clsx from "clsx";
import { useLocation } from "react-router";
import { history } from "src/stores";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { CgNotes } from "react-icons/cg";

interface iFooterV3 {}

export const FooterV3 = (props: iFooterV3) => {
  const location = useLocation();
  const isActive = (name: string) => {
    return location?.pathname.indexOf(name) > -1;
  };

  return (
    <div className="mx-0 px-space-4 md:px-space-6 bg-black overflow-hidden">
      <div className="h-[1px] my-space-8 bg-gradient-to-r from-neutral-500/0 via-neutral-500 to-neutral-500/0" />
      <div className="mb-space-8 overflow-hidden">
        <div className="flex gap-space-6 mb-space-6 text-grey-100 text-m sm:text-[18px]">
          <div
            className={clsx(
              "flex gap-space-2 items-center cursor-pointer transition-transform",
              "hover:text-orange-800 hover:skew-y-2",
              { "text-orange-800": isActive("/tos") }
            )}
            onClick={() => history.push("/tos")}
          >
            <CgNotes />
            Terms of Service
          </div>
          <div className="w-[1px] bg-gradient-to-b from-white/0 via-white to-white/0" />
          <div
            className={clsx(
              "flex gap-space-2 items-center cursor-pointer transition-transform",
              "hover:text-orange-800 hover:skew-y-2",
              { "text-orange-800": isActive("/privacy") }
            )}
            onClick={() => history.push("/privacy")}
          >
            <MdOutlinePrivacyTip />
            Privacy Policy
          </div>
        </div>
        <div className="float-left">
          <p className="text-l leading-[24px] mb-space-2 md:mb-space-6 blender-medium">
            About <span className="acpex">Geda</span>
          </p>
          <p className="text-s md:text-m leading-[22px] mb-space-6 blender-medium">
            Blockchain-powered eSports infrastructure: <br />A revolutionary
            model allowing everyone to experience and engage in eSports.
          </p>
        </div>
        <div className="flex flex-row items-center justify-center float-right w-full sm:w-[240px] md:w-[320px] sm:h-[100px]">
          <div className="flex-[1]"></div>
          <a
            href="https://twitter.com/GEDAEsports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/twitter.png"
              alt="twitter"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[2]"></div>
          <a
            href="https://discord.gg/gedaesports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/discord.png"
              alt="twitter"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[2]"></div>
          <a
            href="https://www.youtube.com/@GeDaEsports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/youtube.png"
              alt="twitter"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[2]"></div>
          <a
            href="https://twitch.tv/gedaesports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/twitch.png"
              alt="twitch"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[1]"></div>
        </div>
        <div className="clear-both"></div>
      </div>
    </div>
  );
};

const $footerSocialIconStyle = "w-[24px] object-cover aspect-square";
