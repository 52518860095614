import { useWindowSize } from "@uidotdev/usehooks";
import { screenSize } from "src/types/rwd";

export const useScreenWidth = (): screenSize => {
  const { width } = useWindowSize();

  if (!width) return screenSize.DEFAULT;
  if (width >= 1920) return screenSize["2XL"];
  if (width >= 1400) return screenSize.L;
  if (width >= 1080) return screenSize.MD;
  if (width >= 760) return screenSize.SM;
  if (width >= 576) return screenSize.XS;

  return screenSize.DEFAULT;
};

export const useIsPcScreenWidth = (): boolean => {
  const { width } = useWindowSize();

  if ((width ?? 0) >= 1080) return true;

  return false;
};
