import { DashboardTransactionHistoryCaseTab } from "./dashboard-transaction-history-case-tab";
import { DashboardTransactionHistoryCommonTab } from "./dashboard-transaction-history-common-tab";
import { IPaginationResponse } from "src/types/dashboard";
import { CgCardClubs } from "react-icons/cg";
import { GoArrowSwitch } from "react-icons/go";

interface iDashboardTransactionHistory {
  dashboardTransactions: any;
  dashboardTransactionsReceivedDate: Date | null;
  getDashboardTransactionsCb: () => void;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  historyMode: string;
  setHistoryMode: React.Dispatch<React.SetStateAction<"case" | "common">>;
  paginationResponse: IPaginationResponse;
}

export const DashboardTransactionHistory = (
  props: iDashboardTransactionHistory
) => {
  const {
    dashboardTransactions,
    dashboardTransactionsReceivedDate,
    getDashboardTransactionsCb,
    page,
    pageSize,
    setPage,
    setPageSize,
    historyMode,
    setHistoryMode,
    paginationResponse,
  } = props;

  const tabButtonStyle = `flex flex-row items-center justify-center gap-space-1 text-s sm:text-l leading-[12px] sm:leading-[24px] font-[700] blender-medium normal-case p-space-3 border-0 border-b-[2px] border-solid rounded-[0px] cursor-pointer`;

  return (
    <div
      className="bg-[#00000066] rounded-s min-h-[300px] overflow-hidden backdrop-blur-[8px]"
    >
      <div
        className="grid grid-cols-2 w-full border-0 border-b-[1px] border-solid border-black-200 backdrop-blur-[10px]"
      >
        <div
          className={`${tabButtonStyle} ${
            historyMode === "case"
              ? "text-orange-800 bg-black-600 border-orange-800"
              : "text-grey-100 bg-[#00000066] border-transparent"
          }`}
          onClick={() => setHistoryMode("case")}
        >
          <CgCardClubs className="w-[8px] sm:w-[14px] h-[8px] sm:h-[14px]" />
          <span>Case history</span>
        </div>
        <div
          className={`${tabButtonStyle} ${
            historyMode === "common"
              ? "text-orange-800 bg-black-600 border-orange-800"
              : "text-grey-100 bg-[#00000066] border-transparent"
          }`}
          onClick={() => setHistoryMode("common")}
        >
          <GoArrowSwitch className="w-[8px] sm:w-[14px] h-[8px] sm:h-[14px]" />
          <span>Transaction history</span>
        </div>
      </div>
      <div className="p-space-2 sm:p-space-4">
        {/* Case history */}
        <div className={`${historyMode === "case" ? "block" : "hidden"}`}>
          <DashboardTransactionHistoryCaseTab
            dashboardTransactions={dashboardTransactions}
            dashboardTransactionsReceivedDate={
              dashboardTransactionsReceivedDate
            }
            getDashboardTransactionsCb={getDashboardTransactionsCb}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            paginationResponse={paginationResponse}
          />
        </div>
        {/* Common history */}
        <div className={`${historyMode === "common" ? "block" : "hidden"}`}>
          <DashboardTransactionHistoryCommonTab
            dashboardTransactions={dashboardTransactions}
            dashboardTransactionsReceivedDate={
              dashboardTransactionsReceivedDate
            }
            getDashboardTransactionsCb={getDashboardTransactionsCb}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            paginationResponse={paginationResponse}
          />
        </div>
      </div>
    </div>
  );
};
