import { useEffect, useState } from "react";
import { useGameStore } from "src/stores";
import { history } from "src/stores";
import { IGameSeries } from "src/types/prediction";
import { LandingPredictRow } from "./landing-predict-row";
import { LoadingIconV2 } from "./loading-icon-v2";
import { PREDICTION_LIST_V3 } from "src/config/predictions";

interface iLandingPredict {}

export const LandingPredict = (props: iLandingPredict) => {
  const [game, setGame] = useState<IGameSeries | null>(null);
  const [predictionList, setPredictionList] = useState<any>(null);

  const { getGameSeries, getPredictionList } = useGameStore();

  useEffect(() => {
    getGameSeries(PREDICTION_LIST_V3[5]?.gameId).then((result: any) => {
      if (!result) return;
      setGame(result?.length > 0 ? result[0] : null);
    });

    getPredictionList(PREDICTION_LIST_V3[5]?.gameId).then((result: any) => {
      if (!result) return;
      setPredictionList(result);
    });
  }, [getGameSeries, getPredictionList]);

  return (
    <div
      className="h-full flex items-start items-stretch flex-col mx-0 md:mx-space-2 mb-space-4 p-space-2 md:p-space-6 rounded-s"
      style={{
        background: "linear-gradient(0deg, #2E2E2E 0%, #191919 100%)",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
    >
      <p className="text-l md:text-xxml font-[500] leading-[19px] md:leading-[28px] blender-medium mb-space-2 md:mb-space-4">
        Upcoming predictions
      </p>
      <div className="flex-[1]">
        {game && game?.stream_url && (
          <iframe
            className="w-full min-h-[268px] sm:min-h-[400px] md:min-h-[100px] l:min-h-[300px] rounded-xs"
            src={game?.stream_url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={"GeDa Live"}
          />
        )}

        {game && !game?.stream_url && (
          <img src={game?.image} alt="game-img" className="rounded-xs" />
        )}

        {!predictionList && (
          <div className="flex items-center justify-center">
            <LoadingIconV2 />
          </div>
        )}

        {predictionList &&
          predictionList.map((item: any, i: number) => {
            if (i > 0) {
              return null;
            }

            return (
              <div key={i}>
                {item?.games.map((game: any, q: number) => {
                  if (q > 0) {
                    return null;
                  }

                  return <LandingPredictRow game={game} key={i} />;
                })}
              </div>
            );
          })}
      </div>
      <button
        className="w-full blender-medium text-l leading-[19px] py-[14px] btn-primary"
        onClick={() => history.push("/prediction")}
      >
        Watch & Predict
      </button>
    </div>
  );
};
