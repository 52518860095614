import { ILeaderboardData } from "src/types/leaderboard";

const getBackgroundColor = (index: number) => {
  if (index === 1) return "#FFC71E4D";
  if (index === 2) return "#C5C5C54D";
  if (index === 3) return "#C36D484D";
  return "#FFC71E4D";
};

interface ILeaderboardUser {
  rankings: ILeaderboardData[];
}

export const LeaderboardUser = (props: ILeaderboardUser) => {
  const { rankings } = props;
  return (
    <div className="flex flex-row">
      {rankings.slice(0, 3).map((data, index) => (
        <div
          className="min-w-[114px] h-[28px] pr-space-2 flex mr-space-2 items-center gap-space-1"
          style={{
            borderRadius: "24px 24px 8px 24px",
            backgroundColor: getBackgroundColor(index + 1),
          }}
          key={index}
        >
          {/* user avatar */}
          <img src={data.avatar} alt="avatar" className="w-[28px] h-[28px]" />

          {/* rank and username */}
          <div className="flex flex-col ">
            <div className="flex items-center">
              <p className="blender-medium leading-[12px] text-xs text-grey-100">
                RANK {data.rank}
              </p>
              <img
                src={data.rankImage}
                alt="ranking"
                className="w-[8px] h-[8px]"
              />
            </div>
            <p className="blender-medium leading-[12px] text-m text-grey-100 ">
              {data.displayName}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
