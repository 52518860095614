import { useCallback, useEffect, useMemo, useState } from "react";
import RoulettePro from "react-roulette-pro";
import { RaffleV4Card } from "./raffle-v4-card";
import { RaffleV4Action } from "./raffle-v4-action";
import { openErrorNotification, openWarningNotification } from "./notification";
import "react-roulette-pro/dist/index.css";
import { ModalConfirmAirdropRaffleRetrieved } from "./modal-confirm-airdrop-raffle-retrieved";

import getRandomIntInRange from "src/utils/getRandomIntInRange";
import { useAirdropStore } from "src/stores";
import { createSignature } from "src/utils/crypto";
import { getRandomIndexByPropertyValue } from "src/utils";
// import { writeContract } from "@wagmi/core";
// import { useNetwork, useSwitchNetwork } from "wagmi";

import { getEarnPrizeItemRenderParameterSet } from "src/utils/earn";
import { useAuth } from "./auth-context";

const css = document.createElement("style");
css.textContent = `
.roulette-pro-regular-design-top::before {
border-top :8px solid #FE5219 !important
}

.roulette-pro-regular-design-top::after{
border-bottom :8px solid #FE5219 !important
}
.roulette-pro-regular-design-top.horizontal{
  background : #FE5219 !important;
  z-index: 999;
  left:6%
}
`;

interface iRaffleV4 {
  airdropProfile: any;
  userId: string;
  getProfileCb: any;
  getAirdropProfileCb: any;
  profile: any;
  prizeList: any;
}

export const RaffleV4 = (props: iRaffleV4) => {
  const {
    airdropProfile,
    userId,
    getProfileCb,
    getAirdropProfileCb,
    profile,
    prizeList,
  } = props;

  const [mode, setMode] = useState<string | null>(null);
  const [isDrawn, setIsDrawn] = useState<boolean>(false);
  const [start, setStart] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [prize, setPrize] = useState<{} | null>({});
  const [prizeIndex, setPrizeIndex] = useState<number>(0);
  // Inside the component
  const [openLuckyDrawRetrievedModal, setOpenLuckyDrawRetrievedModal] =
    useState<boolean>(false);

  const [isTrySpinWithoutConnectWallet, setIsTrySpinWithoutConnectWallet] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [tryItLoading, setTryItLoading] = useState(false);

  const { airdropRaffle } = useAirdropStore();
  const { loggedIn, setOpenLoginModal } = useAuth();

  const handlePrizeDefined = useCallback(() => {
    setSpinning(false);

    if (mode === "spin" && isDrawn) {
      try {
        setPrize(prizeList[prizeIndex]);
        setLoading(false);
        setOpenLuckyDrawRetrievedModal(true);
        getProfileCb();
        getAirdropProfileCb();
      } catch (e) {
        console.log("Error:", e);
      }
    }

    if (mode !== "spin") {
      setTryItLoading(false);
    }
  }, [prizeIndex, prizeList, mode, isDrawn, getProfileCb, getAirdropProfileCb]);

  const API = useMemo(
    () => ({
      getTryPrizeIndex: async () => {
        const randomPrizeIndex = getRandomIntInRange(0, prizeList.length - 1);
        return randomPrizeIndex;
      },
      getRealPrizeIndex: async () => {
        try {
          console.log("Enter spin");
          const prize = await airdropRaffle({
            id: userId,
            signature: createSignature(`${userId}`),
          });

          if (!prize) {
            openErrorNotification("Please try again later");
            return getRandomIntInRange(0, prizeList.length - 1);
          }

          let idx = getRandomIndexByPropertyValue(
            prizeList,
            "_id",
            prize["_id"]
          );
          let times = 0;
          const margin = 100;
          while (
            idx >= prizeIndex - margin &&
            idx <= prizeIndex + margin &&
            times < 3
          ) {
            idx = getRandomIndexByPropertyValue(prizeList, "_id", prize["_id"]);
            times++;
          }
          if (idx < 0) {
            openErrorNotification("Please try again later");
          }
          if (idx >= 0) {
            setIsDrawn(true);
          }
          return idx > -1 ? idx : getRandomIntInRange(0, prizeList.length - 1);
        } catch (err) {
          // Handle error
        }
      },
    }),
    [prizeList, airdropRaffle, userId, prizeIndex, setIsDrawn]
  );

  useEffect(() => {
    if (!spinning || !prizeList.length) {
      return;
    }

    const prepare = async () => {
      const newPrizeIndex =
        mode === "try"
          ? await API.getTryPrizeIndex()
          : await API.getRealPrizeIndex();
      setPrizeIndex(newPrizeIndex);
      setStart(false);
      setTryItLoading(false);
    };

    prepare();
    // eslint-disable-next-line
  }, [spinning, prizeList]);

  useEffect(() => {
    if (!prizeIndex || start) {
      return;
    }

    setStart(true);
  }, [prizeIndex, start]);

  const prepareSpin = async (type: string) => {
    if (spinning) return;
    setIsDrawn(false);
    setMode(type);
    setPrizeIndex(0);
    if (type === "spin") {
      if (!loggedIn) {
        setOpenLoginModal(true);
        return;
      }

      if (!profile?.twitter_connected) {
        openWarningNotification("Please authorize Twiiter Login");
        return;
      }

      if (airdropProfile?.tickets < 1) {
        openWarningNotification("You do not have ticket to spin!");
        return;
      }

      if (userId) {
        setLoading(true);
        setSpinning(true);
      }
    }

    if (type !== "spin") {
      try {
        // if (chain?.id !== 80001) {
        //   switchNetwork?.(80001);
        // }
        // await writeContract({
        //   abi: require("../abi/actionAbi.json"),
        //   address: "0x2FFF85719D872c4052E48695508C9cf6925F0922",
        //   functionName: "createActionLog",
        //   args: ["123", address, "spin", "123", "spin"],
        // });
      } catch (e) {
        console.log("e", e);
        return;
      }

      setTryItLoading(true);
      setSpinning(true);
    }
  };

  return (
    <div className="h-full py-space-4 mb-space-4">
      <div className="sm:flex sm:items-start sm:flex-row blender-medium">
        <div
          className="sm:flex-[1] text-xxl sm:text-[32px] md:text-[28px] l:text-[32px] text-grey-600 leading-[32px] sm:leading-[46px] sm:leading-[56px]"
          style={{
            fontWeight: 900,
          }}
        >
          LUCKY SPIN
        </div>
        <div className="flex items-start flex-row">
          <div className="text-[20px] sm:text-[24px] text-grey-100 leading-[32px] sm:leading-[46px] sm:leading-[56px] mr-space-2">
            SPIN COUNT:
          </div>
          <div
            className={`text-[40px] sm:text-[56px] ${
              (airdropProfile?.tickets || "0") !== "0"
                ? "text-grey-100"
                : "text-grey-800"
            } leading-[32px] sm:leading-[46px] sm:leading-[56px]`}
          >
            {airdropProfile?.tickets || "0"}
          </div>
        </div>
      </div>
      <div className="relative">
        {/* airdrop items */}
        <div className="relative w-full py-space-2">
          <RoulettePro
            start={start}
            prizes={prizeList}
            prizeIndex={prizeIndex}
            spinningTime={3}
            onPrizeDefined={handlePrizeDefined}
            options={{
              stopInCenter: true,
              withoutAnimation: false,
            }}
            classes={{
              wrapper: "roulette-pro-wrapper-additional-styles",
            }}
            defaultDesignOptions={{ prizesWithText: true }}
            soundWhileSpinning="/assets/spin_v3.mp3"
            prizeItemRenderFunction={(item: any) => {
              let { name, _id } = item;

              let prizeItemRenderParameterSet =
                getEarnPrizeItemRenderParameterSet(item, prizeList, prizeIndex);

              return (
                <div key={_id} className="w-[205px]">
                  <RaffleV4Card
                    label={name}
                    iconImg={prizeItemRenderParameterSet?.iconImg}
                    mainTxt={prizeItemRenderParameterSet?.mainTxt}
                    showMainTxt={prizeItemRenderParameterSet?.showMainTxt}
                    showImg={prizeItemRenderParameterSet?.showImg}
                    bgImg={prizeItemRenderParameterSet?.bgImg}
                  />
                </div>
              );
            }}
          />
          <div className="absolute inset-y-0 left-0 w-[120px] bg-gradient-to-r from-black to-transparent z-[99]" />
          {/* Left shadow */}
          <div className="absolute inset-y-0 right-0 w-[120px] bg-gradient-to-l from-black to-transparent z-[99]" />
          {/* Right shadow */}

          {!loggedIn && !isTrySpinWithoutConnectWallet && (
            <div
              className="w-full h-full absolute top-0 left-0 z-[99] py-[10px]"
              style={{
                backdropFilter: "blur(10px)",
              }}
            >
              <div className="text-center">
                <p className="text-[24px] leading-[42px] mb-[10px]">
                  Connect wallet, or give it a try
                </p>
                <div className="mb-space-4">
                  <button
                    className="w-full sm:w-[400px] blender-medium text-l leading-[19px] py-[14px] grey"
                    onClick={() => {
                      setIsTrySpinWithoutConnectWallet(true);
                      prepareSpin("try");
                    }}
                  >
                    TRY IT
                  </button>
                </div>
                <div className="mb-[10px]">
                  <button
                    className="w-full sm:w-[400px] blender-medium text-l leading-[19px] py-[14px] btn-primary"
                    onClick={() => prepareSpin("spin")}
                  >
                    SPIN
                  </button>
                </div>
                <p className="small-content">
                  *Try it does not distribute successful spin results
                </p>
              </div>
            </div>
          )}
        </div>

        <ModalConfirmAirdropRaffleRetrieved
          isModalOpen={openLuckyDrawRetrievedModal}
          setIsModalOpen={setOpenLuckyDrawRetrievedModal}
          prize={prize}
          setPrize={setPrize}
        />

        {/* action bar */}
        {(loggedIn || isTrySpinWithoutConnectWallet) && (
          <RaffleV4Action
            prepareSpin={prepareSpin}
            loading={loading}
            tryItLoading={tryItLoading}
          />
        )}
      </div>
    </div>
  );
};
