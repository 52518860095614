import { useRef, useState } from "react";
import { useSpring, animated } from "react-spring/web";
import { LandingPartnersConveyerPartner } from "./landing-partners-conveyer-partner";
import { iPartner } from "src/types/landing";

interface iLandingPartnersHorizontalConveyer {
  partners: iPartner[];
  isReversedScroll: boolean;
}

export const LandingPartnersHorizontalConveyer = (props: iLandingPartnersHorizontalConveyer) => {
  const { partners, isReversedScroll = false } = props;

  const mainRef = useRef<HTMLDivElement | null>(null);

  const [animationOn, setAnimationOn] = useState<boolean>(false);

  let mainScrollWidth = 0; //should not access direcly
  const getMainScrollWidth = () => {
    mainScrollWidth = ((mainRef && mainRef.current) ? mainRef.current.scrollWidth : 0);
    return mainScrollWidth;
  };

  const isChangedMainScrollWidth = () => {
    return (mainScrollWidth !== ((mainRef && mainRef.current) ? mainRef.current.scrollWidth : 0));
  };

  const scrollSpring = useSpring({
    to: {
      x: (!isReversedScroll ? (getMainScrollWidth() / 2) : 1)
    },
    from: {
      x: (!isReversedScroll ? 1 : (getMainScrollWidth() / 2))
    },
    onFrame: (props: any) => {
      if((mainRef && mainRef.current)) {
        if(!isReversedScroll) {
          if(props.x <= 1 ||
            isChangedMainScrollWidth() ||
            props.x >= (getMainScrollWidth() / 2)) {
            setAnimationOn(true);

            setTimeout(() => {
              setAnimationOn(false);
            }, 10);
          }
        }
        else {
          if(isChangedMainScrollWidth() ||
            props.x <= 1) {
            setAnimationOn(true);

            setTimeout(() => {
              setAnimationOn(false);
            }, 10);
          }
        }

        mainRef.current.scrollLeft = props.x;
      }
      else {
        setAnimationOn(true);

        setTimeout(() => {
          setAnimationOn(false);
        }, 10);
      }
    },
    config: {
      duration: 3000 * (partners ? partners.length : 0)
    },
    reset: animationOn
  });

  return (
    <animated.div
      className="overflow-hidden"
      style={{
        ...scrollSpring
      }}
      ref={mainRef}
    >
      <div
        className="flex flex-row gap-space-2"
        style={{
          width: (((partners.length / 2) * 200) + "%")
        }}
      >
        {partners && (
          partners.map((partner: iPartner, q: number) => {
            return (
              <div
                className="flex-1"
                key={q}
              >
                <LandingPartnersConveyerPartner
                  partner={partner}
                />
              </div>
            );
          })
        )}

        {partners && (
          partners.map((partner: iPartner, q: number) => {
            return (
              <div
                className="flex-1"
                key={q}
              >
                <LandingPartnersConveyerPartner
                  partner={partner}
                />
              </div>
            );
          })
        )}
      </div>
    </animated.div>
  );
};
