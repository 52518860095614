import { iPartner } from "src/types/landing";

interface iLandingPartnersConveyerPartner {
  partner: iPartner;
}

export const LandingPartnersConveyerPartner = (
  props: iLandingPartnersConveyerPartner
) => {
  const { partner } = props;

  return (
    <div
      className="relative bg-[#0f0f0f] rounded-tl-[32px] sm:rounded-tl-[64px] rounded-tr-[4px] sm:rounded-tr-[8px] rounded-bl-[4px] sm:rounded-bl-[8px] rounded-br-[32px] sm:rounded-br-[64px] overflow-hidden"
      style={{
        boxShadow: "4px 4px 26px 0px #FFFFFF0D inset, 0px 4px 2px 0px #FFFFFF0D inset, 0px -4px 2px 0px #00000026 inset",
      }}
    >
      <img
        src={"/assets/v4/landing_partner-item-bg.png"}
        className="relative w-full opacity-10 object-cover"
        style={{
          aspectRatio: "1/0.51"
        }}
        alt="background"
      />
      <img
        src={partner.image}
        className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-full object-cover"
        style={{
          aspectRatio: "1/0.51"
        }}
        alt="partner"
      />
    </div>
  );
};
