import { useEffect, useState } from "react";
import { history } from "src/stores";
import { useAirdropStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import { RaffleV4Card } from "./raffle-v4-card";

import { getEarnPrizeItemRenderParameterSet } from "src/utils/earn";

interface iLandingBanner {}

export const LandingEarn = (props: iLandingBanner) => {
  const [prizeList, setPrizeList] = useState<[]>([]);

  const { getPrizeList } = useAirdropStore();

  useEffect(() => {
    getPrizeList().then((result: any) => {
      setPrizeList(result);
    });
  }, [getPrizeList]);

  return (
    <div
      className="h-full flex items-start items-stretch flex-col mx-0 md:mx-space-2 mb-space-4 py-space-2 md:py-space-6 rounded-s"
      style={{
        background: "#191919",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
    >
      <div className="px-space-2 md:px-space-6">
        <p className="text-l md:text-xxml font-[500] leading-[19px] md:leading-[28px] blender-medium mb-space-2 md:mb-space-4">
          Spin & Earn
        </p>
      </div>

      <div className="relative min-h-[220px] flex-[1] mb-space-8 overflow-hidden">
        {prizeList.length === 0 && (
          <div className="flex items-center justify-center">
            <LoadingIconV2 />
          </div>
        )}

        <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-full min-w-[980px] grid grid-cols-5 gap-space-8 place-items-center">
          {prizeList.length > 0 &&
            prizeList.map((prize: any, i: number) => {
              if (i > 4) {
                return null;
              }

              let prizeItemRenderParameterSet =
                getEarnPrizeItemRenderParameterSet(prize, prizeList, i);

              return (
                <div key={i} className="w-[196px]">
                  <RaffleV4Card
                    label={prize?.name}
                    iconImg={prizeItemRenderParameterSet?.iconImg}
                    mainTxt={prizeItemRenderParameterSet?.mainTxt}
                    showMainTxt={prizeItemRenderParameterSet?.showMainTxt}
                    showImg={prizeItemRenderParameterSet?.showImg}
                    bgImg={prizeItemRenderParameterSet?.bgImg}
                  />
                </div>
              );
            })}
        </div>
        <div className="absolute inset-y-0 left-0 w-[60px] bg-gradient-to-r from-black-800 to-transparent z-[99]" />
        <div className="absolute inset-y-0 right-0 w-[60px] bg-gradient-to-l from-black-800 to-transparent z-[99]" />
      </div>

      <div className="px-space-2 md:px-space-6">
        <button
          className="w-full blender-medium text-l leading-[19px] py-[14px] btn-primary"
          onClick={() => history.push("/airdrop")}
        >
          Try now
        </button>
      </div>
    </div>
  );
};
