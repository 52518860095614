import clsx from "clsx";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { CaseOpeningSelectOption } from "./case-opening-select-option";

interface ICaseOpeningSelectList {
  className?: string;
  data: IMysteryBoxPrizeDetail[];
  soldSet: Set<number>;
  handleSellItem: (type: "sell-selected" | "sell-all", index?: number) => void;
}

const CaseOpeningSelectList = ({
  className,
  data,
  soldSet,
  handleSellItem,
}: ICaseOpeningSelectList) => {
  return (
    <div
      className={clsx(className, "flex flex-col gap-space-1 md:gap-space-4")}
    >
      {data.map((item, index) => {
        const isSold = soldSet.has(index);
        if (isSold) return null;

        return (
          <CaseOpeningSelectOption
            key={index}
            item={item}
            isSelected={isSold}
            onSelect={() => {
              if (isSold) return;
              handleSellItem("sell-selected", index);
            }}
            disabled={isSold}
          />
        );
      })}
    </div>
  );
};

export { CaseOpeningSelectList };
