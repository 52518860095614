export const LeaderboardHeader = () => {
  const tStyle =
    "blender-medium font-[700] text-l sm:text-[18px] text-grey-800 leading-none";
  return (
    <div className="flex pt-m pb-s">
      <div className="w-[34px] sm:w-[36px]"></div>
      <div className={`${tStyle}`}>Rank</div>
      <div className={`${tStyle} flex-1 text-center ml-xxs sm:ml-space-4`}>
        Username
      </div>
      <div className={`flex-1 text-center  ${tStyle} `}>Total Earned</div>
    </div>
  );
};
