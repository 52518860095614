import { LoadingIcon } from "./loading-icon";

interface iRaffleV4Action {
  prepareSpin: any;
  loading: boolean;
  tryItLoading: boolean;
}

export const RaffleV4Action = (props: iRaffleV4Action) => {
  const { prepareSpin, loading = false, tryItLoading = false } = props;

  return (
    <div className="sm:flex sm:flex-row sm:justify-end sm:items-center mt-space-4 sm:mt-space-8">
      {loading && tryItLoading ? (
        <LoadingIcon color="#FE5219" />
      ) : (
        <div>
          <button
            className="w-full sm:w-[172px] blender-medium text-l leading-[19px] py-space-3 mb-space-2 sm:mb-0 grey"
            onClick={() => prepareSpin("try")}
          >
            TRY IT
          </button>
          <button
            className="w-full sm:w-[172px] blender-medium text-l leading-[19px] py-space-3 sm:ml-space-4 btn-primary"
            onClick={() => prepareSpin("spin")}
          >
            SPIN
          </button>
        </div>
      )}
    </div>
  );
};
