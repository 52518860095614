export const getMatrixWeaponImage = (name: string) => {
  if (name === "thunder_judgment")
    return "/assets/games/matrix-fire/Thunder_Judgment.jpg";
  if (name === "rioter_starry_sunflowers")
    return "/assets/games/matrix-fire/Rioter_Starry Sunflowers.jpg";
  if (name === "peashooter_mondrian")
    return "/assets/games/matrix-fire/Peashooter_Mondrian.jpg";
  if (name === "guardian_burning")
    return "/assets/games/matrix-fire/Guardian_Burning.jpg";
  if (name === "calf_psycho")
    return "/assets/games/matrix-fire/Calf_Psycho.jpg";
  if (name === "sentinel_scream")
    return "/assets/games/matrix-fire/Sentinel_Scream.jpg";
  if (name === "crossbow_plum_scent")
    return "/assets/games/matrix-fire/Crossbow_Plum Scent.jpg";
  if (name === "rioter_pioneer_wasp")
    return "/assets/games/matrix-fire/Rioter_Pioneer Wasp.jpg";
  if (name === "guardian_pioneer_wasp_illusion")
    return "/assets/games/matrix-fire/Guardian_Pioneer Wasp Illusion.jpg";
  if (name === "rioter_pioneer_dolphin")
    return "/assets/games/matrix-fire/Guardian_Pioneer Dolphin.jpg";
  if (name === `rioter_pioneer_halley's_comet`)
    return "/assets/games/matrix-fire/Rioter_Pioneer Halley_s Comet.jpg";
  if (name === `rioter_pioneer_ranch`)
    return "/assets/games/matrix-fire/Rioter_Pioneer Ranch.jpg";
  if (name === `guardian_pioneer_dolphin`)
    return "/assets/games/matrix-fire/Guardian_Pioneer Dolphin.jpg";
  if (name === `guardian_pioneer_halleys_comet`)
    return "/assets/games/matrix-fire/Guardian_Pioneer Halley_s Comet.jpg";
  if (name === `guardian_pioneer_ranch`)
    return "/assets/games/matrix-fire/Guardian_Pioneer Ranch.jpg";
  return "/assets/games/matrix-fire/firebox.jpeg";
};

export const getCurrencyIcon = (currency: string) => {
  if (currency === "MAX") {
    return "/assets/v4/case-opening-matr1x-coin-icon.png";
  }
  if (currency === "GDD") {
    return "/assets/v4/gdd.png";
  }

  return "/assets/v4/geda-star.png";
};
