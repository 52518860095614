import { createContext, useCallback, useMemo, useRef, useState } from "react";
import { useScreenWidth } from "src/utils/rwd";
import { IMysteryBoxItem } from "src/types/mysteryBox";
import { screenSize } from "src/types/rwd";
import { ROULETTE_TIME_IN_SECONDS } from "src/config/roulette";

interface ICaseOpeningRouletteContext {
  boxItems: IMysteryBoxItem[];
  rollingTimeInSeconds: number;
  isRolling: boolean;
  isHighlightPhase: boolean;
  spin: (isRolling: boolean, onWin?: () => void) => void;
  storeBoxItems: (item: IMysteryBoxItem[]) => void;
  screenSize: screenSize;
}

export const CaseOpeningRouletteContext =
  createContext<ICaseOpeningRouletteContext>({
    boxItems: [],
    rollingTimeInSeconds: 0,
    isRolling: false,
    isHighlightPhase: false,
    spin: () => {},
    storeBoxItems: () => {},
    screenSize: screenSize.DEFAULT,
  });

interface ICaseOpeningRouletteProvider {
  children: React.ReactNode;
}

export const CaseOpeningRouletteProvider = ({
  children,
}: ICaseOpeningRouletteProvider) => {
  const [boxItems, setBoxItems] = useState<IMysteryBoxItem[]>([]);
  const [isRolling, setIsRolling] = useState(false);
  const [isHighlightPhase, setIsHighlightPhase] = useState(false);
  const screenSize = useScreenWidth();

  const spinAudioRef = useRef<HTMLAudioElement>(null);
  const winAudioRef = useRef<HTMLAudioElement>(null);

  const reset = useCallback(() => {
    setIsHighlightPhase(false);
  }, []);

  const spin = useCallback(
    (onWin) => {
      if (isRolling) return;

      reset();

      setIsRolling(true);
      spinAudioRef.current?.play();

      setTimeout(() => {
        setIsRolling(false);

        setTimeout(() => {
          setIsHighlightPhase(true);
          winAudioRef.current?.play();

          if (onWin) {
            onWin();
          }
        }, 1000);
      }, ROULETTE_TIME_IN_SECONDS * 1000);
    },
    [isRolling, reset]
  );

  const value = useMemo(() => {
    return {
      boxItems,
      rollingTimeInSeconds: ROULETTE_TIME_IN_SECONDS,
      isRolling,
      isHighlightPhase,
      spin,
      storeBoxItems: setBoxItems,
      screenSize,
    };
  }, [boxItems, isRolling, isHighlightPhase, spin, setBoxItems, screenSize]);

  return (
    <CaseOpeningRouletteContext.Provider value={value}>
      {children}
      <audio ref={spinAudioRef} src="/assets/spin_v4.mp3" />
      <audio ref={winAudioRef} src="/assets/cash.mp3" />
    </CaseOpeningRouletteContext.Provider>
  );
};
