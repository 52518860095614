import { useEffect, useState, useRef } from "react";
import { useGameStore } from "src/stores";
import { IGameList } from "src/types/prediction";
import { HotGame } from "./hot-game";

interface IPredictionV4GameList {
  selectedGameIds: string;
  setSelectedGameIds: (val: string) => void;
}

export const PredictionV4GameList = (props: IPredictionV4GameList) => {
  const { selectedGameIds, setSelectedGameIds } = props;
  const [games, setGames] = useState<IGameList[]>([]);
  const { getGameCategory } = useGameStore();

  const gameRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    getGameCategory().then((result) => {
      if (!result) return;
      setGames(result);
    });
  }, [getGameCategory]);

  return (
    <div className="rounded-s py-space-2 sm:py-space-4 gap-space-2 sm:gap-space-4 flex flex-col bg-black-600">
      <h2 className="text-l sm:text-xxml text-grey-100 leading-[19px] sm:leading-[28px] !font-[900] blender-medium px-space-2 sm:px-space-4 mb-0">
        Games
      </h2>

      {/* List of Games */}
      <div className="overflow-hidden overflow-x-auto sm:px-space-2">
        <div className="w-auto whitespace-nowrap">
          {games.length > 0 &&
            games.map((item: IGameList, i: number) => {
              const gameIds = item?.game_ids ? item?.game_ids.join(",") : "";
              return (
                <div
                  className="inline-block w-[240px] mx-space-2"
                  ref={(el) => {
                    gameRef.current[i] = el;
                  }}
                >
                  <HotGame
                    item={item}
                    key={i}
                    goIconDirection={
                      selectedGameIds === gameIds ? "down" : "right"
                    }
                    action={() => {
                      if (selectedGameIds === gameIds)
                        return setSelectedGameIds("");
                      setSelectedGameIds(gameIds);

                      if (!gameRef?.current[i]) return;
                      gameRef?.current[i]?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
