import { useState, useEffect } from "react";
import clsx from "clsx";
import { history, useMarketplaceStore } from "src/stores";
import { formatPrice } from "src/utils";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { BsArrowRepeat } from "react-icons/bs";
import { CaseOpeningModal } from "src/components/case-opening-modal";
import { CaseOpeningSelectList } from "src/components/case-opening-select-list";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { LoadingIconV2 } from "./loading-icon-v2";
import { useAuth } from "./auth-context";
import { getCurrencyIcon } from "src/utils/image";

interface ICaseOpeningBoxMultiResultModal {
  className?: string;
  prizes: IMysteryBoxPrizeDetail[];
  isVisible: boolean;
  selectedNum: number;
  onClose: () => void;
  onRetry: () => void;
  bonus: number;
}

const calculatePrice = (
  prizes: IMysteryBoxPrizeDetail[],
  soldSet: Set<number>
) =>
  formatPrice(
    prizes
      .filter((_, index) => !soldSet.has(index))
      .reduce(
        (acc, { weaponPrice = 0, weaponConvertPrice = 0, sellCurrency }) =>
          acc +
          (sellCurrency && sellCurrency !== "USDT"
            ? weaponConvertPrice
            : weaponPrice),
        0
      )
  );

const CaseOpeningBoxMultiResultModal = ({
  className,
  prizes,
  isVisible,
  selectedNum,
  onClose,
  onRetry,
  bonus,
}: ICaseOpeningBoxMultiResultModal) => {
  const [soldSet, setSoldSet] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState<boolean>(false);

  const { sellItemsById: sellWeapons } = useMarketplaceStore();
  const { getDashboardProfileCb } = useAuth();

  const selectedPrizes = prizes.filter((_prize, i) => soldSet.has(i));
  const calculatedAllPrice = calculatePrice(prizes, soldSet);
  const currency = prizes[0].sellCurrency || "USDT";
  // Initialize
  useEffect(() => {
    setSoldSet(new Set());
  }, [prizes]);

  useEffect(() => {
    if (soldSet.size === prizes.length) {
      onClose();
    }
  }, [soldSet, prizes, onClose]);

  const handleSellItem = async (type = "sell-selected", index?: number) => {
    let sellPrizes: any = selectedPrizes;

    if (type === "sell-all") {
      sellPrizes = prizes.filter((_, index) => !soldSet.has(index));
    } else {
      if (index === undefined) return;
      sellPrizes = [prizes[index]];
    }

    if (sellPrizes.length === 0)
      return openWarningNotification("No items to sell.");
    if (loading) return;

    setLoading(true);
    const result = await sellWeapons({
      ids: sellPrizes.map(({ _id }: IMysteryBoxPrizeDetail) => _id),
    });
    setLoading(false);
    if (!result) return openWarningNotification("Failed to sell item.");

    getDashboardProfileCb();

    if (type === "sell-all") {
      // If selling all, set soldSet to include all prize indices
      setSoldSet(new Set(prizes.map((_, index) => index)));
    } else {
      if (index === undefined) return;
      // For sell-selected, update soldSet as before
      setSoldSet(new Set([...Array.from(soldSet), index]));
    }

    openSuccessNotification("Items are sold!");

    if (type === "sell-all" || soldSet.size === prizes.length) {
      onClose();
    }
  };

  const handleGoToInventory = () => {
    history.push("/dashboard#case");
  };

  return (
    <CaseOpeningModal
      className={className}
      isVisible={isVisible}
      title={<span className="mt-space-2 block">Your Drop</span>}
      bonus={bonus}
      content={
        <div className="relative w-full h-[280px] md:h-[320px] flex justify-center items-center">
          <div className="absolute w-full h-full py-space-2 px-space-4 md:py-space-4 md:px-space-8">
            <CaseOpeningSelectList
              className="max-h-full overflow-y-auto scrollbar-hide"
              data={prizes}
              soldSet={soldSet}
              handleSellItem={handleSellItem}
            />
          </div>

          {/* Loading Indicator */}
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
              <LoadingIconV2 />
            </div>
          )}
        </div>
      }
      footerClassName="pt-space-2 mx-space-0 md:mx-[25px] px-space-2"
      footer={
        <div className="flex items-stretch w-full max-h-[138px] gap-space-2 md:gap-space-4">
          {/* Right buttons area */}
          <div className="flex-1 shrink grid grid-cols-2 grid-rows-[16px_38px_38px] gap-xs">
            <div className="self-center md:self-auto text-xs md:text-l text-grey-100 leading-none">
              SELL ALL ITEMS:
            </div>
            <div className="flex items-center justify-end">
              <img
                src={getCurrencyIcon(currency)}
                className="w-[14px] h-[14px] mr-space-1"
                alt=""
              />
              <span className="text-native-green text-l leading-none">
                {calculatedAllPrice}
              </span>
            </div>
            <div
              className={clsx(
                "case-opening-sell-btn",
                "col-span-full",
                "w-full h-full flex items-center justify-center",
                "border rounded-xs border-native-green text-native-green text-l cursor-pointer"
              )}
              onClick={() => handleSellItem("sell-all")}
            >
              Sell All
            </div>
            <div
              className={clsx(
                "group/retry w-full h-[24px] md:h-full flex items-center justify-center gap-space-1",
                "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-l cursor-pointer"
              )}
              onClick={onRetry}
            >
              <BsArrowRepeat className="text-grey-400 transition-transform duration-300 group-hover/retry:rotate-180" />
              <span className="hidden md:block leading-none">
                Try Again ({selectedNum})
              </span>
            </div>
            <div
              className={clsx(
                "w-full h-[24px] md:h-full flex items-center justify-center gap-space-1",
                "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-l cursor-pointer"
              )}
              onClick={handleGoToInventory}
            >
              <span className="hidden md:block leading-none">My inventory</span>
              <img
                className="w-[16px] h-[16px] md:w-[10px] md:h-[10px]"
                src="/assets/v4/dashboard.png"
                alt=""
              />
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

export { CaseOpeningBoxMultiResultModal };
