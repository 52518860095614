import clsx from "clsx";
import React from "react";

interface IMarqueeProps {
  className?: string;
  children?: React.ReactNode;
  repeat?: number;
  duration?: string;
  gap?: string;
  [key: string]: any;
}

export function Marquee({
  className,
  children,
  repeat = 2, // min: 2
  duration = "10s",
  fromColor = "from-[#000]",
  ...props
}: IMarqueeProps) {
  return (
    <div
      className={clsx(
        className,
        "relative flex flex-row overflow-hidden",
        "group [--marquee-gap:8px] [gap:var(--marquee-gap)]"
      )}
      style={{ "--marquee-duration": duration } as React.CSSProperties}
      {...props}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={clsx(
              "animation-marquee",
              "flex flex-row shrink-0 justify-around",
              "[gap:var(--marquee-gap)]",
              "group-hover:[animation-play-state:running]",
              "sm:group-hover:[animation-play-state:paused]"
            )}
          >
            {React.Children.map(children, (child, index) => (
              <div
                key={index}
                className="transition-all duration-500 ease-in-out"
              >
                {child}
              </div>
            ))}
          </div>
        ))}
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 left-0 w-1/6 bg-gradient-to-r",
          fromColor
        )}
      />
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 right-0 w-1/6 bg-gradient-to-l",
          fromColor
        )}
      />
    </div>
  );
}
