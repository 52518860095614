import { history } from "src/stores";
import { AiOutlineHome, AiOutlinePlayCircle } from "react-icons/ai";
import { IoLogoElectron } from "react-icons/io5";
import { MdOutlineGamepad } from "react-icons/md";

const menuItems = [
  {
    name: "Home",
    page: "/",
    isSelectable: true,
    active: ["/", "/"],
  },
  {
    name: "Open case",
    page: "/mystery-box",
    isSelectable: true,
    active: ["/mystery-box", "/mystery-box/:id"],
  },
  {
    name: "Earn",
    page: "/airdrop",
    isSelectable: true,
    active: ["/airdrop"],
  },
  {
    name: "Watch",
    page: "/prediction",
    isSelectable: true,
    active: ["/prediction", "/prediction/:id"],
  },
];

export const BottomMenu = () => {
  const renderIcon = (name: string, isActive: boolean) => {
    const iconClass = `w-[24px] h-[24px] ${
      isActive ? "text-grey-100" : "text-grey-600"
    }`;

    if (name === "Home") return <AiOutlineHome className={iconClass} />;
    if (name === "Open case") return <IoLogoElectron className={iconClass} />;
    if (name === "Earn") return <MdOutlineGamepad className={iconClass} />;
    if (name === "Watch") return <AiOutlinePlayCircle className={iconClass} />;
    return null;
  };
  return (
    <div className="fixed bg-black-600/90 bottom-0 w-full flex flex-row z-[9999] min-h-[75px] max-h-[75px] justify-between items-center">
      {menuItems.map((item, i) => {
        const { name, page, isSelectable, active = [] } = item;
        const currentPath = window?.location?.pathname;
        const isActive = active.some((path) =>
          path.includes(":id")
            ? currentPath.startsWith(path.split(":")[0])
            : path === currentPath
        );
        return (
          <div
            key={i}
            className={`px-space-3 py-space-4 gap-space-1 w-full flex flex-col items-center justify-center ${
              isSelectable ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() => isSelectable && history.push(page)}
          >
            {renderIcon(name, isActive)}
            <p
              className={`text-xs font-semibold ${
                isActive ? "text-grey-100" : "text-grey-600"
              }`}
            >
              {name}
            </p>
          </div>
        );
      })}
    </div>
  );
};
