import { useEffect, useMemo, useState } from "react";
import { useMarketplaceStore } from "src/stores";
import { iAttribute } from "src/types/marketplace";
import { toggleItemInListId } from "src/utils/list";

interface iMarketplaceSkin {
  skinId: string;
  selectedSkins: string[];
  setSelectedSkins?: (val: string[]) => void;
}

export const MarketplaceSkin = (props: iMarketplaceSkin) => {
  const { skinId, selectedSkins, setSelectedSkins } = props;
  const [detail, setDetail] = useState<any>(null);
  const { getAssetDetail } = useMarketplaceStore();

  useEffect(() => {
    if (detail) return;
    getAssetDetail(
      "0x18c3927efa4640afb0521cc348a02593b69226d2",
      String(skinId)
    ).then((result: any) => {
      if (!result) return;
      setDetail(result);
    });
  }, [detail, getAssetDetail, skinId]);

  const rarityObj = detail?.traits.find(
    (item: iAttribute) => item?.trait_type === "Quality"
  );
  const weaponTypeObj = detail?.traits.find(
    (item: iAttribute) => item?.trait_type === "Weapon Type"
  );
  const wearObj = detail?.traits.find(
    (item: iAttribute) => item?.trait_type === "Wear"
  );
  const wearNameObj = detail?.traits.find(
    (item: iAttribute) => item?.trait_type === "Wear Name"
  );

  const borderColor = useMemo(() => {
    if (rarityObj) {
      if (rarityObj?.value === "Rare") return "#019cff";
    }
    return "#FFF";
  }, [rarityObj]);

  const wearColor = useMemo(() => {
    if (wearNameObj) {
      if (wearNameObj?.value === "Worn Out") return "#ee484e";
      if (wearNameObj?.value === "Brand New") return "#25e545";
    }
    return "#737373";
  }, [wearNameObj]);

  const wearImg = useMemo(() => {
    const imgPath = `/assets/v4`;
    if (wearNameObj) {
      if (wearNameObj?.value === "Worn Out") return `${imgPath}/pie-wo.png`;
      if (wearNameObj?.value === "Brand New") return `${imgPath}/pie-bn.png`;
    }
    return `${imgPath}/pie-bn.png`;
  }, [wearNameObj]);

  const itemExists = selectedSkins.some(
    (currentItem) => currentItem === skinId
  );

  return (
    <div
      className="bg-black-800 rounded-xs w-full min-h-[100px] p-space-2 flex flex-col gap-space-4 border-t-[4px] border-solid cursor-pointer"
      style={{
        borderColor: borderColor,
        borderLeftColor: "#5A5A5A",
        borderBottomColor: "#5A5A5A",
        borderRightColor: "#5A5A5A",
        ...(!detail && {
          filter: "blur(5px)",
        }) /* Adjust the blur amount as needed */,
      }}
      onClick={() => {
        if (!setSelectedSkins) return;
        const skinList = toggleItemInListId(selectedSkins, skinId);
        setSelectedSkins(skinList);
      }}
    >
      <div className="relative w-full">
        <img
          src={detail?.image_url || "/assets/v4/matrix-fire-default.jpg"}
          alt="skin-img"
          className="rounded-s"
          style={{
            ...((itemExists || !detail?.image_url) &&
              setSelectedSkins && {
                filter: "blur(5px)",
              }) /* Adjust the blur amount as needed */,
          }}
        />

        {itemExists && setSelectedSkins && (
          <div className="w-[64px] h-[64px] top-[50%] left-[50%] absolute ml-[-32px] mt-[-46px] flex flex-col gap-space-2">
            <img
              src={"/assets/v4/trash.png"}
              alt="remove-icon"
              className="w-[64px] h-[64px]"
            />
            <p className="text-grey-200 text-center blender-medium">Remove</p>
          </div>
        )}
      </div>

      <div className="flex flex-row items-start justify-between gap-space-1 flex-1">
        <p className="text-l text-grey-200 leading-[19px] blender-medium">{detail?.name}</p>
        <p className="text-l text-grey-200 leading-[19px] blender-medium">${0.1}</p>
      </div>

      <p className="small-content text-grey-200 blender-medium">{weaponTypeObj?.value}</p>

      <div className="flex flex-row items-center gap-space-4 justify-between">
        <div className="flex flex-row items-center">
          <div
            className="rounded-full w-[8px] h-[8px]"
            style={{ backgroundColor: borderColor }}
          />
          <p className="small-content text-grey-800 blender-medium ml-space-1">{rarityObj?.value}</p>
        </div>

        <div className="flex flex-row items-center">
          <p
            className="small-content blender-medium mr-space-1"
            style={{
              color: wearColor,
            }}
          >
            {Number(wearObj?.value || 0).toFixed(6)}
          </p>
          <img src={wearImg} alt="pie" className="w-[34px] h-[34px]" />
        </div>
      </div>
    </div>
  );
};
