import { ContainerV3 } from "src/components/container-v3";
import { GoBack } from "src/components/go-back";
import { FaHashtag } from "react-icons/fa6";

const Tos = () => {
  return (
    <ContainerV3>
      <div className="p-space-2 md:px-space-6 md:py-space-4">
        <div className="mb-space-2 md:mb-space-4 flex items-center">
          <GoBack />
        </div>
        <h1 className="text-grey-600">TERMS OF SERVICE</h1>
        <p className="text-grey-600 mb-space-2">
          Date Last Modified: October 25, 2024
        </p>
        <article className="grid gap-space-2 text-grey-800">
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-4">
            <FaHashtag /> Conditions relating to the use of the Service on
            geda.com (“Website”)
          </h2>
          <p className="text-grey-800">
            The Service can be provided to a person with a full legal capacity
            under the legislation of a country or a territory where such person
            resides, which usually means a person is required to be sixteen (16)
            years old. It is possible for a person to use the Service below the
            age of legal capacity if the authorization from the person's parents
            or guardians is given. Such authorization may be provided in a form
            of written authorization of a parent/guarding submitted to Website
            or by implicative actions of a parent/guardian, such as executing a
            payment from a card, opened in the name of a minor.
            <br />
            No individual under the age of fourteen (14) may use the Service,
            regardless of any authorization from a parent/guardian to use the
            Service. If under legislation of a country or a territory, where an
            individual resides, it is required for such individual to be over
            this age to use the Service, the legislation of such country or
            territory shall apply.
            <br />
            You need a supported Web browser to access the Service. You
            acknowledge and agree that Website may cease to support a given Web
            browser and that your continuous use of the Service will require you
            to download a supported Web browser. You also acknowledge and agree
            that the performance of the Service is incumbent on the performance
            of your computer equipment and your Internet connection. You are
            solely responsible for managing your account and password and for
            keeping your password confidential. You are also solely responsible
            for restricting access to your account.
            <br />
            You agree that you are responsible for all activities that occur on
            your account or through the use of your password by yourself or by
            other persons. If you believe that a third party has access to your
            password, use the password regeneration feature of the Service to
            obtain a new password as soon as possible. In all circumstances, you
            agree not to permit any third party to use or access the Service.
          </p>
          <p className="text-grey-800 mt-space-4">
            As a condition to your use of the Service, you agree not to:
            <br />
            impersonate or misrepresent your affiliation with any person or
            entity;
            <br />
            access, tamper with, or use any non-public areas of the Service or
            Website's computer systems;
            <br />
            attempt to probe, scan, or test the vulnerability of the Service or
            any related system or network or breach any security or
            authentication measures used in connection with the Service and such
            systems and networks;
            <br />
            attempt to decipher, decompile, disassemble, reverse engineer or
            otherwise investigate any of the software or components used to
            provide the Service;
            <br />
            harm or threaten to harm other users in any way or interfere with,
            or attempt to interfere with, the access of any user, host or
            network, including without limitation, by sending a virus,
            overloading, flooding, spamming, or mail-bombing the Service;
            <br />
            provide payment information belonging to a third party;
            <br />
            use the Service in an abusive way contrary to its intended use, to
            Website policies and instructions and to any applicable law;
            <br />
            systematically retrieve data or other content from the Service to
            create or compile, directly or indirectly, in single or multiple
            downloads, a collection, compilation, database, directory or the
            like, whether by manual methods, through the use of bots, crawlers,
            or spiders, or otherwise;
            <br />
            make use of the Service in a manner contrary to the terms and
            conditions under which third parties provide facilities and
            technology necessary for the operation of the Service, such as G2A
            or Valve;
            <br />
            infringe third party intellectual property rights when using or
            accessing the Service, including but not limited to in making
            available virtual items by using the Service;
            <br />
            make use of, promote, link to or provide access to materials deemed
            by Website at its sole discretion to be offensive or cause harm to
            Website reputation, including, but not limited to, illegal content
            and pornographic content and content deemed offensive or injurious
            to Website and/or the Service (such as Warez sites, IRC bots and
            torrent sites).
            <br />
            In the event of discrepancies between the actual and intended drops,
            you shall not use such case until you receive notification of the
            elimination of such discrepancies. You also agree to notify us about
            such discrepancies in drop immediately after noticing it via
            support. Using the Services after discovering such discrepancies
            and/or without notification shall be deemed as the use of the
            Service in an abusive way contrary to its intended use.
            <br />
            The withdraw of the skins will be available after you refill your
            balance on the site up to the minimal amount $2 for one transaction
            Due to the nature of the Services (the supply of digital content
            which is not supplied on a tangible medium) after you refill the
            balance you thereby loss your right of withdrawal from the Service,
            however such right of withdrawal and corresponding refunds may be
            kept if envisaged by the applicable legislation. Every request for
            withdrawal shall be addressed to our support team via support
            button.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Termination
          </h2>
          <p className="text-grey-800">
            We may terminate or suspend access to our Service immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach the Terms.
            <br />
            We will terminate or suspend access to our Service immediately,
            without prior notice or liability, for accounts impersonating our
            staff members (e.g. "administrator", "moderator").
            <br />
            Upon termination, your right to use the Service will immediately
            cease. If you wish to terminate your account, you may simply
            discontinue using the Service.
            <br />
            All provisions of the Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
            <br />
            Using multiple accounts for any purposes on the Service is strictly
            forbidden and might lead to a termination of all accounts without
            notice.
            <br />
            We may temporarily restrict access to our Service of users who have
            used multiple bank cards to refill their balance for a quick
            security check through our Support team.
            <br />
            Using strong language or acting disrespectful and rude in a
            conversation with a support agent is forbidden and might lead to a
            termination without notice.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Links To Other websites
          </h2>
          <p className="text-grey-800">
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by Website.
            <br />
            Website has no control over and assumes no responsibility for the
            content, privacy policies, or practices of any third party websites
            or services.
            <br />
            You further acknowledge and agree that Website will not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with use of
            or reliance on any such content, goods or services available on or
            through any such websites or services.
            <br />
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party websites or services that you visit.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            No Monetary Rewards or Compensation
          </h2>
          <p className="text-grey-800">
            This website operates solely for informational or entertainment
            purposes. We do not offer or provide any form of monetary rewards,
            financial compensation, or material incentives in exchange for
            participation, interaction, contributions, or engagement with the
            content or the Services, nor we provide any monetary compensation of
            any nature as a reward for participation in any activities on the
            Website.
            <br />
            You agree, that any item received herein, shall be used solely in
            the corresponding game and shall not be claim to be compensated from
            our side, whether directly or implied.
            <br />
            Any actions taken by users, such as submitting content,
            participating in discussions, or utilizing resources, are entirely
            voluntary and should not be motivated by expectations of financial
            or material gain.
            <br />
            By participating in activities on Website, you acknowledge and agree
            that your participation does not entitle you to any form of payment,
            prize, reward, or reimbursement, either now or in the future, except
            those items, which are of intangible form and strictly implied to be
            used within a corresponding PC game. We are not liable for any
            misunderstandings regarding potential earnings, as no such offers
            exist on Website.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-4">
            <FaHashtag />
            No warranties
          </h2>
          <p className="text-grey-800">
            This website is provided "as is" without any representations or
            warranties, express or implied. Website makes no representations or
            warranties in relation to this website or the information and
            materials provided on this website. Without prejudice to the
            generality of the foregoing paragraph, Website does not warrant
            that: this website will be constantly available, or available at
            all; or the information on this website is complete, true, accurate
            or non-misleading. Nothing on this website constitutes, or is meant
            to constitute, advice of any kind. If you require advice in relation
            to any legal, financial or medical matter you should consult an
            appropriate professional.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Affiliation
          </h2>
          <p className="text-grey-800">
            We are in NO WAY affiliated with or endorsed by the Valve
            corporation, Counter Strike: Global Offensive, Steam or any other
            trademarks of the Valve corporation.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Website account
          </h2>
          <p className="text-grey-800">
            By topping up an amount to your Website account, you accept that the
            credits received (labeled by the $-sign) are a limited use internal
            website credits to be spent exclusively on products and services
            presented on the website and are not worth real money and not
            subject to any refunds.
          </p>
          <p className="text-grey-800">
            From time to time, we may add different events and/or additional
            services on our site with the specific credits with limited use for
            such events and/or additional services. Such credits may be given as
            an additional reward from your activities on our site, can be
            provided in different forms (currency, specific skins and/or
            characters) at our discretion, and shall not be considered as the
            Website Wallet credits. Giving you such credits shall not be deemed
            as the additional Website Wallet and we reserve the right to delete
            such credits and/or limit their use without any compensations.
          </p>
          <p className="text-grey-800">
            Skins that have been sold automatically after not being withdrawn
            within 30 days are not subject to any refunds.
          </p>
          <p className="text-grey-800">
            Avoid websites that are looking like they are owned or controlled by
            Website - we don't have any affiliate sites.
          </p>
          <p className="text-grey-800">
            All of our contact details are listed on our site.
            <br />
            Everyone pretending to be "administrator", "moderator" or "trade
            bot" of Website is a scammer - we will never send you screenshots of
            our control panel or add you on Steam for purposes of trading your
            items.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Additional Terms and Conditions; EULAs
          </h2>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Referral codes
          </h2>
          <p className="text-grey-800">
            The unauthorized use of proprietary referral codes issued
            specifically to particular individuals (e.g. content creators,
            bloggers, media stars, professional e-sports players, the
            "Proprietary Referrals"), as well as any referral codes made up to
            resemble the Proprietary Referrals and mislead the audience or have
            domain names of CS:GO websites in them (the "Misleading Referrals")
            is forbidden. The infringers will not be allowed to benefit from or
            make us of any bonuses accruing from the use of such Proprietary
            Referrals.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Personal data
          </h2>
          <p className="text-grey-800">
            We can use payment emails to notify customers about news and
            discounts.
            <br />
            You are responsible for any fees, taxes or other costs associated
            with the purchase and delivery of your items resulting from charges
            imposed by your relationship with payment services providers or the
            duties and taxes imposed by your local customs officials or other
            regulatory body. For customer service inquiries or disputes, You may
            contact us by email at support@geda.com. (email of submerchant)
            <br />
            Where possible, we will work with you and/or any user selling on our
            website, to resolve any disputes arising from your purchase.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Daily Free Bonus
          </h2>
          <p className="text-grey-800">
            We can provide our users with the Website’s Daily Free bonus,
            subject to the terms and conditions at our discretion, that inter
            alia may limit the possibility to use Daily Free Bonus for certain
            users. A user may be limited in using Daily Free Bonus if certain
            activity or non-abuse conditions are not met, as well as in any
            other situation, that we may consider as an abusive or non-compliant
            with these Terms.
          </p>
          <p className="text-grey-800">
            Daily Free Bonus is strictly limited to one per user and is intended
            for recreational purposes only. Accordingly, any activity which
            unfairly exploits or manipulates Daily Free Bonus is strictly
            forbidden.
          </p>
          <p className="text-grey-800">
            In case a user violates any of the above conditions, or is found to
            be exploiting Daily Free Bonus, GeDa may, in its sole discretion:
            (i) limit the eligibility of such user to participate in the Daily
            Free Bonus or use the Service; (ii) amend the terms of Daily Free
            Bonus; (iii) cancel and forfeit that users Daily Free Bonus
            eligibility or Daily Free Bonus balance at any time.
          </p>
          <p className="text-grey-800">
            All balance (or other benefits) received under Daily Free Bonus will
            be erased from the user's account after 365 days of inactivity. In
            the event of dispute, GeDa's decision is final and not subject to
            appeal.
          </p>
          <p className="text-grey-800">
            GeDa may cancel, amend or modify Daily Free Bonus by publishing the
            amended or modified terms or notice of cancellation. Such amendment,
            modification or cancellation shall be with immediate effect.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Intellectual property
          </h2>
          <p className="text-grey-800">
            All of the content ("Content") on our site - including text,
            software, scripts, code, designs, graphics, photos, sounds, music,
            videos, applications, interactive features, articles news stones,
            sketches animations, stickers, general artwork and other content– is
            property of ours or others we license or otherwise legally access
            this Content from, and is protected by copyright, trademark, patent
            and other laws.
          </p>
          <p className="text-grey-800">
            We reserve all rights to such Content, including but not limited to,
            all copyrights, trademarks, service marks, and trade names are
            owned, registered and/or licensed by us. You do not acquire a
            license or any ownership rights to any copyright, trademarks,
            service marks or trade names through your access or use of our site
            or Content.
          </p>
          <p className="text-grey-800">
            However, there might be the Content, which is produced by ours
            strictly for the dedicated use of such Content on Website (“Our
            Content”). Upon receiving Our Content, whether by purchasing in on
            Website or obtaining it in from any activity on Website we grant you
            a limited, non-exclusive, non-transferable, and revocable license to
            access and use the such for personal, non-commercial purposes,
            strictly on Website. You may view, download, and print content for
            your own informational use, provided that you do not modify or
            redistribute the content without our prior written consent.
          </p>
          <p className="text-grey-800">
            With regard to Our Content, you may not use it for commercial
            purposes without obtaining explicit permission of ours, as well as
            to reproduce, distribute, modify, publicly display, or create
            derivative works based on Our Content without our authorization, nor
            you are allowed to remove, alter, or obscure any proprietary notices
            or labels on Our Content.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Giveaways
          </h2>
          <p className="text-grey-800">
            From time to time we may organize giveaways, either personally or
            via third parties, according to terms and conditions announced with
            regard to such giveaways respectively. We may amend the terms and
            conditions, as well as to cancel any giveaway, if necessary at our
            sole discretion.
          </p>
          <p className="text-grey-800">
            In case such giveaway is organized by a third party, notwithstanding
            a possible use of our Site therein, we shall not be liable for
            inappropriate organization thereof.
          </p>
          <p className="text-grey-800">
            In no event we shall be liable for your possibility to obtain the
            corresponding result, including but not limited to events if the
            incorrect information about an awarded user and/or delivery address
            is provided. Nor shall we be liable for applicable taxes and duties,
            which may arise from such giveaway and/or its delivery to an awarded
            user.
          </p>
          <p className="text-grey-800">
            We shall have a right to withhold the delivery of a giveaway until
            the necessary information is provided, as well as we shall have a
            right to cancel a giveaway to a specific user if such user and/or
            their actions do not comply with the applicable rules, including,
            but not limited to presence in a sanctions list, residing in a
            sanctioned or banned country, fraud actions, provision of wrongful
            data etc. At our discretion and if certain conditions to deliver
            giveaway’s object are not met we may provide a user with the
            web-site balance instead.
          </p>
          <p className="text-grey-800">
            By participating in any giveaway, whether organized by us or by
            third party at our request, you agree to reconfirm the data you
            provided to us upon our request. Such data may include your name,
            surname, middle name/patronym, email, address of residence and
            delivery or any other data, which may be necessary for us to
            accomplish the giveaway and to deliver its results.
          </p>
          <p className="text-grey-800">
            Upon receipt of the giveaway result you also agree to provide us
            with the photo of yours with the giveaway result.
          </p>
          <p className="text-grey-800">
            You consent us to use the data you provided hereinabove for
            assurance of giveaway result were received by users, as well as for
            the marketing of the Site and/or giveaways.
          </p>
          <p className="text-grey-800">
            We shall keep the right to cancel the delivery of giveaway if such
            provisions are not observed by you.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Clarifications; Modifications
          </h2>
          <p className="text-grey-800">
            F.A.Q. sections of specific service shall be considered as the
            additional clarification of these Terms of Service. If any
            discrepancies between F.A.Q. section and these Terms of Service
            appear, the latter shall prevail.
          </p>
          <p className="text-grey-800">
            All clarifications of our service you may receive from our support
            team shall not be considered binding to us to follow such
            clarification.
          </p>
          <p className="text-grey-800">
            If any provision of these Terms of Service are treated differently
            you agree it shall be treated according to our initial intentions.
          </p>
          <p className="text-grey-800">
            We reserve the right to amend, modify or change in any other manner
            these Terms of Service without any preliminary notification and
            solely at our discretion.
          </p>
        </article>
      </div>
    </ContainerV3>
  );
};

export { Tos };
