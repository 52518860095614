import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useAuthStore, useDashboardStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import { IDashboardProfile } from "src/types/dashboard";
import { useAuth } from "./auth-context";

const inputStyle = `py-space-4 w-full h-[40px] text-left placeholder-left pl-space-4 border-black-200 rounded-xs border-solid border-[1px] placeholder-black-200 text-grey-800 placeholder-blender-medium placeholder-font-[700] placeholder-text-l font-[400] text-m md:text-xxl blender-medium bg-transparent placeholder-center placeholder-text-grey-600 focus:border-orange-800 focus:outline-none`;

interface IModalTransfer {
  onCancel: () => void;
  data: IDashboardProfile | null;
  getDashboardProfileCb: () => void;
}

export const ModalTransfer = (props: IModalTransfer) => {
  const { onCancel, data, getDashboardProfileCb } = props;

  const [guid, setGuid] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [timer, setTimer] = useState<number>(0); // 5 minutes = 300 seconds
  const [canResend, setCanResend] = useState<boolean>(false);
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false); // Track if OTP is sent

  const { getOtp } = useAuthStore();
  const { sendP2PTransfer } = useDashboardStore();
  const { userInfo } = useAuth();

  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  useEffect(() => {
    let interval: any = null;
    if (!isOtpSent) return;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0 && isOtpSent) {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [timer, isOtpSent]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const handleSendCode = async () => {
    if (timer > 0)
      return openWarningNotification(
        `Verification code can be resent in ${timer} seconds.`
      );

    if (!userInfo?.email)
      return openWarningNotification("Please fill in email");

    // Logic to send OTP
    const result = await getOtp(userInfo?.email, "transfer");
    if (!result)
      return openWarningNotification(
        "Failed to send verification code, please try again later"
      );

    openSuccessNotification(
      "Please check your inbox and fill in the verification code within 5 minutes."
    );
    setCanResend(false);
    setTimer(300); // Reset timer to 5 minutes
    setIsOtpSent(true); // Mark OTP as sent
  };

  const handleTransfer = async () => {
    if (!guid || !amount || !otp)
      return openWarningNotification("Please fill in all fields");

    if (Number(amount) < 100) {
      return openWarningNotification("Minimum 100 GeDa Stars transfer");
    }

    if (loading) return;
    setLoading(true);
    const result = await sendP2PTransfer({
      guid: guid,
      amount: Number(amount),
      otp: otp,
    });
    setLoading(false);
    if (!result || typeof result === "string")
      return openWarningNotification(
        "P2P Transfer failed, please check the GUID or verification code and try again later."
      );

    openSuccessNotification("Successfully transferred GeDa Stars!");
    getDashboardProfileCb();
    onCancel();
  };

  return (
    <div className="w-screen h-screen z-[999] fixed top-0 left-0 flex items-center justify-center">
      <div
        className="gap-space-2 relative z-[999] w-[320px] h-[568px] md:w-[640px] md:h-[590px]  items-start"
        style={{
          backgroundImage: `url("/assets/v4/${
            !isResponsive ? "receipt-bg" : "modal-mobile-bg"
          }.png")`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-center p-space-7 py-space-7">
          <h2 className="text-xxl md:text-xxxl text-grey-400 flex-1 mt-space-4 md:mt-space-8">
            <span className="acpex text-orange-800">P2P </span>Transfer
          </h2>
          <img
            src="/assets/v4/modal-close.png"
            alt="modal-close"
            className="w-[30px] h-[30px] md:w-[56px] md:h-[56px] cursor-pointer"
            onClick={() => onCancel()}
          />
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        <div className="px-space-8 h-[350px] py-space-4 flex flex-col gap-space-4">
          <div className="flex flex-col gap-space-2">
            <p className="text-xs md:text-m">GUID</p>
            <input
              type="text"
              value={guid}
              onChange={(e) => setGuid(e?.target?.value)}
              className={inputStyle}
              placeholder="GeDa User ID"
            />
          </div>

          <div className="flex flex-col gap-space-2">
            <p className="text-xs md:text-m">Amount</p>
            <input
              type="text"
              value={amount}
              onChange={(e) => {
                const value = e?.target?.value;
                // Regex to allow numbers and decimals only
                if (!isNaN(Number(value)) && /^\d*\.?\d*$/.test(value)) {
                  setAmount(value);
                }
              }}
              className={inputStyle}
              placeholder="GeDa Stars"
            />
            {data?.usdt && (
              <div className="flex flex-row items-center gap-space-2">
                <p className="text-xs md:text-m">My GeDa Stars</p>
                <p className="text-xs md:text-m">•</p>
                <p
                  className="text-xs md:text-m text-orange-800 cursor-pointer"
                  onClick={() => setAmount(String(data?.usdt))}
                >
                  {data?.usdt}
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-space-2">
            <p className="text-xs md:text-m">Verification Code</p>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e?.target?.value)}
              className={inputStyle}
              placeholder="Verification Code"
            />
            <div className="flex flex-row items-center gap-space-2">
              <p className="text-xs md:text-m">
                {canResend
                  ? "Click to resend the verification code"
                  : "Click to send a verification code to your inbox"}
              </p>
              <p className="text-xs md:text-m">•</p>
              <p
                className="text-xs md:text-m text-orange-800 cursor-pointer"
                onClick={() => handleSendCode()}
              >
                {canResend
                  ? "Resend"
                  : isOtpSent
                  ? `Resend in ${formatTime(timer)}`
                  : "Send"}
              </p>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        {/* Confirmation */}
        <div className="flex justify-center items-center py-space-4 px-space-4">
          <button
            className="border border-orange-600 w-[400px] h-[47px] bg-black-800 flex justify-center items-center rounded-xs blender-medium text-orange-600 font-[500] text-xs md:text-m"
            onClick={(e) => handleTransfer()}
          >
            {loading ? <LoadingIconV2 /> : "Transfer"}
          </button>
        </div>
      </div>

      {/* Overlay */}
      <div className="bg-black-800/60 w-full h-full left-0 absolute"></div>
    </div>
  );
};
