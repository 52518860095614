interface ISearchInput {
  search: string;
  setSearch: (val: string) => void;
}

export const SearchInput = ({ search, setSearch }: ISearchInput) => {
  return (
    <div className="relative">
      <input
        className="md:w-[270px] w-[120px] border-[1px] border-solid border-black-100 bg-black-800 py-space-2 px-space-4 pl-space-9 placeholder:text-black-100 rounded-xs text-white"
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e?.target?.value)}
      />
      <img
        src="/assets/v4/search.png"
        alt="search"
        className="w-[15px] h-[15px] absolute left-space-4 top-space-3"
      />
    </div>
  );
};
