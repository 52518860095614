import { isMobile } from "react-device-detect";
import { useWindowSize } from "@uidotdev/usehooks";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect } from "wagmi";
import { useCallback, useEffect, useState } from "react";
import { useAuthStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useAuth } from "./auth-context";

const btnStyle =
  "border w-full h-[47px] flex justify-center items-center blender-medium font-[500] text-xs md:text-m btn-primary";

interface IModalConnectAddress {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
}

export const ModalConnectAddress = (props: IModalConnectAddress) => {
  const { setIsModalOpen } = props;
  const [stage, setStage] = useState<
    "connect" | "address_exist" | "address_not_exist"
  >("connect");
  const [loading, setLoading] = useState<boolean>(false);

  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { setLoggedIn, setUserInfo } = useAuth();
  const { checkAddress, connectWalletToAccount } = useAuthStore();
  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  const handleCancel = () => {
    localStorage.setItem("_geda_show_connect_wallet_modal", "no");
    setIsModalOpen(false);
  };

  const handleCreateAccountCb = useCallback(
    async (integrate: boolean) => {
      if (!address) return;
      if (loading) return;
      setLoading(true);
      const result = await connectWalletToAccount({
        address: address,
        integrate: integrate ? "yes" : "no",
      });
      if (!result || typeof result === "string")
        return openWarningNotification("Please try again.");

      if (integrate) {
        setLoggedIn(false);
        setUserInfo(null);
        openSuccessNotification(
          "Your account is integrated to the address, please relogin!"
        );
      }

      if (!integrate) {
        openSuccessNotification("Your address is integrated to the account.");
      }

      localStorage.setItem("_geda_show_connect_wallet_modal", "no");
      setIsModalOpen(false);
    },
    [
      address,
      loading,
      connectWalletToAccount,
      setLoggedIn,
      setUserInfo,
      setIsModalOpen,
    ]
  );

  useEffect(() => {
    if (!address) return setStage("connect");
    const checkAddressExist = async () => {
      const result = await checkAddress(address);
      if (!result) {
        handleCreateAccountCb(false);
      }
      if (result) setStage("address_exist");
    };

    checkAddressExist();
  }, [address, checkAddress, handleCreateAccountCb]);

  return (
    <div className="w-screen h-screen z-[999] fixed top-0 left-0 flex items-center justify-center">
      <div
        className="gap-space-2 relative z-[999] w-[320px] h-[568px] md:w-[640px] md:h-[590px]  items-start"
        style={{
          backgroundImage: `url("/assets/v4/${
            !isResponsive ? "receipt-bg" : "modal-mobile-bg"
          }.png")`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-center p-space-7 py-space-7">
          <h2 className="text-xxl md:text-xxxl text-grey-400 flex-1 mt-space-4 md:mt-space-8">
            Welcome to <span className="acpex text-orange-800">Geda</span>
          </h2>
          <img
            src="/assets/v4/modal-close.png"
            alt="modal-close"
            className="w-[30px] h-[30px] md:w-[56px] md:h-[56px] cursor-pointer"
            onClick={() => handleCancel()}
          />
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        <div className="px-space-8 h-[350px] py-space-4 flex flex-col gap-space-4 items-center justify-center">
          {stage === "connect" && (
            <>
              <p className="text-orange-800 text-l text-center">
                Do you want to connect wallet to your account now?
              </p>
              <p className="text-s text-center">
                You can always connect wallet again in the dashboard.
              </p>
            </>
          )}

          {stage === "address_exist" && (
            <>
              {loading && <LoadingIconV2 />}
              {!loading && (
                <>
                  {" "}
                  <p className="text-orange-800 text-l text-center">
                    You have an account with the connected address!
                  </p>
                  <p className="text-s text-center">{address}</p>
                  <p
                    className="text-orange-800 text-m text-center cursor-pointer"
                    onClick={() => disconnect()}
                  >
                    Change address
                  </p>
                  <p className="text-m text-center">
                    Do you want to migrate this account to the existing address
                    account? You will have to re-login once the migration is
                    completed.
                  </p>
                </>
              )}
            </>
          )}
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        {/* Confirmation */}
        <div className="flex flex-row gap-space-2 justify-center items-center py-space-4 px-space-4 max-w-[80%] mx-auto">
          {stage === "connect" && (
            <button className={btnStyle} onClick={(e) => open()}>
              {"Connect"}
            </button>
          )}

          {stage === "address_exist" && (
            <>
              <button
                className={btnStyle}
                onClick={(e) => handleCreateAccountCb(false)}
              >
                {"Create new"}
              </button>
              <button
                className={btnStyle}
                onClick={(e) => handleCreateAccountCb(true)}
              >
                {"Integrate to existing"}
              </button>
            </>
          )}
        </div>
      </div>

      {/* Overlay */}
      <div className="bg-black-800/60 w-full h-full left-0 absolute"></div>
    </div>
  );
};
