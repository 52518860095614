import { LoadingIconV2 } from "./loading-icon-v2";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DashboardInventorySpin } from "./dashboard-inventory-spin";
import { ModalInventoryCaseSell } from "./modal-inventory-case-sell";
import { history, useAuthStore, useMarketplaceStore } from "src/stores";
import { IDashboardProfile } from "src/types/dashboard";
import {
  DISPLAY_MODE_CASE,
  DISPLAY_MODE_SPIN,
  DISPLAY_MODE_MAX_CASE,
} from "src/config/dashboard";
import { DashboardInventoryCase } from "./dashboard-inventory-case";
import { useAuth } from "./auth-context";
import { formatDollar } from "src/utils";
import { ModalInventoryCaseSellAll } from "./modal-inventory-case-sell-all";
import { ISellItem } from "src/types/mysteryBox";
import { TbDeviceGamepad3 } from "react-icons/tb";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { getCurrencyIcon } from "src/utils/image";

interface iDashboardInventory {
  data: IDashboardProfile;
  dashboardDisplayMode: string;
  setDashboardDisplayMode: (val: string) => void;
  getDashboardProfileCb: () => void;
  getDashboardTransactionsCb: () => void;
}

export const DashboardInventory = (props: iDashboardInventory) => {
  const {
    data,
    dashboardDisplayMode,
    setDashboardDisplayMode,
    getDashboardTransactionsCb,
  } = props;

  const tabButtonStyle = `flex flex-col sm:flex-row items-center justify-center gap-space-0 sm:gap-space-1 text-s sm:text-l leading-[12px] sm:leading-[24px] font-[700] blender-medium normal-case p-space-1 sm:p-space-3 border-0 border-b-[2px] border-solid rounded-[0px] cursor-pointer`;

  const [spins, setSpins] = useState<[] | null>(null);
  const [weapons, setWeapons] = useState<ISellItem[] | null>(null);
  const [openInventoryCaseSellModal, setOpenInventoryCaseSellModal] =
    useState(false);
  const [openInventoryCaseSellAllModal, setOpenInventoryCaseSellAllModal] =
    useState(false);
  const [inventoryCaseSelectedItems, setInventoryCaseSelectedItems] = useState<
    any[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { loggedIn, getDashboardProfileCb } = useAuth();
  const { getMyBag, getMyCaseBag, getMyCaseBagByCurrency } = useAuthStore();
  const { sellItemsById: sellWeapons } = useMarketplaceStore();

  const getMyBagCb = useCallback(() => {
    getMyBag().then((list) => {
      if (!list) return;
      setSpins(list);
    });
  }, [getMyBag]);

  const getMyCaseBagCb = useCallback(() => {
    getMyCaseBag().then((list) => {
      if (!list) return;
      setWeapons(list);
    });
  }, [getMyCaseBag]);

  const getMyCaseBagByCurrencyCb = useCallback(
    (currency?: string) => {
      getMyCaseBagByCurrency(currency).then((list) => {
        if (!list) return;
        setWeapons(list);
      });
    },
    [getMyCaseBagByCurrency]
  );

  const handleCancelInventoryCaseSellModal = () => {
    setInventoryCaseSelectedItems([]);
    setOpenInventoryCaseSellModal(false);
  };

  const handleCancelInventoryCaseSellAllModal = () => {
    setInventoryCaseSelectedItems([]);
    setOpenInventoryCaseSellAllModal(false);
  };

  const handleCompleteInventoryCaseSellModal = () => {
    setInventoryCaseSelectedItems([]);

    setWeapons(null);
    getMyCaseBagCb();

    getDashboardProfileCb();
    getDashboardTransactionsCb();

    setOpenInventoryCaseSellModal(false);
  };

  const handleCompleteInventoryCaseSellAllModal = () => {
    setInventoryCaseSelectedItems([]);

    setWeapons(null);
    DISPLAY_MODE_MAX_CASE === dashboardDisplayMode
      ? getMyCaseBagByCurrencyCb("MAX")
      : getMyCaseBagCb();

    getDashboardProfileCb();
    getDashboardTransactionsCb();

    setOpenInventoryCaseSellAllModal(false);
  };

  const handleSellAllWeapons = async () => {
    if (loading) return;
    if (sellItems.length === 0) {
      return openWarningNotification("No items to sell");
    }

    if (loading) return;
    setLoading(true);

    let formattedSellItems = sellItems.map((sellItem: any) => {
      return sellItem?._id;
    });
    const result = await sellWeapons({ ids: formattedSellItems });

    setLoading(false);
    if (!result || typeof result === "string") {
      openWarningNotification("Failed to sell items.");
      return;
    }

    openSuccessNotification("Sell complete, the transfer may take some time.");
    DISPLAY_MODE_MAX_CASE === dashboardDisplayMode
      ? getMyCaseBagByCurrencyCb("MAX")
      : getMyCaseBagCb();
    getDashboardProfileCb();
  };

  useEffect(() => {
    if (!loggedIn) {
      setSpins([]);
      setWeapons([]);
      return;
    }

    if (dashboardDisplayMode === DISPLAY_MODE_CASE) {
      setTimeout(() => {
        getMyCaseBagCb();
      }, 1000);
    }
    if (dashboardDisplayMode === DISPLAY_MODE_SPIN) {
      setTimeout(() => {
        getMyBagCb();
      }, 1000);
    }
    if (dashboardDisplayMode === DISPLAY_MODE_MAX_CASE) {
      setTimeout(() => {
        getMyCaseBagByCurrencyCb("MAX");
      }, 1000);
    }
  }, [
    dashboardDisplayMode,
    loggedIn,
    getMyBagCb,
    getMyCaseBagCb,
    getMyCaseBagByCurrencyCb,
  ]);

  const sellItems = useMemo(() => {
    if (!weapons || weapons.length === 0) return [];
    return weapons?.filter(
      (weapon: any) => weapon?.item && !weapon?.claimed && !weapon?.sold
    );
  }, [weapons]);

  const totalWeaponPrice = useMemo(() => {
    return sellItems?.reduce((total, weapon: any) => {
      return (
        total +
        (weapon?.item?.weaponConvertPrice || weapon?.item?.weaponPrice || 0)
      );
    }, 0);
  }, [sellItems]);

  return (
    <div>
      <div className="relative bg-[#19191966] p-0 sm:p-space-4 rounded-s backdrop-blur-[8px]">
        <p className="hidden sm:block text-l sm:text-[24px] leading-[19px] sm:leading-[28px] font-[500] blender-medium text-grey-100 mb-space-4">
          Inventory
        </p>
        <div className="grid grid-cols-3 w-full border-0 border-b-[1px] border-solid border-black-200 rounded-tl-s rounded-tr-s overflow-hidden backdrop-blur-[10px]">
          <div
            className={`${tabButtonStyle} ${
              dashboardDisplayMode === DISPLAY_MODE_SPIN
                ? "text-orange-800 bg-black-600 border-orange-800"
                : "text-grey-100 bg-[#00000066] border-transparent"
            }`}
            onClick={() => setDashboardDisplayMode(DISPLAY_MODE_SPIN)}
          >
            <TbDeviceGamepad3 className="w-[14px] h-[14px] text-orange-800" />
            <span>Spin</span>
          </div>
          <div
            className={`${tabButtonStyle} ${
              dashboardDisplayMode === DISPLAY_MODE_CASE
                ? "text-orange-800 bg-black-600 border-orange-800"
                : "text-grey-100 bg-[#00000066] border-transparent"
            }`}
            onClick={() => setDashboardDisplayMode(DISPLAY_MODE_CASE)}
          >
            <img
              src="/assets/v4/geda-star.png"
              alt="geda-star"
              className="w-[14px] h-[14px]"
            />
            <span>GeDa Star Case</span>
          </div>

          <div
            className={`${tabButtonStyle} ${
              dashboardDisplayMode === DISPLAY_MODE_MAX_CASE
                ? "text-orange-800 bg-black-600 border-orange-800"
                : "text-grey-100 bg-[#00000066] border-transparent"
            }`}
            onClick={() => setDashboardDisplayMode(DISPLAY_MODE_MAX_CASE)}
          >
            <img
              src="/assets/v4/case-opening-matr1x-coin-icon.png"
              alt="geda-star"
              className="w-[14px] h-[14px]"
            />
            <span>MAX Case</span>
          </div>
        </div>

        <div className="p-space-2 sm:p-space-4">
          {/* Spin inventory */}
          <div
            className={`${
              dashboardDisplayMode === DISPLAY_MODE_SPIN ? "block" : "hidden"
            }`}
          >
            {loggedIn && !spins && (
              <div className="flex items-center justify-center py-space-8">
                <LoadingIconV2 />
              </div>
            )}

            {loggedIn && spins && spins.length === 0 && (
              <div className="flex items-center justify-center">
                <button
                  className={`blender-medium text-s sm:text-l leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 btn-primary`}
                  onClick={() => history.push("/airdrop")}
                >
                  Play Game
                </button>
              </div>
            )}

            {loggedIn && spins && spins.length > 0 && (
              <div className="grid grid-cols-2 md:grid-cols-3 l:grid-cols-4 2xl:grid-cols-5 gap-space-2 sm:gap-space-4">
                {spins.map((item: any, i: number) => {
                  return (
                    <DashboardInventorySpin
                      item={item}
                      getMyBagCb={getMyBagCb}
                      key={i}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {/* Case inventory */}
          <div
            className={`${
              dashboardDisplayMode === DISPLAY_MODE_CASE ? "block" : "hidden"
            }`}
          >
            {loggedIn && !weapons && (
              <div className="flex items-center justify-center py-space-8">
                <LoadingIconV2 />
              </div>
            )}

            {loggedIn && weapons && weapons.length === 0 && (
              <div className="flex items-center justify-center">
                <button
                  className={`blender-medium text-s sm:text-l leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 btn-primary`}
                  onClick={() => history.push("/mystery-box")}
                >
                  Open case
                </button>
              </div>
            )}

            {loggedIn && weapons && weapons.length > 0 && (
              <div className="grid grid-cols-2 md:grid-cols-3 l:grid-cols-4 2xl:grid-cols-5 gap-space-2 sm:gap-space-4">
                <button
                  className="flex flex-col items-center justify-center gap-space-2 border-[1px] border-solid border-orange-600 text-s sm:text-l text-orange-600 leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 rounded-xs"
                  onClick={() => handleSellAllWeapons()}
                >
                  {loading && <LoadingIconV2 />}
                  {!loading && (
                    <>
                      <p className="text-s sm:text-l">Sell all weapons</p>
                      <p className="text-s sm:text-l flex flex-row items-center gap-space-1">
                        <img
                          src="/assets/v4/geda-star.png"
                          alt="sell"
                          className="w-[14px] h-[14px]"
                        />{" "}
                        {formatDollar(totalWeaponPrice)}
                      </p>
                    </>
                  )}
                </button>
                {weapons.map((item: any, i: number) => {
                  return (
                    <DashboardInventoryCase
                      item={item}
                      getMyBagCb={getMyCaseBagCb}
                      key={i}
                    />
                  );
                })}
              </div>
            )}
          </div>

          {/* MAX Case inventory */}
          <div
            className={`${
              dashboardDisplayMode === DISPLAY_MODE_MAX_CASE
                ? "block"
                : "hidden"
            }`}
          >
            {loggedIn && !weapons && (
              <div className="flex items-center justify-center py-space-8">
                <LoadingIconV2 />
              </div>
            )}

            {loggedIn && weapons && weapons.length === 0 && (
              <div className="flex items-center justify-center">
                <button
                  className={`blender-medium text-s sm:text-l leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 btn-primary`}
                  onClick={() => history.push("/mystery-box")}
                >
                  Open case
                </button>
              </div>
            )}

            {loggedIn && weapons && weapons.length > 0 && (
              <div className="grid grid-cols-2 md:grid-cols-3 l:grid-cols-4 2xl:grid-cols-5 gap-space-2 sm:gap-space-4">
                <button
                  className="flex flex-col items-center justify-center gap-space-2 border-[1px] border-solid border-orange-600 text-s sm:text-l text-orange-600 leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 rounded-xs"
                  onClick={() => handleSellAllWeapons()}
                >
                  {loading && <LoadingIconV2 />}
                  {!loading && (
                    <>
                      <p className="text-s sm:text-l">Sell all weapons</p>
                      <p className="text-s sm:text-l flex flex-row items-center gap-space-1">
                        <img
                          src={getCurrencyIcon(
                            DISPLAY_MODE_MAX_CASE === dashboardDisplayMode
                              ? "MAX"
                              : "USDT"
                          )}
                          alt="sell"
                          className="w-[14px] h-[14px]"
                        />{" "}
                        {formatDollar(totalWeaponPrice)}
                      </p>
                    </>
                  )}
                </button>
                {weapons.map((item: any, i: number) => {
                  return (
                    <DashboardInventoryCase
                      item={item}
                      getMyBagCb={
                        DISPLAY_MODE_MAX_CASE === dashboardDisplayMode
                          ? () => getMyCaseBagByCurrencyCb("MAX")
                          : getMyCaseBagCb
                      }
                      key={i}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      {openInventoryCaseSellModal && (
        <ModalInventoryCaseSell
          onCancel={() => handleCancelInventoryCaseSellModal()}
          onComplete={() => handleCompleteInventoryCaseSellModal()}
          dashboardProfile={data}
          sellItems={inventoryCaseSelectedItems}
        />
      )}
      {openInventoryCaseSellAllModal && (
        <ModalInventoryCaseSellAll
          onCancel={() => handleCancelInventoryCaseSellAllModal()}
          onComplete={() => handleCompleteInventoryCaseSellAllModal()}
          dashboardProfile={data}
          sellItems={sellItems || []}
        />
      )}
    </div>
  );
};
