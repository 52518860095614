import moment from "moment";
import { useWindowSize } from "@uidotdev/usehooks";
import { formatDollar, formatName } from "src/utils";
import { getCurrencyIcon } from "src/utils/image";

const tdStyle = `align-top pt-space-4`;

interface iDashboardTransactionHistoryCaseRow {
  item: any;
}

export const DashboardTransactionHistoryCaseRow = (
  props: iDashboardTransactionHistoryCaseRow
) => {
  const { item } = props;

  const size: any = useWindowSize();

  const isShowLess = () => {
    return size?.width < 1080;
  };

  const getRenderStatus = (status: string) => {
    if (status === "pending-payment") {
      return "pending";
    }
    return status;
  };

  const formatDate = (date: Date) => {
    return moment(date).format("MMMM D, YYYY");
  };

  return (
    <tr>
      <td className={`${tdStyle} text-left`}>
        <div className="flex flex-col gap-space-1">
          {item?.type === "mystery-box" &&
            Array.isArray(item?.extra) &&
            item?.extra.map((extraItem: any, i: number) => {
              return (
                <div className="flex flex-row gap-space-2" key={i}>
                  <img
                    src={
                      extraItem?.imgUrl || "/assets/v4/matrix-fire-default.jpg"
                    }
                    alt="prize-img"
                    className="w-[24px] h-[24px] aspect-square border-[1px] border-solid border-native-yellow rounded-full"
                  />
                  <p className="text-l leading-[24px] blender-medium">
                    {formatName(extraItem?.name)}
                  </p>
                </div>
              );
            })}

          {item?.type === "sell-item" &&
            Array.isArray(item?.extra) &&
            item?.extra.map((extraItem: any, i: number) => {
              return (
                <div className="flex flex-row gap-space-2" key={i}>
                  <img
                    src={
                      extraItem?.item?.imgUrl ||
                      "/assets/v4/matrix-fire-default.jpg"
                    }
                    alt="prize-img"
                    className="w-[24px] h-[24px] aspect-square border-[1px] border-solid border-native-yellow rounded-full"
                  />
                  <p className="text-l leading-[24px] blender-medium">
                    {extraItem?.item?.name}
                  </p>
                </div>
              );
            })}

          {item?.type === "sell-item" && !Array.isArray(item?.extra) && (
            <div className="flex flex-row gap-space-2">
              <img
                src={
                  item?.extra?.item?.imgUrl ||
                  "/assets/v4/matrix-fire-default.jpg"
                }
                alt="prize-img"
                className="w-[24px] h-[24px] aspect-square border-[1px] border-solid border-native-yellow rounded-full"
              />
              <p className="text-l leading-[24px]">{item?.extra?.item?.name}</p>
            </div>
          )}
        </div>
      </td>
      <td className={`${tdStyle} text-center`}>
        <p className="text-l leading-[24px] text-grey-600 blender-medium capitalize">
          {getRenderStatus(item?.status)}
        </p>
      </td>
      {!isShowLess() && (
        <td className={`${tdStyle} text-center`}>
          <p className="text-l leading-[24px] blender-medium">
            {formatDate(item?.created_at)}
          </p>
        </td>
      )}
      <td className={`${tdStyle} text-right`}>
        <div className="flex flex-row items-center justify-end gap-space-1">
          <img
            src={getCurrencyIcon(item?.currency || "USDT")}
            alt={`${item?.currency}-icon`}
            className="w-[14px] h-[14px]"
          />
          <p className="text-l leading-[24px] text-grey-600 blender-medium">
            {formatDollar(item?.amount)}
          </p>
        </div>
      </td>
      {!isShowLess() && (
        <td className={`${tdStyle} text-right`}>
          <p className="text-l leading-[24px] text-grey-600 blender-medium capitalize">
            {item?.status === "done"
              ? formatDate(item?.updated_at)
              : getRenderStatus(item?.status)}
          </p>
        </td>
      )}
    </tr>
  );
};
