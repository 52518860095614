import { useState } from "react";
import { formatDollar, formatName } from "src/utils";
import { getRarityColor } from "src/utils/color";
import { ModalClaimCaseForm } from "./modal-claim-case-form";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useMarketplaceStore } from "src/stores";
import { useAuth } from "./auth-context";
import { LoadingIconV2 } from "./loading-icon-v2";
import { getCurrencyIcon } from "src/utils/image";

interface iDashboardInventoryCase {
  item: any;
  getMyBagCb: any;
}

export const DashboardInventoryCase = (props: iDashboardInventoryCase) => {
  const { item, getMyBagCb } = props;

  const btnStyle = `w-full border-[1px] border-solid p-space-2 rounded-xs cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px]`;

  const [isShowMoreAction, setIsShowMoreAction] = useState(false);
  const [isOpenModalClaimForm, setIsOpenModalClaimForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { sellItemsById: sellWeapons } = useMarketplaceStore();
  const { getDashboardProfileCb } = useAuth();

  const handleClaim = (item: any) => {
    if (item?.claimed) {
      return;
    }
    setIsOpenModalClaimForm(true);
  };

  const handleSell = async (item: any) => {
    if (loading) return;
    if (item?.sold) return;
    if (item?.claimed) return;

    if (loading) return;
    setLoading(true);

    const result = await sellWeapons({ ids: [item?._id] });

    setLoading(false);
    if (!result || typeof result === "string") {
      openWarningNotification("Failed to sell item.");
      return;
    }

    openSuccessNotification("Sell complete, the transfer may take some time.");
    getDashboardProfileCb();
    getMyBagCb();
  };

  const getClaimButtonTxt = (item: any) => {
    if (item?.claimed) {
      if (item?.claim_status === "pending_to_airdrop") {
        return "DISTRIBUTE SOON";
      }
      return "CLAIMED";
    }

    return "CLAIM";
  };

  const getSellButtonTxt = (item: any) => {
    if (item?.sold) {
      return "SOLD";
    }
    return "Sell";
  };

  return (
    <div
      className="relative p-space-2 rounded-s overflow-hidden"
      style={{
        background: "linear-gradient(180deg, #BCC3DA 0%, #EEF7FB 100%)",
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "linear-gradient(180deg, rgba(46, 46, 46, 0) 50%, rgba(46, 46, 46, 0.3) 75.62%, #2E2E2E 100%)",
        }}
      />
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "linear-gradient(360deg, rgba(46, 46, 46, 0) 59.5%, rgba(46, 46, 46, 0.3) 79.75%, #2E2E2E 100%)",
        }}
      />
      <div className="relative overflow-hidden">
        <img
          src={item?.item?.imgUrl || "/assets/v4/matrix-fire-default.jpg"}
          alt="prize-img-back"
          className="relative w-[80%] aspect-square mb-space-2 opacity-0"
        />
        <img
          src={item?.item?.imgUrl || "/assets/v4/matrix-fire-default.jpg"}
          alt="prize-img"
          className={`absolute transform ${
            isShowMoreAction ? "top-[15px] w-[70%]" : "top-[0px] w-[100%]"
          } left-[50%] translate-x-[-50%] aspect-square rounded-xs transition-all duration-500`}
        />
        <div className="absolute top-0 left-0 w-full">
          <p className="text-s sm:text-[24px] leading-[14px] sm:leading-[28px] font-[500] blender-medium text-grey-100 mb-space-1 sm:mb-space-2">
            {formatName(item?.item?.name)}
          </p>
          {item?.item?.quality && (
            <div className="flex flex-row items-center">
              <div
                className="rounded-full w-[8px] h-[8px]"
                style={{ backgroundColor: getRarityColor(item?.item?.quality) }}
              />
              <p className="small-content text-grey-200 blender-medium ml-space-1">
                {formatName(item?.item?.quality)}
              </p>
            </div>
          )}
        </div>
        <div>
          <div className="relative h-[32px] sm:h-[45px]">
            <div className="absolute bottom-0 left-0 w-full">
              {!item?.claimed && (
                <div
                  className={`${btnStyle} bg-black-400 border-grey-800`}
                  onClick={() => handleSell(item)}
                >
                  {loading && <LoadingIconV2 />}
                  {!loading && (
                    <>
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200">
                        {getSellButtonTxt(item)}
                      </p>
                      {!item?.sold && (
                        <img
                          src={getCurrencyIcon(
                            item?.currency ? item?.currency : "USDT"
                          )}
                          alt="sell"
                          className="w-[14px] h-[14px]"
                        />
                      )}
                      {!item?.sold && item?.item?.weaponPrice && (
                        <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200">
                          (
                          {formatDollar(
                            item?.item?.weaponConvertPrice ||
                              item?.item?.weaponPrice
                          )}
                          )
                        </p>
                      )}
                    </>
                  )}
                </div>
              )}

              <div
                className={`grid ${
                  isShowMoreAction || item?.claimed
                    ? "grid-rows-[1fr] mt-space-1"
                    : "grid-rows-[0fr]"
                } transition-all duration-500`}
              >
                <div className="flex flex-col gap-space-1 w-full overflow-hidden">
                  <div
                    className={`${btnStyle} bg-[#2E2E2E4D] border-grey-800`}
                    onClick={() => handleClaim(item)}
                  >
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200">
                      {getClaimButtonTxt(item)}
                    </p>
                    <img
                      src="/assets/v4/dashboard-inventory-claim.png"
                      alt="claim"
                      className="w-[10px] h-[10px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!item?.claimed && (
            <div
              className="flex flex-row items-center justify-center gap-space-1 mt-space-1 cursor-pointer"
              onClick={() => setIsShowMoreAction(!isShowMoreAction)}
            >
              <p className="text-s sm:text-l leading-[14px] sm:leading-[24px] font-[500] blender-medium text-grey-100 z-[1]">
                {isShowMoreAction ? "Less" : "More"}
              </p>
              <img
                src="/assets/v4/dashboard-inventory-down.png"
                alt="more"
                className={`w-[11px] h-[10px] z-[1] ${
                  isShowMoreAction ? "transform rotate-[180deg]" : ""
                }`}
              />
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <ModalClaimCaseForm
        isModalOpen={isOpenModalClaimForm}
        setIsModalOpen={setIsOpenModalClaimForm}
        item={item}
        getMyBagCb={getMyBagCb}
      />
    </div>
  );
};
