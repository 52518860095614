import clsx from "clsx";
import { range, rangeValue } from "src/types/case-opening";

interface ICaseOpeningNumberRangeInput {
  className?: string;
  range: range;
  onChange: (range: range) => void;
}

const getRangeValue = (value: rangeValue): rangeValue => {
  return value === "" ? "" : Number(value);
};

const CaseOpeningNumberRangeInput = ({
  className,
  range,
  onChange,
}: ICaseOpeningNumberRangeInput) => {
  const [maxValue = "", minValue = ""] = range;

  return (
    <div
      className={clsx(
        className,
        "flex flex-row items-center text-grey-600 blender-medium text-m"
      )}
    >
      <span className="mr-space-2">Max price</span>
      <input
        className="w-[60px] border-[1px] border-solid border-black-100 bg-black-800 py-space-2 px-space-2 placeholder:text-black-100 rounded-xs text-white"
        type="number"
        value={maxValue}
        max={999999999}
        min={0}
        onChange={(e) => onChange([getRangeValue(e?.target?.value), minValue])}
      />
      <span className="mx-space-2">-</span>
      <span className="mr-space-2">Min price</span>
      <input
        className="w-[60px] border-[1px] border-solid border-black-100 bg-black-800 py-space-2 px-space-2 placeholder:text-black-100 rounded-xs text-white"
        type="number"
        value={minValue}
        max={999999999}
        min={0}
        onChange={(e) => onChange([maxValue, getRangeValue(e?.target?.value)])}
      />
    </div>
  );
};

export { CaseOpeningNumberRangeInput };
