import { useState } from "react";
import { history } from "src/stores";
import { AuthStore } from "src/stores/auth";
import { IconButton } from "./icon-button";
import { openWarningNotification } from "./notification";
import { RoundButton } from "./round-button";
import { useLocation } from "react-router";
import { useAuth } from "./auth-context";
import { formatDollar } from "src/utils";
import { Label } from "./label";
import { getCurrencyIcon } from "src/utils/image";
import clsx from "clsx";

interface INavV3 {
  withBg: boolean;
  isFixedNav: boolean;
}

export const NavV3 = (props: INavV3) => {
  const { isFixedNav = false } = props;
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const location = useLocation();
  const authStore = new AuthStore();
  const {
    loggedIn,
    setLoggedIn,
    setUserInfo,
    setOpenLoginModal,
    dashboardData,
  } = useAuth();

  const isActive = (name: string) => {
    return location?.pathname.indexOf(name) > -1;
  };

  const handleHoverButton = (name: string, isHovered: boolean) => {
    isHovered ? setHoveredButton(name) : setHoveredButton(null);
  };

  const handleLogout = () => {
    authStore.logout();
    setLoggedIn(false);
    setUserInfo(null);
  };

  const handleLogin = () => {
    setOpenLoginModal(true);
  };

  return (
    <div
      className={`desktop w-full h-[60px] flex flex-row z-[100] items-stretch justify-start 
      } ${isFixedNav ? "fixed left-0 top-0" : "relative"} bg-black-400/50`}
      style={{
        backdropFilter: "blur(4px)",
        backgroundImage: `url("/assets/nav/nav-bg-transparent.png")`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-row items-center justify-start flex-1 pl-space-2 gap-space-4 w-[60px] h-[100%]">
        <div
          className={clsx("relative h-[100%] flex items-center", {
            "nav-btn-hovered is-logo": hoveredButton === "Home",
          })}
        >
          <img
            src={
              hoveredButton === "Home"
                ? "/assets/v3/geda-orange.png"
                : "/assets/v3/geda-white.png"
            }
            alt="icon"
            className="w-[40px] h-[40px] cursor-pointer"
            onClick={() => history.push("/")}
            onMouseOver={() => handleHoverButton("Home", true)}
            onMouseOut={() => handleHoverButton("Home", false)}
          />
        </div>

        <div className="px-space-4 h-[100%] flex flex-row items-center gap-space-1 relative">
          <IconButton
            icon={`/assets/v4/${
              isActive("/mystery-box") || hoveredButton === "Case Open"
                ? `reward-active.png`
                : `reward.png`
            }`}
            name="Case Open"
            page={"/mystery-box"}
            isActive={isActive("/mystery-box")}
            isHovered={hoveredButton === "Case Open"}
            onHoverChange={(isHovered) =>
              handleHoverButton("Case Open", isHovered)
            }
          />
          <Label name="Hot" />
        </div>

        <div className="px-space-4 h-[100%]">
          <IconButton
            icon={`/assets/v4/${
              isActive("/prediction") || hoveredButton === "Watch & Predict"
                ? `watch-active.png`
                : `watch.svg`
            }`}
            name="Watch & Predict"
            page={"/prediction"}
            isActive={isActive("/prediction")}
            isHovered={hoveredButton === "Watch & Predict"}
            onHoverChange={(isHovered) =>
              handleHoverButton("Watch & Predict", isHovered)
            }
          />
        </div>

        <div className="px-space-4 h-[100%]">
          <IconButton
            icon={`/assets/v4/${
              isActive("/airdrop") || hoveredButton === "Earn"
                ? `earn-active.png`
                : `earn.svg`
            }`}
            name="Earn"
            page={"/airdrop"}
            isActive={isActive("/airdrop")}
            isHovered={hoveredButton === "Earn"}
            onHoverChange={(isHovered) => handleHoverButton("Earn", isHovered)}
          />
        </div>

        {/* <div className="px-space-4">
          <IconButton
            icon={`/assets/v4/${
              isActive("/marketplace") ? `mp-active.png` : `mp.svg`
            }`}
            name="Marketplace"
            page={"/marketplace/sell"}
            isActive={isActive("/marketplace")}
          />
        </div> */}

        <div className="px-space-4 h-[100%] flex flex-row items-center gap-space-1 relative">
          <IconButton
            name="Guild"
            iconProps={"FaGuilded"}
            isHovered={hoveredButton === "Guild"}
            onHoverChange={(isHovered) => handleHoverButton("Guild", isHovered)}
          />
          <Label name="Soon" type="coming-soon" />
        </div>

        <div className="px-space-4 h-[100%] flex flex-row items-center gap-space-1 relative">
          <IconButton
            name="Esports"
            iconProps={"MdSportsEsports"}
            isHovered={hoveredButton === "Esports"}
            onHoverChange={(isHovered) =>
              handleHoverButton("Esports", isHovered)
            }
          />
          <Label name="Soon" type="coming-soon" />
        </div>

        {/* <div className="px-space-4">
          <IconButton
            suffix="/assets/v4/external.svg"
            name="Guide"
            url={"https://guide.geda.gg/"}
          />
        </div> */}
      </div>
      {/* right side gradient */}
      <div
      // className="px-space-4 relative"
      // style={{
      //   backgroundImage: `url("/assets/nav/bg-v2.png")`,
      //   backgroundSize: "100% 400%",
      //   backgroundPosition: "right",
      // }}
      >
        <div className="relative z-[5] flex flex-row items-center justify-end gap-space-4 h-full ">
          {!loggedIn && (
            <>
              <RoundButton
                name="Connect"
                action={() => handleLogin()}
                icon="/assets/v4/nav_wallet.png"
              />
            </>
          )}

          {loggedIn && (
            <>
              <div className="flex items-center flex-row gap-space-1 mr-space-2">
                <img
                  src="/assets/v4/geda-star.png"
                  alt="geda-star"
                  className="w-[20px] h-[20px]"
                />
                <p>{formatDollar(dashboardData?.usdt || 0)}</p>
              </div>
              <div className="flex items-center flex-row gap-space-1 mr-space-2">
                <img
                  src={getCurrencyIcon("MAX")}
                  alt="max"
                  className="w-[20px] h-[20px]"
                />
                <p>{formatDollar(dashboardData?.max || 0)}</p>
              </div>
              <div className="flex items-center flex-row gap-space-1 mr-space-2">
                <img
                  src={getCurrencyIcon("GDD")}
                  alt="gdd"
                  className="w-[20px] h-[20px]"
                />
                <p>{formatDollar(dashboardData?.gdd)}</p>
              </div>
              <RoundButton
                name={"Referral"}
                action={() => openWarningNotification("Coming Soon!")}
              />
              <RoundButton
                name={"Dashboard"}
                action={() => history.push("/dashboard")}
                icon="/assets/v4/dashboard.png"
              />
              <RoundButton
                name={""}
                action={() => handleLogout()}
                icon="/assets/v4/logout.png"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
