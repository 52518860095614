import { useState, useEffect } from "react";
import clsx from "clsx";
import { CgSignal } from "react-icons/cg";
import { Marquee } from "src/components/marquee";
import { useWebSocket } from "src/components/websocket-context";
import { getRarityColor, formatPrice, formatTextToUpperCase } from "src/utils";
import { IAnnouncedWeapon } from "src/types/case-opening";
import { useMarketplaceStore } from "src/stores";

const Connection = ({ isConnected }: { isConnected: boolean }) => {
  return (
    <div
      className={clsx("flex items-center justify-center border-r-[2px]", {
        "text-[#1EBB71] border-r-[#1EBB71]": isConnected,
        "text-[#D40C48] border-r-[#D40C48]": !isConnected,
      })}
    >
      <CgSignal className="w-[16px] h-[16px]" />
    </div>
  );
};

const WeaponBulletin = ({
  username,
  itemName,
  itemQuality,
  weaponPrice,
  boxPrice,
  boxName,
}: IAnnouncedWeapon) => {
  return (
    <p className="blender-medium">
      <span className="mx-space-4">✹</span>
      <span className="text-grey-100 mx-space-1">
        {formatTextToUpperCase(username)}
      </span>
      <span className="text-grey-800 mx-space-1">WON</span>
      <span
        className="mx-space-1"
        style={{ color: getRarityColor(itemQuality) }}
      >
        {`${formatPrice(weaponPrice)} GEDA STAR ${formatTextToUpperCase(
          itemQuality
        )} ${formatTextToUpperCase(itemName)}`}
      </span>
      <span className="text-grey-100 mx-space-1">WITH {boxPrice}</span>
      <span className="text-grey-800 mx-space-1">
        ON {formatTextToUpperCase(boxName)} DRAW
      </span>
    </p>
  );
};

const AnnouncementBar = ({ className }: { className?: string }) => {
  const { announcedWeapon, isConnected } = useWebSocket();
  const { getMysteryBoxRecentWinnings } = useMarketplaceStore();
  const [bulletins, setBulletins] = useState<IAnnouncedWeapon[] | []>([]);
  const hasValue = Boolean(bulletins.length);
  const maxBulletins = 5;

  useEffect(() => {
    const fetchRecentWinnings = async () => {
      const result = await getMysteryBoxRecentWinnings();
      if (!result || typeof result === "string") return;
      const recentBulletins = result.map((item: any) => {
        const {
          userName,
          itemQuality,
          itemName,
          weaponPrice,
          boxPrice,
          boxName,
        } = item;
        return {
          username: userName,
          itemQuality,
          itemName,
          weaponPrice,
          boxPrice,
          boxName,
        };
      });
      setBulletins(recentBulletins);
    };

    fetchRecentWinnings();
  }, [getMysteryBoxRecentWinnings]);

  useEffect(() => {
    if (announcedWeapon) {
      setTimeout(() => {
        setBulletins((prevBulletins) => {
          let newBulletin: IAnnouncedWeapon[] = [...prevBulletins];
          if (newBulletin.length >= maxBulletins) {
            newBulletin.pop();
          }
          newBulletin.unshift(announcedWeapon);
          return newBulletin;
        });
      }, 1000);
    }
  }, [announcedWeapon]);

  return (
    <div
      className={clsx(
        className,
        "grid grid-flow-col grid-cols-[32px_1fr]",
        "bg-black-800 md:backdrop-blur-sm shadow-box-4"
      )}
    >
      <Connection isConnected={isConnected} />
      <Marquee
        className={clsx("transition-opacity duration-1000", {
          invisible: !hasValue,
        })}
        repeat={2}
        duration="100s"
        fromColor="from-black-800"
      >
        {hasValue &&
          bulletins.map((bulletin, i) => (
            <WeaponBulletin
              key={bulletin.username + bulletin.itemName + i}
              {...bulletin}
            />
          ))}
      </Marquee>
      <img
        className="absolute right-0 w-[60px] h-[36px] sm:w-[100px] self-center object-contain"
        src="/assets/v4/update-label.png"
        alt=""
      />
    </div>
  );
};

export { AnnouncementBar };
