import { useEffect, useState } from "react";
import { MdOpenInNew } from "react-icons/md";
import { history } from "src/stores";
import { useAuth } from "./auth-context";
import { LoadingIconV2 } from "./loading-icon-v2";
import { iBanner, iBannerButton } from "src/types/landing";
import { banners } from "src/config/landing";

interface iLandingBanner {}

export const LandingBanner = (props: iLandingBanner) => {
  const { loggedIn, setOpenLoginModal } = useAuth();

  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(0);
  const [windowScrollY, setWindowScrollY] = useState<number>(0);
  const [musicOn, setMusicOn] = useState<boolean>(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(0);

  const isFadeoutBecauseOfWindowScroll = () => {
    return windowScrollY / windowInnerHeight > 0.2;
  };

  const handleUnmute = (mute: boolean) => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.muted = mute; // Unmute the video
      videoElement.play();
    }
    setMusicOn(mute);
  };

  const handleWindowResize = () => {
    setWindowInnerHeight(window.innerHeight);
  };

  const handleWindowScroll = () => {
    setWindowScrollY(window.scrollY);
  };

  const handleLogin = () => {
    setOpenLoginModal(true);
  };

  useEffect(() => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.muted = true;
      videoElement.volume = 0.5; // Set volume level (0.0 to 1.0)
      videoElement.play();
    }
  }, [currentBannerIndex]); // Re-run when the banner index changes

  useEffect(() => {
    setWindowInnerHeight(window.innerHeight);

    window.addEventListener("scroll", handleWindowScroll);
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  return (
    <div className="relative h-[calc(80vh-88px)] sm:h-[calc(100vh-80px)] md:h-[calc(100vh-100px)] md:mt-[60px]">
      {/* change to md:top-[0px] md:h-[calc(100vh-62px)] to make the video banner goes to the navbar */}
      <div
        className={`fixed top-[220px] sm:top-[40px] md:top-[0px] left-0 w-full h-[calc(80vh-104px)] sm:h-[calc(100vh-104px)] md:h-[calc(100vh-62px)] overflow-hidden ${
          isFadeoutBecauseOfWindowScroll() ? "opacity-30" : "opacity-100"
        }`}
      >
        {banners &&
          banners.map((banner: iBanner, i: number) => {
            return (
              <div
                className="absolute top-0 left-0 w-full h-full z-[0] opacity-[0] transition-all duration-500"
                style={{
                  opacity: currentBannerIndex === i ? 1 : 0,
                  zIndex: currentBannerIndex === i ? 1 : 0,
                }}
                key={i}
              >
                <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                  <LoadingIconV2 />
                </div>

                {String(currentBannerIndex) === String(i) && banner?.video && (
                  <video
                    autoPlay
                    muted={!musicOn}
                    playsInline
                    loop
                    className={`relative w-full h-full ${
                      isFadeoutBecauseOfWindowScroll()
                        ? "sm:w-[110%] sm:h-[110%]"
                        : "sm:w-full sm:h-full"
                    } max-w-[110%] object-cover transition-all duration-500`}
                    onCanPlay={() => {
                      document?.querySelector("video")?.play(); // Programmatically trigger play
                    }}
                  >
                    <source src={banner?.video} type="video/mp4" />
                  </video>
                )}

                <div className="w-[70%] sm:w-[496px] absolute top-[50%] right-0 transform translate-y-[-50%] p-space-2 md:p-space-12">
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                      filter: "blur(40px)",
                    }}
                  />
                  <div className="relative flex flex-col items-end gap-space-2 sm:gap-space-4">
                    <p
                      className="text-xxml sm:text-[48px] text-right font-[400] leading-[28px] sm:leading-[45px] acpex"
                      dangerouslySetInnerHTML={{
                        __html: banner?.title != null ? banner?.title : "",
                      }}
                    ></p>
                    <p className="text-s sm:text-xxml text-right font-[500] leading-[14px] sm:leading-[28px] blender-medium mb-space-2">
                      {banner?.content}
                    </p>
                    <div className="hidden sm:block">
                      {banner?.buttons &&
                        banner?.buttons
                          .slice()
                          .reverse()
                          .map((button: iBannerButton, q: number) => {
                            return (
                              <button
                                className="float-right w-[172px] text-s sm:text-l leading-[16px] sm:leading-[19px] py-[6px] sm:py-[14px] blender-medium ml-space-2 mb-space-2 grey"
                                style={{
                                  ...({
                                    borderColor: button?.color,
                                  } as React.CSSProperties),
                                  ...({
                                    color: button?.color,
                                  } as React.CSSProperties),
                                }}
                                onClick={() => {
                                  if (button?.pushHistory) {
                                    history.push(button?.pushHistory);
                                  } else if (button?.openUrl) {
                                    window.open(button?.openUrl);
                                  }
                                }}
                                key={q}
                              >
                                <div className="flex flex-row items-center justify-center gap-space-1 sm:gap-space-2">
                                  <span>{button?.text}</span>
                                  {button?.openUrl && <MdOpenInNew />}
                                </div>
                              </button>
                            );
                          })}
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div
          className={`absolute bottom-0 left-0 w-full ${
            isFadeoutBecauseOfWindowScroll()
              ? "bottom-[-110px] opacity-0"
              : "bottom-0 opacity-100"
          } sm:opacity-100 z-[1] transition-all duration-500`}
        >
          <div className="relative w-full">
            {/* Mute */}
            <div
              className={`absolute bottom-0 ${
                isFadeoutBecauseOfWindowScroll()
                  ? "sm:bottom-[-58px] sm:opacity-0"
                  : "sm:bottom-0 sm:opacity-100"
              } right-0 sm:right-[-50px] w-[70px] sm:w-[218px] h-[36px] sm:h-[58px] z-[1] transition-all duration-500`}
            >
              <div className="absolute bottom-0 right-0 w-[70px] sm:w-[218px] h-full bg-black rounded-[0px] rounded-tl-m sm:rounded-tl-[0px] sm:transform sm:skew-x-[-50deg]"></div>
              <div
                className="absolute bottom-[4px] sm:bottom-[8px] right-[8px] sm:right-[100px] w-[51px] sm:w-[76px] cursor-pointer"
                onClick={() => {
                  handleUnmute(!musicOn);
                }}
              >
                <img
                  src={
                    musicOn
                      ? "/assets/v4/landing-banner-video-mute-off.png"
                      : "/assets/v4/landing-banner-video-mute-on.png"
                  }
                  alt="banner-video"
                  className="w-full"
                />
              </div>
            </div>

            {/* Video horizontal selector */}
            <div
              className={`sm:hidden absolute bottom-0 ${
                isFadeoutBecauseOfWindowScroll()
                  ? "sm:opacity-0"
                  : "sm:opacity-100"
              } left-0 h-[36px] bg-black rounded-[0px] rounded-tr-m pl-space-2 pr-space-3 z-[1] transition-all duration-500`}
            >
              <div className="h-[8px]">
                <div
                  className="absolute top-[2px] w-[4px] h-[4px] bg-orange-800 rounded-full transition-all duration-500"
                  style={{
                    left: currentBannerIndex * 32 + 42,
                  }}
                />
              </div>
              <div className="flex flex-row items-center gap-space-2 pb-space-1">
                <div
                  className="relative cursor-pointer w-[16px] h-[16px] bg-[#24242499] border-[1px] border-solid border-black-100 rounded-full rotate-[270deg]"
                  onClick={() => {
                    if (currentBannerIndex > 0) {
                      setCurrentBannerIndex(currentBannerIndex - 1);
                      handleUnmute(false);
                    }
                  }}
                >
                  <img
                    src={
                      currentBannerIndex > 0
                        ? "/assets/v4/landing-banner-selector-up-on.png"
                        : "/assets/v4/landing-banner-selector-up-off.png"
                    }
                    alt="banner-selector"
                    className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[14px] h-[14px]"
                  />
                </div>
                <div className="relative">
                  <div className="flex flex-row items-center gap-space-2">
                    {banners.map((item, i: number) => {
                      return (
                        <div
                          key={i}
                          className={`cursor-pointer w-[24px] h-[24px] border-[1px] border-solid ${
                            currentBannerIndex === i
                              ? "border-orange-800"
                              : "border-black-100"
                          } overflow-hidden rounded-full`}
                          onClick={() => {
                            if (currentBannerIndex !== i) {
                              setCurrentBannerIndex(i);
                              handleUnmute(false);
                            }
                          }}
                        >
                          {item?.icon && (
                            <img
                              src={item?.icon}
                              alt="icon"
                              className="w-full h-full object-cover"
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="relative cursor-pointer w-[16px] h-[16px] bg-[#24242499] border-[1px] border-solid border-black-100 rounded-full rotate-[90deg]"
                  onClick={() => {
                    if (currentBannerIndex < banners.length - 1) {
                      setCurrentBannerIndex(currentBannerIndex + 1);
                      handleUnmute(false);
                    }
                  }}
                >
                  <img
                    src={
                      currentBannerIndex < banners.length - 1
                        ? "/assets/v4/landing-banner-selector-up-on.png"
                        : "/assets/v4/landing-banner-selector-up-off.png"
                    }
                    alt="banner-selector"
                    className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[14px] h-[14px]"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile banner buttons */}
          {banners &&
            banners.map((banner: iBanner, i: number) => {
              if (i !== currentBannerIndex) {
                return null;
              }

              if (!banner?.buttons) {
                return null;
              }

              if (banner?.buttons.length === 0) {
                return null;
              }

              return (
                <div className="relative sm:hidden w-full bg-black px-space-2 pt-space-2 z-[1]">
                  <div className="flex items-start items-stretch flex-row gap-space-2 border-[1px] border-solid border-black-200 p-space-2 rounded-s">
                    {banner?.buttons &&
                      banner?.buttons
                        .slice()
                        .map((button: iBannerButton, q: number) => {
                          return (
                            <button
                              className="flex-1 text-s leading-[16px] py-[6px] blender-medium grey"
                              style={{
                                ...({
                                  borderColor: button?.color,
                                } as React.CSSProperties),
                                ...({
                                  color: button?.color,
                                } as React.CSSProperties),
                              }}
                              onClick={() => {
                                if (button?.pushHistory) {
                                  history.push(button?.pushHistory);
                                } else if (button?.openUrl) {
                                  window.open(button?.openUrl);
                                }
                              }}
                              key={q}
                            >
                              <div className="flex flex-row items-center justify-center gap-space-1">
                                <span>{button?.text}</span>
                                {button?.openUrl && <MdOpenInNew />}
                              </div>
                            </button>
                          );
                        })}

                    {!loggedIn && (
                      <button
                        className="flex-1 text-s leading-[16px] py-[6px] blender-medium btn-primary"
                        onClick={() => handleLogin()}
                      >
                        Connect & Earn
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* Video vertical selector */}
      <div
        className={`hidden sm:block fixed top-0 ${
          isFadeoutBecauseOfWindowScroll()
            ? "left-[-104px] opacity-0"
            : "left-0 opacity-100"
        } w-[80px] z-[1] h-screen bg-black transition-all duration-500`}
      ></div>
      <div
        className={`hidden sm:block absolute top-0 ${
          isFadeoutBecauseOfWindowScroll()
            ? "left-[-104px] opacity-0"
            : "left-0 opacity-100"
        } h-full z-[1] transition-all duration-500`}
      >
        <div className="absolute top-[50%] left-0 transform translate-y-[-50%] py-space-4">
          <div
            className="absolute top-0 left-[80px] w-[22px] h-full bg-black"
            style={{ borderRadius: "0px 96px 96px 0px" }}
          />
          <div className="flex flex-col items-center gap-space-4 w-[80px]">
            <div
              className="relative cursor-pointer w-[36px] h-[36px] bg-[#24242499] border-[1px] border-solid border-black-100 rounded-full"
              onClick={() => {
                if (currentBannerIndex > 0) {
                  setCurrentBannerIndex(currentBannerIndex - 1);
                  handleUnmute(false);
                }
              }}
            >
              <img
                src={
                  currentBannerIndex > 0
                    ? "/assets/v4/landing-banner-selector-up-on.png"
                    : "/assets/v4/landing-banner-selector-up-off.png"
                }
                alt="banner-selector"
                className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[20px] h-[20px]"
              />
            </div>
            <div className="relative w-full">
              <div className="flex flex-col items-center gap-space-4">
                {banners.map((item, i: number) => {
                  return (
                    <div
                      key={i}
                      className={`cursor-pointer w-[48px] h-[48px] border-[1px] border-solid ${
                        currentBannerIndex === i
                          ? "border-orange-800"
                          : "border-black-100"
                      } overflow-hidden rounded-full`}
                      onClick={() => {
                        if (currentBannerIndex !== i) {
                          setCurrentBannerIndex(i);
                          handleUnmute(false);
                        }
                      }}
                    >
                      {item?.icon && (
                        <img
                          src={item?.icon}
                          alt="icon"
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                className="absolute left-[82px] w-[8px] h-[8px] bg-orange-800 rounded-full transition-all duration-500"
                style={{
                  top: currentBannerIndex * 64 + 20,
                }}
              />
            </div>
            <div
              className="relative cursor-pointer w-[36px] h-[36px] bg-[#24242499] border-[1px] border-solid border-black-100 rounded-full rotate-[180deg]"
              onClick={() => {
                if (currentBannerIndex < banners.length - 1) {
                  setCurrentBannerIndex(currentBannerIndex + 1);
                  handleUnmute(false);
                }
              }}
            >
              <img
                src={
                  currentBannerIndex < banners.length - 1
                    ? "/assets/v4/landing-banner-selector-up-on.png"
                    : "/assets/v4/landing-banner-selector-up-off.png"
                }
                alt="banner-selector"
                className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[20px] h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
