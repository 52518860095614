import { useEffect, useState } from "react";
import { useAirdropStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import { useWindowSize } from "@uidotdev/usehooks";

import {
  getMissionBackgroundImage,
  getMissionPeriodTypeLabelBackgroundColor,
  getMissionPeriodTypeLabelColor,
  getMissionPeriodTypeLabelText,
} from "src/utils/mission";

interface iLandingMission {}

export const LandingMission = (props: iLandingMission) => {
  const [tasks, setTasks] = useState<[] | null>();

  const { getMissionList } = useAirdropStore();

  const size: any = useWindowSize();

  //copy from src/components/raffle-v3-mission-list.tsx, but some className is changed
  const renderPeriodTypeLabel = (
    mission_type: string,
    completed: boolean = false
  ) => {
    let bgColor = getMissionPeriodTypeLabelBackgroundColor(
      mission_type,
      completed
    );
    let color = getMissionPeriodTypeLabelColor(mission_type, completed);

    mission_type = getMissionPeriodTypeLabelText(mission_type, completed);

    return (
      <div
        className={`float-left px-space-1 py-[2px] mt-space-2 mr-space-2 
        border-[1px]
        z-[999] rounded-xs md:rounded-md`}
        style={{
          backgroundColor: bgColor,
          borderColor: bgColor,
        }}
      >
        <p
          className="small-content"
          style={{
            color: color,
          }}
        >
          {mission_type}
        </p>
      </div>
    );
  };

  useEffect(() => {
    //"" should be userId
    getMissionList("").then((result: any) => {
      if (!result) return;
      setTasks(result);
    });
  }, [getMissionList]);

  return (
    <div
      className="h-full flex items-start items-stretch flex-col mx-0 md:mx-space-2 mb-space-4 p-space-2 md:p-space-6 rounded-s"
      style={{
        background: "linear-gradient(360deg, #111111 0%, #262626 100%)",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
    >
      <p className="text-l md:text-xxml font-[500] leading-[19px] md:leading-[28px] blender-medium mb-space-2 md:mb-space-4">
        Task available
      </p>
      <div className="flex-[1]">
        {!tasks && (
          <div className="flex items-center justify-center">
            <LoadingIconV2 />
          </div>
        )}

        {tasks &&
          tasks.map((task: any, i: number) => {
            if (i > 2) {
              return null;
            }

            const rewards = task?.rewards_type.split("+");

            return (
              <div
                className="bg-[#19191999] border-[1px] border-solid border-orange-1200 mb-space-2 p-space-4 rounded-s"
                style={{
                  boxShadow: "0px 0px 10px 0px #FE52194D inset",
                }}
                key={i}
              >
                <div className="flex items-start flex-row">
                  <img
                    src={getMissionBackgroundImage(
                      task?.type,
                      task?.target,
                      size
                    )}
                    alt="icon"
                    className="mr-space-2 w-[60px] h-[60px] object-cover rounded-s"
                  />
                  <div className="flex flex-col flex-[1] min-h-[60px]">
                    <p className="text-l leading-[19px] mb-space-2">
                      {task?.name}
                    </p>
                    <div className="flex-[1]"></div>
                    <div>
                      {renderPeriodTypeLabel(
                        task?.mission_type,
                        task?.completed
                      )}

                      <div
                        className="float-left w-fit 
                        border-white z-[999] 
                        border-[1px] px-space-1 py-[2px]
                        rounded-xs mt-space-2 mr-space-2"
                      >
                        <p className="small-content">
                          {task?.rewards_type.includes("point")
                            ? "GEDA POINTS"
                            : task?.rewards_type.includes("ticket")
                            ? "TICKET"
                            : "MULTIPLIER"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    {rewards.map((item: string, index: number) => {
                      const [rewardType, reward] = item.split("_");
                      return (
                        <p
                          key={index}
                          className={`small-content text-right ${
                            index > 0 ? "mt-space-2" : ""
                          }`}
                        >
                          +{reward}
                          <br />
                          <span className="text-orange-600">
                            {rewardType === "multiplier" && "% "}
                            {rewardType === "point"
                              ? " GEDA POINTS"
                              : ` ${rewardType.toUpperCase()}`}
                          </span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <button
        className="w-full blender-medium text-l leading-[19px] py-[14px] btn-primary"
        onClick={() => {
          /*history.push("/airdrop#task")*/ window.location.replace(
            "/airdrop#task"
          );
        }}
      >
        Task available
      </button>
    </div>
  );
};
