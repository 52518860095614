interface iLoadingIconV2 {
  size?: string;
}

export const LoadingIconV2 = (props: iLoadingIconV2) => {
  const { size } = props;

  return (
    <img
      src={"/assets/v4/loading.gif"}
      alt="loading-gif"
      className="rounded-s"
      style={{
        ...(size &&
          size === "small" && {
            width: "12px",
            heigth: "12px",
          }),
        ...(!size && {
          width: "24px",
          heigth: "24px",
        }),
      }}
    />
  );
};
