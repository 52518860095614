const $btnStyle = `
  cursor-pointer
  w-full
  rounded-xs
  h-[24px]
  flex flex-row items-center
  justify-center
  text-s
  leading-[14px]
  blender-medium
  border-0
`;

export const AirdropMultiplierV2 = (props: any) => {
  const { openModal, setOpenNFTBoost } = props;

  return (
    <div>
      <p
        onClick={() => setOpenNFTBoost && setOpenNFTBoost()}
        className={`${$btnStyle} bg-v2primary text-white mb-space-2`}
      >
        NFT BOOST
      </p>
      <p
        onClick={() => openModal && openModal()}
        className={`${$btnStyle} bg-white text-black`}
      >
        REFERRAL
      </p>
    </div>
  );
};
