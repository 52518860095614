import { history } from "src/stores";

interface iLandingRaffles {}

export const LandingRaffles = (props: iLandingRaffles) => {
  return (
    <div
      className="relative min-h-[240px] mx-0 md:mx-space-2 mb-space-4 h-full cursor-pointer p-space-2 md:p-space-6 rounded-s overflow-hidden"
      style={{
        background:
          "linear-gradient(180deg, #2F2F36 0%, #29292D 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
      onClick={() => history.push("/mystery-box")}
    >
      <video
        autoPlay
        muted={true}
        playsInline
        loop
        className="absolute top-0 left-0 w-full h-full"
      >
        <source
          src={`https://media.geda.gg/Open_Case_Animation_weapon.mp4`}
          type="video/mp4"
        />
      </video>
      <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-center">
        <p className="text-[32px] leading-[30px] text-orange-800 acpex">???</p>
        <p className="text-[32px] leading-[30px] acpex">Blind Box</p>
        <p className="text-[32px] leading-[30px] text-orange-800 acpex">???</p>
      </div>
      <div className="relative">
        <p className="text-l md:text-xxml font-[500] leading-[19px] md:leading-[28px] blender-medium mb-space-2 md:mb-space-4">
          Open box
        </p>
      </div>
    </div>
  );
};
