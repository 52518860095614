import { useRef, useState } from "react";
import { useSpring, animated } from "react-spring/web";
import { LandingPartnersConveyerPartner } from "./landing-partners-conveyer-partner";
import { iPartner } from "src/types/landing";

interface iLandingPartnersVerticalConveyer {
  partners: iPartner[];
  isReversedScroll: boolean;
}

export const LandingPartnersVerticalConveyer = (props: iLandingPartnersVerticalConveyer) => {
  const { partners, isReversedScroll = false } = props;

  const mainRef = useRef<HTMLDivElement | null>(null);

  const [animationOn, setAnimationOn] = useState<boolean>(false);

  let mainScrollHeight = 0; //should not access direcly
  const getMainScrollHeight = () => {
    mainScrollHeight = ((mainRef && mainRef.current) ? mainRef.current.scrollHeight : 0);
    return mainScrollHeight;
  };

  const isChangedMainScrollHeight = () => {
    return (mainScrollHeight !== ((mainRef && mainRef.current) ? mainRef.current.scrollHeight : 0));
  };

  const scrollSpring = useSpring({
    to: {
      y: (!isReversedScroll ? (getMainScrollHeight() / 2) : 1)
    },
    from: {
      y: (!isReversedScroll ? 1 : (getMainScrollHeight() / 2))
    },
    onFrame: (props: any) => {
      if((mainRef && mainRef.current)) {
        if(!isReversedScroll) {
          if(props.y <= 1 ||
            isChangedMainScrollHeight() ||
            props.y >= (getMainScrollHeight() / 2)) {
            setAnimationOn(true);

            setTimeout(() => {
              setAnimationOn(false);
            }, 10);
          }
        }
        else {
          if(isChangedMainScrollHeight() ||
            props.y <= 1) {
            setAnimationOn(true);

            setTimeout(() => {
              setAnimationOn(false);
            }, 10);
          }
        }

        mainRef.current.scrollTop = props.y;
      }
      else {
        setAnimationOn(true);

        setTimeout(() => {
          setAnimationOn(false);
        }, 10);
      }
    },
    config: {
      duration: 3000 * (partners ? partners.length : 0)
    },
    reset: animationOn
  });

  return (
    <animated.div
      className="h-[367px] md:h-[467px] overflow-hidden"
      style={{
        ...scrollSpring
      }}
      ref={mainRef}
    >
      <div className="flex flex-col gap-space-4">
        {partners && (
          partners.map((partner: iPartner, q: number) => {
            return (
              <LandingPartnersConveyerPartner
                partner={partner}
                key={q}
              />
            );
          })
        )}

        {partners && (
          partners.map((partner: iPartner, q: number) => {
            return (
              <LandingPartnersConveyerPartner
                partner={partner}
                key={q}
              />
            );
          })
        )}
      </div>
    </animated.div>
  );
};
