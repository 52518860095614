export const PREDICTION_LIST = [
  {
    name: "Matr1x Fire",
    desc: "Asia Master 2024",
    image: "/assets/games/GEDA_MF_logo_hires_asiamaster.png",
    isSelectable: false,
    isEnded: false,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024",
    logo: "/assets/v2/GEDA_MF_logo_hires_asiamaster.png",
    avatar: "/assets/v2/GEDA_MF_logo_hires_asiamaster.png",
  },
  {
    name: "League of Legends",
    desc: "LCK Spring 2024",
    image: "/assets/v2/LCK.webp",
    isSelectable: true,
    isEnded: false,
    type: ["web2"],
    gameId: "league-lck-spring-2024",
    logo: "/assets/v2/LCK.webp",
    avatar: "/assets/v2/LCK.webp",
  },
  {
    name: "Matr1x Fire",
    desc: "Community Invitational HK",
    image: "/assets/v2/GEDA_MF_logo_hires_community.png",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire",
    logo: "/assets/v2/GEDA_MF_logo_hires_community.png",
    avatar: "/assets/v2/GEDA_MF_logo_hires_community.png",
  },
];

export const PREDICTION_LIST_V3 = [
  {
    name: "eSports World Cup",
    desc: "Counter Strike 2",
    image: "/assets/games/esportscup.jpeg",
    isSelectable: true,
    isEnded: false,
    type: ["web2"],
    gameId: "cs2-esports-2024",
    logo: "/assets/games/esportscup.jpeg",
    avatar: "/assets/games/esportscup.jpeg",
    youtubeLive: "",
    bilibili: "",
    isLive: true,
  },
  {
    name: "eSports World Cup",
    desc: "League of Legends",
    image: "/assets/games/esportscup.jpeg",
    isSelectable: true,
    isEnded: true,
    type: ["web2"],
    gameId: "league-esports-2024",
    logo: "/assets/games/esportscup.jpeg",
    avatar: "/assets/games/esportscup.jpeg",
    youtubeLive: "",
    bilibili: "",
    isLive: true,
  },
  {
    name: "Matr1x X Ancient8",
    desc: "Master Duel",
    image: "/assets/games/GN17.png",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matrix-master-duel",
    logo: "/assets/games/GN17.png",
    avatar: "/assets/games/GN17.png",
    youtubeLive: "https://youtube.com/embed/4YyzJzVAbkk",
    bilibili: "https://youtube.com/embed/4YyzJzVAbkk",
    isLive: false,
  },
  {
    name: "Matr1x X GeDa World Tour",
    desc: "Vietnam",
    image: "/assets/games/vietnam.png",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matrix-world-tour-vietnam",
    logo: "/assets/games/vietnam.png",
    avatar: "/assets/games/vietnam.png",
    youtubeLive: "https://youtube.com/embed/oh2JuX5odAg",
    bilibili: "https://youtube.com/embed/oh2JuX5odAg",
    isLive: false,
  },
  {
    name: "MATR1X-KUKU 宝箱开箱",
    desc: "“欧”庄被美女包围啦！",
    image: "/assets/games/matrix-box-event.jpeg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matrix-fire-kuku-open",
    logo: "/assets/games/matrix-box-event.jpeg",
    avatar: "/assets/games/matrix-box-event.jpeg",
    youtubeLive: "https://youtube.com/embed/MGPWeaCYn34",
    bilibili: "https://youtube.com/embed/MGPWeaCYn34",
    isLive: false,
  },
  {
    name: "League of Legends",
    desc: "MSI 2024",
    image: "/assets/games/msi2024.jpeg",
    isSelectable: true,
    isEnded: true,
    type: ["web2"],
    gameId: "league-msi-2024",
    logo: "/assets/games/msi2024.jpeg",
    avatar: "/assets/games/msi2024.jpeg",
    youtubeLive: "https://www.youtube.com/embed/RZPpvI549I0",
    bilibili: "https://www.youtube.com/embed/RZPpvI549I0",
    isLive: false,
  },
  {
    name: "Matr1x Fire x Geda ESports",
    desc: "SEABW Showmatch",
    image: "/assets/games/MATR1X-FIRE-X-GeDa-SEA-BW-ShowmatchKV_Sponsors.png",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-seabw-showcase",
    logo: "/assets/games/MATR1X-FIRE-X-GeDa-SEA-BW-ShowmatchKV_Sponsors.png",
    avatar: "/assets/games/MATR1X-FIRE-X-GeDa-SEA-BW-ShowmatchKV_Sponsors.png",
    isLive: false,
  },
  {
    name: "League of Legends",
    desc: "LCK Spring 2024 Spring Playoffs",
    image: "/assets/v2/LCK.webp",
    isSelectable: true,
    isEnded: true,
    type: ["web2"],
    gameId: "league-lck-spring-2024",
    logo: "/assets/v2/LCK.webp",
    avatar: "/assets/v2/LCK.webp",
    youtubeLive: "https://www.youtube.com/embed/Mf48cMr9-mU",
    bilibili: "https://www.youtube.com/embed/Mf48cMr9-mU",
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Day 2",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-01.jpg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-finals",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-01.jpg",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-01.jpg",
    youtubeLive: "https://www.youtube.com/embed/g8REftowdTQ",
    bilibili: "https://www.youtube.com/embed/g8REftowdTQ",
    twitter:
      '<blockquote class="twitter-tweet" data-media-max-width="2000"><p lang="en" dir="ltr">test4 <a href="https://t.co/d8UussTpXx">https://t.co/d8UussTpXx</a></p>&mdash; Gloria | 🚀MATR1XCoin (@GloriaXY4) <a href="https://twitter.com/GloriaXY4/status/1771217661839700163?ref_src=twsrc%5Etfw">March 22, 2024</a></blockquote>',
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Day 1",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-01.jpg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-semi-finals",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-01.jpg",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-01.jpg",
    youtubeLive: "https://www.youtube.com/embed/4z_CeCF7rh4",
    bilibili: "https://www.youtube.com/embed/4z_CeCF7rh4",
    twitter:
      '<blockquote class="twitter-tweet" data-media-max-width="2000"><p lang="en" dir="ltr">test4 <a href="https://t.co/d8UussTpXx">https://t.co/d8UussTpXx</a></p>&mdash; Gloria | 🚀MATR1XCoin (@GloriaXY4) <a href="https://twitter.com/GloriaXY4/status/1771217661839700163?ref_src=twsrc%5Etfw">March 22, 2024</a></blockquote>',
    isLive: false,
  },
  {
    name: "Arena of Faith",
    desc: "Exhibition Match",
    image: "/assets/games/aof_exhibition.jpeg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "aof-exhibition",
    logo: "/assets/games/aof_exhibition.jpeg",
    avatar: "/assets/games/aof_exhibition.jpeg",
    youtubeLive: "https://www.youtube.com/embed/mpcSLIsgyAU",
    bilibili: "https://www.youtube.com/embed/mpcSLIsgyAU",
    twitter:
      '<blockquote class="twitter-tweet" data-media-max-width="2000"><p lang="en" dir="ltr">test4 <a href="https://t.co/d8UussTpXx">https://t.co/d8UussTpXx</a></p>&mdash; Gloria | 🚀MATR1XCoin (@GloriaXY4) <a href="https://twitter.com/GloriaXY4/status/1771217661839700163?ref_src=twsrc%5Etfw">March 22, 2024</a></blockquote>',
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Wildcard Qualifier",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-02.png",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-wildcard-qualifier",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-02.png",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-02.png",
    youtubeLive: "https://www.youtube.com/embed/mpcSLIsgyAU",
    bilibili: "https://www.youtube.com/embed/mpcSLIsgyAU",
    twitter:
      '<blockquote class="twitter-tweet" data-media-max-width="2000"><p lang="en" dir="ltr">test4 <a href="https://t.co/d8UussTpXx">https://t.co/d8UussTpXx</a></p>&mdash; Gloria | 🚀MATR1XCoin (@GloriaXY4) <a href="https://twitter.com/GloriaXY4/status/1771217661839700163?ref_src=twsrc%5Etfw">March 22, 2024</a></blockquote>',
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Hong Kong, China & Macau, China & Chinese TaiPei",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-05.jpg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-asia",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-05.jpg",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-05.jpg",
    youtubeLive: "https://www.youtube.com/embed/hXlTbiasd4I",
    bilibili:
      "https://www.bilibili.com/blackboard/live/live-activity-player.html?cid=24020017&quality=0",
    twitter:
      '<blockquote class="twitter-tweet" data-media-max-width="2000"><p lang="en" dir="ltr">test4 <a href="https://t.co/d8UussTpXx">https://t.co/d8UussTpXx</a></p>&mdash; Gloria | 🚀MATR1XCoin (@GloriaXY4) <a href="https://twitter.com/GloriaXY4/status/1771217661839700163?ref_src=twsrc%5Etfw">March 22, 2024</a></blockquote>',
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Southeast Asia",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-04.jpg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-sea",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-04.jpg",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-04.jpg",
    youtubeLive: "https://www.youtube.com/embed/R_aJPb8Xyx8",
    bilibili:
      "https://www.bilibili.com/blackboard/live/live-activity-player.html?cid=24020017&quality=0",
    twitter:
      '<blockquote class="twitter-tweet" data-media-max-width="2000"><p lang="en" dir="ltr">test4 <a href="https://t.co/d8UussTpXx">https://t.co/d8UussTpXx</a></p>&mdash; Gloria | 🚀MATR1XCoin (@GloriaXY4) <a href="https://twitter.com/GloriaXY4/status/1771217661839700163?ref_src=twsrc%5Etfw">March 22, 2024</a></blockquote>',
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Greater China",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-02.jpg",
    isSelectable: false,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-gc",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-02.jpg",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-02.jpg",
    youtubeLive: "https://www.youtube.com/embed/w1X8y862R3E",
    isLive: false,
  },
  {
    name: "Matr1x Fire Asia Masters 2024",
    desc: "Japan & Korea",
    image: "/assets/games/GEDA_MFM_banner_480x270_v5-03.jpg",
    isSelectable: false,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire-asia-master-2024-jpkr",
    logo: "/assets/games/GEDA_MFM_banner_480x270_v5-03.jpg",
    avatar: "/assets/games/GEDA_MFM_banner_480x270_v5-03.jpg",
    youtubeLive: "https://www.youtube.com/embed/w1X8y862R3E",
    isLive: false,
  },
  {
    name: "Matr1x Fire",
    desc: "Community Invitational HK",
    image: "/assets/v2/GEDA_MF_logo_hires_community.png",
    isSelectable: false,
    isEnded: true,
    type: ["web3"],
    gameId: "matr1x-fire",
    logo: "/assets/v2/GEDA_MF_logo_hires_community.png",
    avatar: "/assets/v2/GEDA_MF_logo_hires_community.png",
    youtubeLive: "https://www.youtube.com/embed/w1X8y862R3E",
    isLive: false,
  },
];
