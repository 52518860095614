import { FC, useContext } from "react";
import clsx from "clsx";
import { useSpring, animated, config } from "@react-spring/web";
import { InboxContext } from "./inbox-provider";
import { IoCloseOutline } from "react-icons/io5";
import { history } from "src/stores";

interface IInboxModalProps {
  className?: String;
}

const InboxModal: FC<IInboxModalProps> = ({ className }) => {
  const { modal, setModal, setIsInboxOpen } = useContext(InboxContext);
  const isModalOpen = Boolean(modal);

  const { subject, content, imageUrl, sitePath = "" } = modal || {};

  const [springs] = useSpring(
    () => ({
      from: {
        opacity: isModalOpen ? 0.8 : 1,
        transform: `scale(${isModalOpen ? 0.98 : 1})`,
      },
      to: {
        opacity: isModalOpen ? 1 : 0.8,
        transform: `scale(${isModalOpen ? 1 : 0.98})`,
      },
      config: { ...config.stiff, clamp: true },
    }),
    [isModalOpen]
  );

  const handleClose = () => setModal(null);

  const handleInteract = () => {
    // go to somewhere
    if (sitePath) {
      history.push(sitePath);
      handleClose();
      setIsInboxOpen(false);
      return;
    }
  };

  return (
    <div
      className={clsx(
        { hidden: !isModalOpen },
        "fixed top-0 left-0 w-full h-full flex justify-center items-center z-[5000]"
      )}
    >
      <div
        className="absolute w-full h-full bg-black/80"
        onClick={handleClose}
      />
      <animated.div
        className="relative flex items-center justify-center"
        style={springs}
      >
        <div
          className={clsx(
            className,
            "relative md:w-[640px] w-[272px] md:h-[590px] h-[480px] grid grid-rows-[86px_minmax(0,_1fr)_105px] md:grid-rows-[104px_minmax(0,_1fr)_105px]",
            "md:bg-[url('/assets/v4/case-opening-frame-bg-black.png')] bg-[url('/assets/v4/modal-mobile-bg.png')] bg-cover"
          )}
        >
          {/* header */}
          <div className="mt-space-4 md:mt-space-5 md:px-space-9 px-space-4 md:py-space-2 py-space-2 flex gap-space-1 items-center justify-between border-b-2 border-black-400 blender-medium">
            <div className="flex items-center w-full h-full overflow-hidden">
              <h1 className="text-xl md:text-xxl text-grey-100 leading-none m-0">
                {subject}
              </h1>
            </div>
            <IoCloseOutline
              className="w-[28px] h-[28px] md:w-[40px] md:h-[40px] cursor-pointer bg-neutral-500/20 text-orange-800 rounded-full"
              onClick={handleClose}
            />
          </div>

          {/* content */}
          <div className="overflow-y-auto overflow-x-clip px-[40px] py-[16px] scrollbar-hide overscroll-contain">
            <div className="h-[80px] md:h-[180px] overflow-hidden grid rounded-xs mb-space-2">
              <img
                className="h-[80px] md:h-[180px] w-full place-self-center object-cover"
                src={imageUrl}
                alt=""
              />
            </div>
            {content && (
              <div
                className="text-s text-grey-400 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>

          {/* footer */}
          <div
            className={clsx(
              "flex pt-[24px] pb-[48px] md:pt-[16px] md:pb-[28px] px-[48px] md:px-[120px] border-t-2 border-black-400 blender-medium"
            )}
          >
            <button
              className="flex-grow h-[28px] md:h-[48px] mb-[4px] text-grey-100 text-s leading-none rounded-xs border-orange-800 bg-orange-800/30"
              onClick={handleInteract}
            >
              O K
            </button>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export { InboxModal };
