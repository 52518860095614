import { history } from "src/stores";

const GoBack = () => {
  return (
    <div
      className="flex flex-row items-center gap-space-2 cursor-pointer"
      onClick={() => history.goBack()}
    >
      <img src="/assets/v4/back.png" alt="back" className="w-[7px] h-[12px]" />
      <p className="text-m text-grey-600">Back</p>
    </div>
  );
};

export { GoBack };
