import React from "react";

interface IModalDepositCard {
  amount: number;
  setAmount: (val: number) => void;
  amounts: number[];
}

export const ModalDepositCard: React.FC<IModalDepositCard> = (props) => {
  const { amount, setAmount, amounts } = props;

  return (
    <div className="flex flex-col gap-space-4 rounded-s px-space-4 py-space-2 bg-black overflow-hidden overflow-y-auto scrollbar-hide scrollbar-hidden">
      <div>
        <h2 className="text-s md:text-l blender-medium text-grey-100 normal-case">
          Enter amount to deposit
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-space-1">
          {amounts.map((val, index) => (
            <button
              key={index}
              onClick={() => setAmount(val)}
              className={`flex h-[36px] px-[10px] py-space-3 rounded-xs items-center justify-center
            bg-grey-200/10 font-[500] text-s border-[1px] border-solid
            ${
              amount !== val
                ? "border-black-100 text-black-100"
                : "border-orange-800 text-orange-800"
            }`}
            >
              ${val}.00
            </button>
          ))}
        </div>
        <div className="relative flex items-center py-space-4">
          <input
            type="text"
            placeholder="Custom amount"
            value={amount}
            onChange={(e) => setAmount(Number(e?.target?.value))}
            className="w-full h-[40px] text-left placeholder-left pl-space-4 border-black-200 rounded-xs border-solid border-[1px] placeholder-black-200 text-orange-600 placeholder-blender-medium placeholder-font-[700] placeholder-text-l font-[400] text-l blender-medium bg-transparent placeholder-center placeholder-text-grey-600 focus:border-orange-800 focus:outline-none"
          />
          <span className="absolute right-4 text-white blender-medium font-[700] text-s md:text-l">
            $ {amount} USD
          </span>
        </div>
        <div className="relative flex gap-xs items-center">
          <p className="text-grey-100 font-[700] blender-medium text-s md:text-l">
            Receiving Amount
          </p>
          <span className="flex items-center bg-orange-800 rounded-xs px-space-2 py-space-1 text-white blender-medium font-[500] h-space-5 mt-space-0 text-xs md:text-s">
            +10% Bonus
          </span>
          <img
            src="assets/v4/info.png"
            alt="information symbal"
            className="w-[16px] h-[16px] cursor-pointer mt-space-1 ml-auto"
          />
        </div>
        <div className="relative flex items-center py-space-4">
          <div className="w-full h-[40px] px-space-4 border-black-200 rounded-xs border-solid border bg-transparent flex-row flex items-center gap-space-2 justify-end">
            <p className="text-white blender-medium font-[700] text-s md:text-l">
              $ {amount} USD
            </p>
            <p className="blender-medium text-orange-600">{`>`}</p>
            <p className="text-orange-600">$ {Math.round(1.1 * amount)} USD</p>
          </div>
        </div>
        <p className="blender-medium font-[500] text-native-red text-s">
          *Disclaimer*
        </p>
        <p className="blender-medium font-[400] text-s text-grey-600">
          Top ups are localized to be utilized only on geda.gg. NFT’s earned
          through Marketplace/Spin can be transferred to external wallets. Top
          up’s CAN NOT be withdrawn as cash value.
        </p>
      </div>
    </div>
  );
};
