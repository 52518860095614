import { useCallback, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { history, useAirdropStore, useTwitterStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import { openWarningNotification } from "./notification";
import { ModalConfirmTweet } from "./modal-confirm-tweet";

import {
  getMissionBackgroundImage,
  getMissionPeriodTypeLabelBackgroundColor,
  getMissionPeriodTypeLabelColor,
  getMissionPeriodTypeLabelText,
} from "src/utils/mission";
import { useAuth } from "./auth-context";

interface iRaffleV4MissionList {
  userId: string;
  handleTwitterLogin: any;
  getAirdropProfileCb: any;
  airdropProfile: any;
  profile: any;
  setUnlockReferralModal: any;
  isExpandMoreComponent: boolean;
  expandMoreComponent: () => void;
  expandLessComponent: () => void;
}

export const RaffleV4MissionList = (props: iRaffleV4MissionList) => {
  const {
    userId,
    handleTwitterLogin,
    getAirdropProfileCb,
    airdropProfile,
    profile,
    setUnlockReferralModal,
    isExpandMoreComponent = false,
    expandMoreComponent,
    expandLessComponent,
  } = props;

  const [tasks, setTasks] = useState<[] | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [tweetModal, setTweetModal] = useState<boolean>(false);
  const [twitterJwt, setTwitterJwt] = useState<string>("");
  const [twitterType, setTwitterType] = useState<string>("");
  const [twitterName, setTwitterName] = useState<string>("");
  const [selectedTask, setSelectedTask] = useState(null);

  const { loggedIn, setOpenLoginModal } = useAuth();
  const { getMissionList, doDailyLogin, doTask } = useAirdropStore();
  const { getTwitterToken, doTwitterTask } = useTwitterStore();

  const size: any = useWindowSize();

  const getMissionListCb = useCallback(
    (userId) => {
      setLoading(true);
      getMissionList(userId).then((result) => {
        if (!result) return;
        setTasks(result);
        setLoading(false);
      });
    },
    [getMissionList]
  );

  const handleTwitterErr = useCallback(async () => {
    openWarningNotification("You need to re-connect the twitter again");
    // jwt = await getTwitterToken();
    await handleTwitterLogin(true);
    return;
  }, [handleTwitterLogin]);

  const handleTwitterTaskCb = useCallback(
    async (type: string, name: string, jwt: string, task: any) => {
      try {
        const result = await doTwitterTask(jwt, type, name, task);
        if (result === "unknown_error") {
          return handleTwitterErr();
        }
        getMissionListCb(userId);
        getAirdropProfileCb();
      } catch (e) {
        console.log("e");
        return;
      }
    },
    [
      doTwitterTask,
      getMissionListCb,
      userId,
      getAirdropProfileCb,
      handleTwitterErr,
    ]
  );

  const handleTasks = async (
    type: string,
    name: string,
    completed = false,
    task: any
  ) => {
    if (!loggedIn) {
      setOpenLoginModal(true);
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (!profile?.twitter_connected) {
      openWarningNotification("Please authorize Twitter Login");
      return;
    }

    switch (type) {
      case "z_predict_games":
        history.push("/prediction");
        break;
      case "like_tweet":
      case "follow_twitter":
      case "rt_tweet":
        let jwt = null;

        try {
          if (!loggedIn) {
            setOpenLoginModal(true);
            openWarningNotification("Please connect to Wallet");
            return;
          }

          if (!profile?.twitter_connected) {
            openWarningNotification("Please authorize Twiiter Login");
            return;
          }

          if (!profile?.twitter_jwt) {
            jwt = await getTwitterToken();
            if (!jwt) return handleTwitterErr();
          }
          if (profile?.twitter_jwt) jwt = profile?.twitter_jwt;

          if (type === "like_tweet" || type === "rt_tweet") {
            setTwitterJwt(jwt);
            setTwitterType(type);
            setTwitterName(name);
            setSelectedTask(task);
            setTweetModal(true);
          }

          if (type === "follow_twitter") {
            handleTwitterTaskCb(type, name, jwt, task);
          }
        } catch (e) {
          handleTwitterErr();
          return;
        }
        break;
      case "daily_login":
        if (completed) {
          openWarningNotification(
            "You have done this task - " + name.toUpperCase()
          );
          return;
        }

        await doDailyLogin();
        getAirdropProfileCb();
        getMissionListCb(userId);
        break;
      case "unlock_referral":
        if (airdropProfile?.referralUnlock)
          return openWarningNotification("You have already unlocked referral!");
        setUnlockReferralModal(true);
        break;
      case "nyan_download":
      case "nyan_mission":
      case "mixmob_download":
      case "mixmob_download_android":
      case "mixmob_download_ios":
      case "visit_pome_village":
      case "pome_tg":
      case "pome_discord":
      case "pome_website":
      case "ss_website":
      case "ss_discord":
      case "cellula_discord":
        if (type === "nyan_download") {
          window.open(
            "https://store.epicgames.com/en-US/p/nyan-heroes-nyan-heroes-sneak-peek-67319a",
            "_blank"
          );
        }
        if (type === "nyan_mission") {
          window.open(
            "https://missions.nyanheroes.com/?_gl=1*wnbz2v*_ga*MTYyODQ1Njg5OS4xNzE1MzIzNTc3*_ga_D4F5M7BVKZ*MTcxNTMyMzU3Ni4xLjAuMTcxNTMyMzU3Ni42MC4wLjA.",
            "_blank"
          );
        }
        if (type === "mixmob_download_android") {
          window.open(
            "https://play.google.com/store/apps/details?id=com.mixmob.racer&referrer=utm_source%3DGeda%26utm_campaign%3DGeda",
            "_blank"
          );
        }
        if (type === "mixmob_download_ios") {
          window.open(
            "https://apps.apple.com/app/apple-store/id6468266026?pt=126660627&ct=Geda&mt=8",
            "_blank"
          );
        }
        if (type === "visit_pome_village") {
          window.open("https://opensea.io/collection/pome-village", "_blank");
        }
        if (type === "pome_tg") {
          window.open("https://t.me/pomerium_official", "_blank");
        }
        if (type === "pome_discord") {
          window.open("https://discord.gg/pomeriumofficial", "_blank");
        }
        if (type === "pome_website") {
          window.open("https://pomerium.space/", "_blank");
        }
        if (type === "ss_discord") {
          window.open("https://discord.gg/starsymphony", "_blank");
        }
        if (type === "ss_website") {
          window.open("https://game.starsymphony.io", "_blank");
        }
        if (type === "cellula_discord") {
          window.open("https://discord.com/invite/cellula-website", "_blank");
        }
        await doTask(task?._id);
        getAirdropProfileCb();
        getMissionListCb(userId);
        break;
      default:
        break;
    }
  };

  const getMissionBgColor = (task: any) => {
    if (task?.completed) {
      return "#00000000";
    }

    switch (task?.mission_type) {
      case "once":
        return "#1D1D1D";

      case "cycle":
        return "#FE791954";

      case "daily":
        return "#FFFFFF54";
    }
  };

  const getMissionBorderColor = (task: any) => {
    if (task?.completed) {
      return "#1EBB71";
    }

    return "#424242";
  };

  const renderPeriodTypeLabel = (
    mission_type: string,
    completed: boolean = false
  ) => {
    let bgColor = getMissionPeriodTypeLabelBackgroundColor(
      mission_type,
      completed
    );
    let color = getMissionPeriodTypeLabelColor(mission_type, completed);

    mission_type = getMissionPeriodTypeLabelText(mission_type, completed);

    return (
      <div
        className={`float-left px-space-1 py-[2px] mt-space-2 mr-space-2 
        border-[1px]
        z-[999] rounded-xs md:rounded-md`}
        style={{
          backgroundColor: bgColor,
          borderColor: bgColor,
        }}
      >
        <p
          className="small-content"
          style={{
            color: color,
          }}
        >
          {mission_type}
        </p>
      </div>
    );
  };

  useEffect(() => {
    getMissionListCb(userId);
  }, [userId, getMissionListCb]);

  return (
    <div className="h-full py-space-4">
      <div className="flex items-start flex-row blender-medium mb-space-4 whitespace-nowrap">
        <div
          className="text-xxl sm:text-[32px] md:text-[28px] l:text-[32px] text-grey-600 leading-[32px]"
          style={{
            fontWeight: 900,
          }}
        >
          TASK
        </div>
        <div className="flex-[1]"></div>
        <div
          className="text-l leading-[32px] text-orange-600 cursor-pointer"
          onClick={() => {
            if (isExpandMoreComponent) {
              expandLessComponent();
            } else {
              expandMoreComponent();
            }
          }}
        >
          {isExpandMoreComponent ? "< Show less" : "View more >"}
        </div>
      </div>
      <div>
        {loading && (
          <div className="flex items-center justify-center">
            <LoadingIconV2 />
          </div>
        )}

        <div
          className={`grid grid-cols-1 sm:grid-cols-2 ${
            !isExpandMoreComponent
              ? "md:grid-cols-2 l:grid-cols-2"
              : "md:grid-cols-3 l:grid-cols-4"
          } gap-x-space-4 gap-y-space-2`}
        >
          {tasks &&
            tasks.map((task: any, i: number) => {
              if (!isExpandMoreComponent) {
                if (size?.width < 760) {
                  if (i > 4) {
                    return null;
                  }
                } else {
                  if (i > 11) {
                    return null;
                  }
                }
              }

              const rewards = task?.rewards_type.split("+");

              return (
                <div
                  key={i}
                  className="relative border-[1px] border-solid px-space-4 py-space-4 rounded-s overflow-hidden cursor-pointer"
                  style={{
                    backgroundColor: getMissionBgColor(task),
                    borderColor: getMissionBorderColor(task),
                  }}
                  onClick={() =>
                    handleTasks(task?.type, task?.name, task?.completed, task)
                  }
                >
                  <div className="flex items-start items-stretch flex-row">
                    <img
                      src={getMissionBackgroundImage(
                        task?.type,
                        task?.target,
                        size
                      )}
                      alt="icon"
                      className="w-[75px] h-[75px] object-cover rounded-xs mr-space-2 "
                    />
                    <div className="flex flex-col flex-[1]">
                      <p className="text-l leading-[19px] mb-space-2">
                        {task?.name}
                      </p>
                      <div className="flex-[1]"></div>
                      <div>
                        {renderPeriodTypeLabel(
                          task?.mission_type,
                          task?.completed
                        )}

                        <div
                          className="float-left w-fit 
                          border-white z-[999] 
                          border-[1px] px-space-1 py-[2px]
                          rounded-xs mt-space-2 mr-space-2"
                        >
                          <p className="small-content">
                            {task?.rewards_type.includes("point")
                              ? "GEDA POINTS"
                              : task?.rewards_type.includes("ticket")
                              ? "TICKET"
                              : "MULTIPLIER"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {rewards.map((item: string, index: number) => {
                        const [rewardType, reward] = item.split("_");
                        return (
                          <p
                            key={index}
                            className={`small-content text-right ${
                              index > 0 ? "mt-space-2" : ""
                            }`}
                          >
                            <span className="text-l leading-[19px]">
                              +{reward}
                            </span>
                            <br />
                            <span className="text-orange-600">
                              {rewardType === "multiplier" && "% "}
                              {rewardType === "point"
                                ? " GEDA POINTS"
                                : ` ${rewardType.toUpperCase()}`}
                            </span>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  {task?.completed && (
                    <div
                      className="w-full h-full absolute top-0 left-0 text-center"
                      style={{
                        backdropFilter: "blur(3px)",
                      }}
                    >
                      <div
                        className="w-full absolute top-[50%] left-0 transform translate-y-[-50%] text-l leading-[24px] text-center leading-[32px] blender-medium"
                        style={{
                          color: getMissionBorderColor(task),
                          fontWeight: 700,
                        }}
                      >
                        Completed
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {selectedTask && (
        <ModalConfirmTweet
          isModalOpen={tweetModal}
          setIsModalOpen={setTweetModal}
          doTwitterTask={doTwitterTask}
          handleTwitterErr={handleTwitterErr}
          twitterJwt={twitterJwt}
          twitterName={twitterName}
          twitterType={twitterType}
          selectedTask={selectedTask}
          getMissionListCb={getMissionListCb}
          userId={userId}
        />
      )}
    </div>
  );
};
