import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";
import { iBuyBody, iSellBody, iSellConfirmBody } from "src/types/marketplace";
import { IOpenMysteryBox, ISellItem } from "src/types/mysteryBox";
import { responseMsg } from "src/types/responseState";
import { handleNotEnoughBalance } from "src/utils";
import { Token } from "src/types/currency";

export class MarketplaceStore {
  async getAssetDetail(contract: string, tokenId: string) {
    const url = `${Configs.API_URL}/v1/marketplace/asset-detail?tokenId=${tokenId}&contract=${contract}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getGedaAssets(collection: string, contract: string) {
    const url = `${Configs.API_URL}/v1/marketplace/geda-assets?collection=${collection}&contract=${contract}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyAssets(collection: string, contract: string) {
    const url = `${Configs.API_URL}/v1/marketplace/my-assets?collection=${collection}&contract=${contract}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getGedaAssetsV2(collection: string, contract: string) {
    const url = `${Configs.API_URL}/v2/marketplace/geda-assets?collection=${collection}&contract=${contract}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyAssetsV2(collection: string, contract: string) {
    const url = `${Configs.API_URL}/v2/marketplace/my-assets?collection=${collection}&contract=${contract}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async buy(body: iBuyBody) {
    const url = `${Configs.API_URL}/v1/marketplace/buy`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) {
        errorHandling(result?.data?.msg);
        return false;
      }
      return result["data"]["success"];
    } catch (e) {
      errorHandling(e);
      return false;
    }
  }

  async sell(body: iSellBody) {
    const url = `${Configs.API_URL}/v1/marketplace/sell`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async sellConfirm(body: iSellConfirmBody) {
    const url = `${Configs.API_URL}/v1/marketplace/sell/confirm`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMysteryBoxList() {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/list`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMysteryBoxItems(id: string) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/items?id=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMysteryBoxRecentWinnings(id?: string) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/recent-winnings${
      id ? `?id=${id}` : ""
    }`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMysteryBoxDetail(id: string) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/detail?id=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async openMysteryBox(body: IOpenMysteryBox) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/open`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async openMysteryBoxV2(body: IOpenMysteryBox) {
    const url = `${Configs.API_URL}/v2/marketplace/mystery-box/open`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async openMysteryBoxV3(body: IOpenMysteryBox, currency: Token = Token.STAR) {
    const url = `${Configs.API_URL}/v3/marketplace/mystery-box/open`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      if (result.data?.msg === responseMsg.NotEnoughBalance) {
        return handleNotEnoughBalance(currency);
      }
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async sellItem(body: ISellItem) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/sell-item`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async sellItems(body: { items: ISellItem[] }) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/sell-items`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async sellItemsById(body: { ids: string[] }) {
    const url = `${Configs.API_URL}/v1/marketplace/mystery-box/sell-items-id`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}
export const STORE_MARKETPLACE = "marketplaceStore";
