import { useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";
import { history } from "src/stores";
import { SearchInput } from "./search-input";
import { Token } from "src/types/currency";
import { IDashboardProfile } from "src/types/dashboard";
import { formatDollar } from "src/utils";
import { getCurrencyIcon } from "src/utils/image";
import { SlWallet } from "react-icons/sl";

interface IModalBalance {
  onCancel: () => void;
  data: IDashboardProfile | null;
  setDepositModal: (val: boolean) => void;
  setDepositCurrency: (val: string) => void;
}

export const ModalBalance = (props: IModalBalance) => {
  const { onCancel, data, setDepositModal, setDepositCurrency } = props;

  const currencyIconStyle = `w-[24px] h-[24px]`;
  const currencyNameStyle = `text-s leading-[14px] text-grey-600 font-[500] blender-medium`;
  const currencyBalanceStyle = `flex-1 text-xxl text-right leading-[24px] font-[500] blender-medium`;
  const depositButtonStyle = `p-xxs rounded-full cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px] bg-native-green`;

  const [searchString, setSearchString] = useState<string>("");

  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  const deposit = (token: string) => {
    if (window.location.pathname === "/dashboard") {
      setDepositCurrency(token);
      setDepositModal(true);
    } else {
      history.push("/dashboard#top-up?currency=" + token);
    }

    onCancel();
  };

  return (
    <div className="w-screen h-screen z-[999] fixed top-0 left-0 flex items-center justify-center">
      <div
        className="gap-space-2 relative z-[999] w-[320px] h-[568px] md:w-[640px] md:h-[590px]  items-start"
        style={{
          backgroundImage: `url("/assets/v4/${
            !isResponsive ? "receipt-bg" : "modal-mobile-bg"
          }.png")`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-center p-space-7 py-space-7">
          <h2 className="text-xxl md:text-xxxl text-grey-400 flex-1 mt-space-4 md:mt-space-8 normal-case">
            Total Balance
          </h2>
          <img
            src="/assets/v4/modal-close.png"
            alt="modal-close"
            className="w-[30px] h-[30px] md:w-[56px] md:h-[56px] cursor-pointer"
            onClick={() => onCancel()}
          />
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        <div className="px-space-8 h-[440px] py-space-4 flex flex-col gap-space-4">
          <div className="flex flex-col items-end">
            <SearchInput search={searchString} setSearch={setSearchString} />
          </div>
          <div className="h-full border-[2px] border-solid border-black-400 rounded-[14px] p-space-4 overflow-hidden overflow-y-auto scrollbar-hide scrollbar-hidden">
            <div className="flex flex-col gap-space-5">
              {"gs".search(searchString.toLowerCase()) !== -1 && (
                <div className="flex flex-row items-center gap-space-3">
                  <div className="flex flex-row items-center gap-space-1">
                    <img
                      src="/assets/v4/geda-star.png"
                      alt="geda-star"
                      className={currencyIconStyle}
                    />
                    <p className={currencyNameStyle}>GS</p>
                  </div>
                  <p className={currencyBalanceStyle}>
                    {formatDollar(data?.usdt || 0)}
                  </p>
                  <div className={depositButtonStyle}>
                    <SlWallet
                      className="w-[14px] h-[14px] text-white"
                      onClick={() => {
                        deposit(Token.STAR);
                      }}
                    />
                  </div>
                </div>
              )}

              {"max".search(searchString.toLowerCase()) !== -1 && (
                <div className="flex flex-row items-center gap-space-3">
                  <div className="flex flex-row items-center gap-space-1">
                    <img
                      src={getCurrencyIcon("MAX")}
                      alt="max"
                      className={currencyIconStyle}
                    />
                    <p className={currencyNameStyle}>GMAX</p>
                  </div>
                  <p className={currencyBalanceStyle}>
                    {formatDollar(data?.max || 0)}
                  </p>
                  <div className={depositButtonStyle}>
                    <SlWallet
                      className="w-[14px] h-[14px] text-white"
                      onClick={() => {
                        deposit(Token.MAX);
                      }}
                    />
                  </div>
                </div>
              )}

              {"gdd".search(searchString.toLowerCase()) !== -1 && (
                <div className="flex flex-row items-center gap-space-3">
                  <div className="flex flex-row items-center gap-space-1">
                    <img
                      src={getCurrencyIcon("GDD")}
                      alt="gdd"
                      className={currencyIconStyle}
                    />
                    <p className={currencyNameStyle}>GDD</p>
                  </div>
                  <p className={currencyBalanceStyle}>
                    {formatDollar(data?.gdd || 0)}
                  </p>
                  <div className={`${depositButtonStyle} opacity-0`}>
                    <SlWallet className="w-[14px] h-[14px] text-white" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      <div className="bg-black-800/60 w-full h-full left-0 absolute"></div>
    </div>
  );
};
