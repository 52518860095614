import clsx from "clsx";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { formatName, getRarityColor } from "src/utils";

interface ICaseOpeningSelectOption {
  className?: string;
  item: IMysteryBoxPrizeDetail;
  isSelected?: boolean;
  onSelect: () => void;
  disabled: boolean;
}

const CaseOpeningSelectOption = ({
  className,
  item,
  onSelect,
  disabled,
}: ICaseOpeningSelectOption) => {
  const {
    name = "",
    quality = "",
    property,
    weaponPrice,
    imgUrl = "",
    sellCurrency,
    weaponConvertPrice,
  } = item || {};
  const rarityColor = getRarityColor(quality);
  const calculatedPrice = (
    (sellCurrency && sellCurrency !== "USDT"
      ? weaponConvertPrice
      : weaponPrice) || 0
  ).toFixed(2);

  return (
    <div
      className={clsx(
        className,
        "case-opening-select-option-border-color",
        "flex p-space-2 gap-space-2 cursor-pointer flex-col md:flex-row items-center"
      )}
      style={{
        borderTopColor: rarityColor,
      }}
    >
      {/* Item image */}
      <div className="shrink-0 basis-1/2 md:basis-[100px] flex items-center justify-center">
        <img className="h-full" src={imgUrl} alt="" />
      </div>
      {/* Description */}
      <div className="flex-1 flex flex-col items-start gap-space-1 md:gap-space-2 overflow-hidden w-full md:w-auto">
        <div className="col-span-full md:justify-self-start  text-s md:text-l text-grey-100 leading-none text-right md:text-left">
          {formatName(name)} {disabled ? "(Sold)" : ""}
        </div>
        <div className="hidden col-span-full text-s text-grey-800 leading-none md:flex items-center">
          Wear rating: <span className="uppercase ml-space-1">{property}</span>
        </div>
        <div className="text-grey-200 flex items-center flex-1">
          <div
            className="rounded-full w-[8px] h-[8px] mr-1"
            style={{ backgroundColor: rarityColor }}
          />
          <p className="text-s text-gray-200 leading-none">
            {formatName(quality)}
          </p>
        </div>
      </div>

      {/* Sell Button */}
      <div className="flex items-center justify-center">
        <button
          className={clsx(
            "case-opening-sell-btn",
            "w-full h-full flex items-center justify-center",
            "border rounded-xs border-native-green text-native-green text-ms cursor-pointer normal-case flex-1"
          )}
          onClick={onSelect}
        >
          Sell for {calculatedPrice}
        </button>
      </div>
    </div>
  );
};

export { CaseOpeningSelectOption };
