import { IPredictionGame } from "src/types/prediction";
import { useAccount } from "wagmi";
import { GamePredictionV2 } from "./game-prediction-v2";
import { GamePredictionRowV2 } from "./game-prediction-row-v2";
import { useState } from "react";

interface IPredictionV4GamePrediction {
  id?: string;
  predictionGames: IPredictionGame[] | null;
  fetchGame: (val: string) => void;
}

export const PredictionV4GamePrediction = (
  props: IPredictionV4GamePrediction
) => {
  const { id, predictionGames, fetchGame } = props;
  const [tab, setTab] = useState<"series" | "my-predictions">("series");
  const [showEnded, setShowEnded] = useState(false);
  const { address } = useAccount();

  return (
    <div className="px-space-0 md:px-space-2 py-space-2 md:py-space-4 gap-space-4 flex flex-col h-full">
      <h2 className="text-l sm:text-xxml text-grey-100 leading-[19px] sm:leading-[28px] !font-[900] blender-medium mb-0">Series Information</h2>

      {/* Upcoming */}
      {predictionGames && predictionGames.length > 0 && (
        <>
          <div
            className="relative pt-0 px-space-2 pb-space-2 border-1 border-native-blue border-t-0 rounded-xs sm:rounded-s"
            style={{
              background:
                "linear-gradient(360deg, rgba(12, 109, 210, 0.5) 0%, rgba(12, 109, 210, 0) 100%)",
            }}
          >
            <GamePredictionRowV2
              game={predictionGames[0]?.games[0]}
              address={address}
              fetchGame={() => fetchGame(id || "")}
            />
          </div>

          {/* Series & My Predictions */}
          <div className="rounded-xs sm:rounded-s bg-black-400 overflow-hidden">
            <div className="flex flex-row items-center w-full">
              <div
                className={`px-space-2 sm:px-space-4 py-space-3 border-0 border-b-[1px] border-solid flex-1 cursor-pointer ${
                  tab === "series"
                    ? "bg-black-600 border-b-[2px] border-orange-800"
                    : "border-black-200"
                }`}
                onClick={() => setTab("series")}
              >
                <p
                  className={`text-s sm:text-l text-grey-600 leading-[14px] sm:leading-[24px] blender-medium ${
                    tab === "series"
                      ? "text-orange-800"
                      : "text-grey-600"
                  }`}
                >
                  Series
                </p>
              </div>

              <div
                className={`px-space-2 sm:px-space-4 py-space-3 border-0 border-b-[1px] border-solid flex-1 cursor-pointer ${
                  tab === "my-predictions"
                    ? "bg-black-600 border-b-[2px] border-orange-800"
                    : "border-black-200"
                }`}
                onClick={() => setTab("my-predictions")}
              >
                <p
                  className={`text-s sm:text-l text-grey-600 leading-[14px] sm:leading-[24px] blender-medium ${
                    tab === "my-predictions"
                      ? "text-orange-800"
                      : "text-grey-600"
                  }`}
                >
                  My predictions
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-space-2 p-space-2">
              <div className="flex flex-row items-center justify-between">
                <p className="text-s sm:text-l text-grey-100 leading-[14px] sm:leading-[19px] blender-medium">
                  {tab === "series" ? "Series" : "My"} Prediction
                </p>
                <div className="flex flex-row items-center gap-space-1 justify-end ">
                  <p
                    className="text-s sm:text-l text-grey-100 leading-[14px] sm:leading-[19px] blender-medium cursor-pointer"
                    onClick={() => setShowEnded(!showEnded)}
                  >
                    Show {showEnded ? "Current" : "Ended"}
                  </p>
                  <img
                    src="/assets/v4/sniper.png"
                    alt="sniper"
                    className="w-[12px] h-[12px]"
                  />
                </div>
              </div>

              <div className="px-space-2 py-space-2 sm:py-space-4 flex flex-col gap-space-2 bg-black-200 rounded-xs sm:rounded-s">
                <GamePredictionV2
                  predictionList={predictionGames}
                  address={address}
                  fetchGame={() => fetchGame(id || "")}
                  tab={tab}
                  showEnded={showEnded}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
