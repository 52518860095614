import { IMysteryBox } from "src/types/mysteryBox";

export const renderMysteryBoxTag = (item: IMysteryBox, isAbsolute = true) => {
  if (!item?.is_new && item.selectable) return null;
  let text = "";
  let bgColor = "#737373";
  if (item?.is_new) {
    text = "New";
    bgColor = "#FE5219";
  }
  if (!item?.selectable) {
    text = "Upcoming";
    bgColor = "#D40C48";
  }
  return (
    <p
      className={`${
        isAbsolute ? "absolute right-space-4 bottom-space-4" : "relative"
      } py-space-0 px-space-1 text-white rounded-xs text-s z-[999]`}
      style={{ background: bgColor }}
    >
      {text}
    </p>
  );
};

export const renderTag = (name: string, bgColor = "#FE5219") => {
  return (
    <p
      className={`py-space-1 px-space-2 text-white rounded-xs text-s z-[999] uppercase`}
      style={{ background: bgColor }}
    >
      {name}
    </p>
  );
};
