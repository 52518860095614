import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import clsx from "clsx";
import { useMarketplaceStore } from "src/stores";
import {
  IMysteryBox,
  IMysteryBoxItem,
  IMysteryBoxPrizeDetail,
} from "src/types/mysteryBox";
import { openWarningNotification } from "src/components/notification";
import { CaseOpeningBoxSelectedModal } from "src/components/case-opening-box-selected-modal";
import { CaseOpeningBoxSingleResultModal } from "src/components/case-opening-box-single-result-modal";
import { CaseOpeningBoxMultiResultModal } from "./case-opening-box-multi-result-modal";
import { CaseOpeningRouletteContext } from "src/components/case-opening-roulette-provider";
import { CaseOpeningRouletteLayout } from "src/components/case-opening-roulette-layout";
import { useAuth } from "./auth-context";
import { history } from "src/stores";
interface RouteParams {
  id: string;
}

export const CaseOpeningMainArea = () => {
  const { getMysteryBoxDetail, getMysteryBoxItems, openMysteryBoxV3 } =
    useMarketplaceStore();
  const { id } = useParams<RouteParams>();
  const { storeBoxItems, spin, isRolling, isHighlightPhase } = useContext(
    CaseOpeningRouletteContext
  );
  const [selectedNum, setSelectedNum] = useState<number>(1);
  const [boxData, setBoxData] = useState<IMysteryBox | null>(null);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [prizes, setPrizes] = useState<IMysteryBoxPrizeDetail[]>([]);
  const [gdd, setGdd] = useState<number>(0);
  const [isBoxModalVisible, setIsBoxModalVisible] = useState<boolean>(true);
  const [isResultModalVisible, setIsResultModalVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loggedIn, setOpenLoginModal, getDashboardProfileCb } = useAuth();

  // Initialize
  useEffect(() => {
    setSelectedNum(1);
    setBoxData(null);
    setShowAnimation(false);
    setPrizes([]);
    setIsBoxModalVisible(true);
    setIsResultModalVisible(false);
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (!id) return;

    getMysteryBoxDetail(id).then((result?: IMysteryBox) => {
      if (!result) return;

      setBoxData(result);
    });
  }, [id, getMysteryBoxDetail]);

  useEffect(() => {
    if (!id) return;

    getMysteryBoxItems(id).then((result?: IMysteryBoxItem[]) => {
      if (!result) return;

      storeBoxItems(result);
    });
  }, [id, getMysteryBoxItems, storeBoxItems]);

  useEffect(() => {
    if (isHighlightPhase) {
      setTimeout(() => {
        setIsResultModalVisible(true);
      }, 1300);
    }
  }, [isHighlightPhase]);

  const handleSpin = async () => {
    if (isLoading) return;
    if (!loggedIn) {
      setOpenLoginModal(true);
      return openWarningNotification("Please login to open the case.");
    }

    setIsLoading(true);
    const result = await openMysteryBoxV3(
      {
        id: id,
        quantity: selectedNum,
      },
      boxData?.currency
    );
    setIsLoading(false);
    if (!result) return openWarningNotification("Failed to open mystery box.");

    setIsBoxModalVisible(false);
    setIsResultModalVisible(false);
    setPrizes(result?.prizes || []);
    setGdd(result?.gdd || 0);
    setShowAnimation(true);
    getDashboardProfileCb();
    spin(isRolling);
  };

  const handleCloseResultModal = () => {
    setPrizes([]);
    setShowAnimation(false);
    setIsBoxModalVisible(true);
    setIsResultModalVisible(false);
  };

  const handleRetry = () => {
    handleSpin();
  };

  return (
    <div className="relative flex h-[calc(100svh_-_80px)] min-h-[520px] md:h-[622px]">
      <div className="absolute w-full h-full top-0 left-0 overflow-hidden">
        <img
          className="w-full h-full object-cover md:-translate-y-[25%] md:h-auto"
          src="/assets/v4/overlay-faded.png"
          alt="background"
        />
      </div>
      {/* Box Selected Modal */}
      <CaseOpeningBoxSelectedModal
        box={boxData}
        selectedNum={selectedNum}
        isVisible={isBoxModalVisible}
        onClose={() => history.push("/mystery-box")}
        onClickNum={(num: number) => setSelectedNum(num)}
        onClickOpen={() => handleSpin()}
      />
      {/* Result Modal */}
      {prizes.length > 1 ? (
        <CaseOpeningBoxMultiResultModal
          prizes={prizes}
          isVisible={isResultModalVisible}
          selectedNum={selectedNum}
          onClose={handleCloseResultModal}
          onRetry={handleRetry}
          bonus={gdd}
        />
      ) : (
        <CaseOpeningBoxSingleResultModal
          prize={prizes[0]}
          isVisible={isResultModalVisible}
          onClose={handleCloseResultModal}
          onRetry={handleRetry}
          bonus={gdd}
        />
      )}
      {/* Roulette Area */}
      {showAnimation && (
        <CaseOpeningRouletteLayout
          totalRoulette={selectedNum}
          prizes={prizes}
        />
      )}
      {/* Gradient Overlay */}
      <div className="absolute w-full h-full top-0 left-0 bg-[radial-gradient(rgba(0,0,0,0),rgba(0,0,0,0.8))]" />
      <div
        className={clsx(
          "z-10 absolute w-full h-full top-0 left-0 flex items-center justify-center",
          "bg-black transition-opacity",
          { "invisible opacity-0 pointer-events-none": !isLoading },
          { "visible opacity-60": isLoading }
        )}
        onClick={(e) => {
          if (isLoading) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <img
          className="w-[80px] h-[80px] md:w-[200px] md:h-[200px]"
          src={"/assets/v4/loading.gif"}
          alt="loading"
        />
      </div>
    </div>
  );
};
