import { iSkin } from "src/types/marketplace";

export function toggleItemInList(list: iSkin[], item: iSkin) {
  const itemExists = list.some((currentItem) => currentItem.id === item.id);
  if (itemExists) {
    // If the item is already in the list, remove it
    return list.filter((currentItem) => currentItem?.id !== String(item?.id));
  } else {
    // If the item is not in the list, add it
    return [...list, item];
  }
}

export function toggleItemInListId(list: string[], item: string) {
  const itemExists = list.some((currentItem) => currentItem === item);
  if (itemExists) {
    // If the item is already in the list, remove it
    return list.filter((currentItem) => currentItem !== String(item));
  } else {
    // If the item is not in the list, add it
    return [...list, item];
  }
}
