import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";
export class LeaderboardStore {
  async getRankings() {
    const url = `${Configs.API_URL}/v2/marketplace/mystery-box/leaderboard/rankings`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyRanking() {
    const url = `${Configs.API_URL}/v2/marketplace/mystery-box/leaderboard/my-ranking`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data[0];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_LEADERBOARD = "leaderboardStore";
