import { FC } from "react";
import clsx from "clsx";
import { IoIosNotificationsOutline } from "react-icons/io";
import { formatUnread } from "src/utils";

interface IInboxIconProps {
  className?: String;
  unreadClass?: String;
  unread: number;
}

const InboxIcon: FC<IInboxIconProps> = ({ className, unreadClass, unread }) => {
  const hasUnread = unread > 0;
  const count = formatUnread(unread);

  return (
    <div className={clsx(className, "relative text-grey-200")}>
      <IoIosNotificationsOutline className="w-full h-full" />
      {hasUnread && (
        <div
          className={clsx(
            unreadClass,
            "flex items-center justify-center absolute right-0 top-0 rounded-full bg-[#EE484E] text-white text-xs leading-none"
          )}
        >
          {count}
        </div>
      )}
    </div>
  );
};

export { InboxIcon };
