import { useState } from "react";
import { ContainerV3 } from "src/components/container-v3";
import { PredictionV4GameList } from "src/components/prediction-v4-game-list";
import { PredictionV4GameSeries } from "src/components/prediction-v4-game-series";

export const PredictionV4 = () => {
  const [selectedGameIds, setSelectedGameIds] = useState<string>("");

  return (
    <ContainerV3>
      <div className="flex flex-col px-space-2 sm:px-space-6 py-space-4 sm:py-space-6 gap-space-4">
        {/* Games */}
        <PredictionV4GameList
          selectedGameIds={selectedGameIds}
          setSelectedGameIds={setSelectedGameIds}
        />
        {/* Game List */}
        <PredictionV4GameSeries selectedGameIds={selectedGameIds} />
      </div>
    </ContainerV3>
  );
};
