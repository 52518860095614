import { IDashboardProfile } from "src/types/dashboard";
import { formatDollar } from "src/utils";
import { getCurrencyIcon } from "src/utils/image";

interface iMobileNavBalanceCurrencies {
  dashboardData: IDashboardProfile | null,
}

export const MobileNavBalanceCurrencies = (props: iMobileNavBalanceCurrencies) => {
  const {
    dashboardData,
  } = props;

  const currencyIconStyle = `w-[10px] md:w-[14px] h-[10px] md:h-[14px]`;
  const currencyBalanceStyle = `text-s md:text-m leading-[10px] md:leading-[14px] font-[500] blender-medium`;

  return (
    <div className="flex flex-col gap-space-1">
      <div className="flex flex-row gap-space-1 mx-auto">
        <img
          src="/assets/v4/geda-star.png"
          alt="geda-star"
          className={currencyIconStyle}
        />
        <p className={currencyBalanceStyle}>{formatDollar(dashboardData?.usdt || 0)}</p>
      </div>
      <div className="flex flex-row gap-space-1 mx-auto">
        <img
          src={getCurrencyIcon("MAX")}
          alt="max"
          className={currencyIconStyle}
        />
        <p className={currencyBalanceStyle}>{formatDollar(dashboardData?.max || 0)}</p>
      </div>
      <div className="flex flex-row gap-space-1 mx-auto">
        <img
          src={getCurrencyIcon("GDD")}
          alt="gdd"
          className={currencyIconStyle}
        />
        <p className={currencyBalanceStyle}>{formatDollar(dashboardData?.gdd || 0)}</p>
      </div>
    </div>
  );
};
