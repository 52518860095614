import { history } from "src/stores";
import { openWarningNotification } from "./notification";
import { FaGuilded } from "react-icons/fa";
import { MdSportsEsports } from "react-icons/md";
import clsx from "clsx";

interface iIconButton {
  icon?: string;
  name: string;
  url?: string;
  page?: string;
  suffix?: string;
  isActive?: boolean;
  largeIcon?: boolean;
  iconProps?: string;
  isHovered?: boolean;
  onHoverChange: (isHovered: boolean) => void;
}

export const IconButton = (props: iIconButton) => {
  const {
    icon,
    name,
    url,
    page,
    suffix,
    isActive = false,
    largeIcon = false,
    iconProps = null,
    isHovered,
    onHoverChange,
  } = props;

  const iconCurve = icon ? icon.replace(".png", "-curve.png") : undefined;

  const getIconPropIcon = () => {
    const iconPropsClasses = "w-[24px] h-[24px] mr-space-2 text-grey-600";
    if (iconProps === "FaGuilded")
      return (
        <FaGuilded
          className={iconPropsClasses}
          style={{ color: isHovered ? "rgba(254,82,25,1)" : "" }}
        />
      );
    if (iconProps === "MdSportsEsports")
      return (
        <MdSportsEsports
          className={iconPropsClasses}
          style={{ color: isHovered ? "rgba(254,82,25,1)" : "" }}
        />
      );
    return null;
  };

  const redirect = () => {
    if (url) return window.open(url, "_self");
    if (page) return history.push(page);
    return openWarningNotification("Coming soon");
  };

  return (
    <div
      className={clsx(
        "h-[100%] flex flex-row items-center justify-center cursor-pointer relative",
        {
          "nav-btn-hovered translate-y-[-2px]": isHovered && !isActive,
        }
      )}
      onClick={() => redirect()}
      onMouseOver={() => onHoverChange(true)}
      onMouseOut={() => onHoverChange(false)}
    >
      {iconProps && getIconPropIcon()}
      {icon && (
        <img
          src={icon}
          alt={name}
          className={`${
            largeIcon ? "w-[46px] h-[46px] relative" : "w-[24px] h-[24px]"
          } `}
        />
      )}
      <p
        className={`${icon ? "ml-space-2" : "ml-0"} ${
          (isActive || isHovered) && "text-orange-800"
        } ${largeIcon ? "!ml-0" : ""}`}
      >
        {name}
      </p>
      {suffix && (
        <img src={suffix} alt={name} className="w-[24px] h-[24px] ml-space-2" />
      )}

      {isActive && (
        <>
          <img
            src={iconCurve}
            className="absolute overflowhidden bottom-[-1px]"
            alt={`${iconCurve}-curve`}
          />
          <img
            src="/assets/v4/orange.png"
            className="absolute left-[50%] ml-[-9.5px] bottom-[-10px]"
            alt="nav-point"
          />
        </>
      )}
    </div>
  );
};
