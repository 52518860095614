import { ICaseOpeningFilters } from "src/types/case-opening";
import { CaseOpeningNumberRangeInput } from "src/components/case-opening-number-range-input";
import { SearchInput } from "src/components/search-input";
import { CaseOpeningSorter } from "src/components/case-opening-sorter";

interface ICaseOpeningFilter {
  filters: ICaseOpeningFilters;
  setFilters: React.Dispatch<React.SetStateAction<ICaseOpeningFilters>>;
}

const CaseOpeningFilter = ({ filters, setFilters }: ICaseOpeningFilter) => {
  const { name = "", range = [], sort } = filters;
  const handleChange = (key: string, newValue: any) => {
    setFilters((prev: ICaseOpeningFilters) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  return (
    <div className="flex flex-row items-center mb-space-6">
      <SearchInput
        search={name}
        setSearch={(value) => handleChange("name", value.toLowerCase())}
      />
      <CaseOpeningNumberRangeInput
        className="hidden md:block md:ml-space-4"
        range={range}
        onChange={(value) => handleChange("range", value)}
      />
      <div className="ml-auto flex items-center">
        {/* <CaseOpeningSorter
          label="Platform"
          order={platform}
          onChange={(value) => handleChange("platform", value)}
        /> */}
        <CaseOpeningSorter
          label="Sort by"
          order={sort}
          onChange={(value) => handleChange("sort", value)}
        />
      </div>
    </div>
  );
};

export { CaseOpeningFilter };
