import { history } from "src/stores";
import { openWarningNotification } from "src/components";
import { responseMsg } from "src/types/responseState";
import { Token } from "src/types/currency";
import { formatMessage } from "./format";

type IHandleNotEnoughBalanceType = (currency: Token) => void;

export const handleNotEnoughBalance: IHandleNotEnoughBalanceType = (
  currency = Token.STAR
) => {
  openWarningNotification(formatMessage(responseMsg.NotEnoughBalance));
  history.push("/dashboard#top-up?currency=" + currency);

  return false;
};
