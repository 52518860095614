import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { WS_API_URL } from "src/config";
import { IAnnouncedWeapon } from "src/types/case-opening";

interface WebSocketContextType {
  socket: WebSocket | null;
  isConnected: boolean;
  announcedWeapon: IAnnouncedWeapon | null;
  sendMessage: (message: string) => void;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [announcedWeapon, setAnnouncedWeapon] =
    useState<IAnnouncedWeapon | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 10;
  const RETRY_INTERVAL = 3000; // 3 seconds between retries
  const HEARTBEAT_INTERVAL = 30000; // 30 seconds for heartbeat

  const connectWebSocket = useCallback(() => {
    const ws = new WebSocket(WS_API_URL);
    let heartbeatInterval: NodeJS.Timeout;

    ws.onopen = () => {
      console.log("WebSocket connected");
      setIsConnected(true);
      setRetryCount(0); // Reset retry count on successful connection

      // Start heartbeat
      heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "ping" }));
        }
      }, HEARTBEAT_INTERVAL);
    };

    ws.onmessage = (event) => {
      console.log("WebSocket message received:", event.data);
      // Handle incoming messages here
      if (event?.data) {
        const { type, data = {} } = JSON.parse(event.data) || {};

        switch (type) {
          case "pong":
            // Received heartbeat response
            break;
          case "winning_announcement":
            setAnnouncedWeapon(data);
            break;

          default:
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
      setIsConnected(false);
      clearInterval(heartbeatInterval);

      if (retryCount < MAX_RETRIES) {
        console.log(
          `Attempting to reconnect... (${retryCount + 1}/${MAX_RETRIES})`
        );
        setTimeout(() => {
          setRetryCount((prev) => prev + 1);
          connectWebSocket();
        }, RETRY_INTERVAL);
      }
    };

    setSocket(ws);

    return () => {
      clearInterval(heartbeatInterval);
      ws.close();
    };
  }, [retryCount]);

  useEffect(() => {
    const cleanup = connectWebSocket();
    return cleanup;
  }, [connectWebSocket]);

  const sendMessage = useCallback(
    (message: string) => {
      if (socket && isConnected) {
        socket.send(message);
      } else {
        console.error("WebSocket is not connected");
      }
    },
    [socket, isConnected]
  );

  return (
    <WebSocketContext.Provider
      value={{ socket, isConnected, announcedWeapon, sendMessage }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
