import {
  ClosedLeaderboardTab,
  OpenedLeaderboardTab,
  OpenedLeaderboardMobileTab,
} from "./leaderboard-tab";
import { LeaderboardUser } from "./leaderboard-user";
import { LeaderboardBody, LeaderboardMobileBody } from "./leaderboard-body";
import { useState, useEffect } from "react";
import { useAuth } from "./auth-context";
import { useLeaderboardStore } from "src/stores";
import { useCallback } from "react";
import { ILeaderboardData } from "src/types/leaderboard";

export const Leaderboard = () => {
  const [displayBoard, setDisplayBoard] = useState(false);
  const { loggedIn } = useAuth();
  const { getRankings, getMyRanking } = useLeaderboardStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [myRanking, setMyRanking] = useState<ILeaderboardData | null>(null);
  const [rankings, setRankings] = useState<ILeaderboardData[]>([]);

  const getRankImage = useCallback((rank: number): string | undefined => {
    switch (rank) {
      case 1:
        return "/assets/v4/leaderboard-first.png";
      case 2:
        return "/assets/v4/leaderboard-second.png";
      case 3:
        return "/assets/v4/leaderboard-third.png";
      default:
        return "";
    }
  }, []);

  const processReceivedData = useCallback(
    (receivedData: ILeaderboardData) => {
      const processedData = {
        avatar: receivedData.avatar
          ? receivedData.avatar
          : "/assets/v4/avatar.png",
        rank: receivedData.rank,
        rankImage: getRankImage(receivedData.rank),
        displayName: receivedData.displayName,
        totalAmount: receivedData.totalAmount,
      };
      return processedData;
    },
    [getRankImage]
  );

  const getMyRankingCb = useCallback((): void => {
    setLoading(true);
    getMyRanking()
      .then((result: any) => {
        if (!result) return;
        if (result.rank <= 6) return;
        const processedMyRanking = processReceivedData(result);
        setMyRanking(processedMyRanking);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getMyRanking, processReceivedData]);

  const getRankingsCb = useCallback((): void => {
    setLoading(true);
    getRankings()
      .then((result: any) => {
        if (!result) return;
        const processedRankings = result.map((item: any) =>
          processReceivedData(item)
        );
        setRankings(processedRankings);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getRankings, processReceivedData]);

  // get top 6 rankings
  useEffect(() => {
    getRankingsCb();
  }, [getRankingsCb]);

  // get my ranking
  useEffect(() => {
    if (!loggedIn) {
      setMyRanking(null);
      return;
    }
    getMyRankingCb();
  }, [getMyRankingCb, loggedIn]);

  // update every hour
  useEffect(() => {
    const updateRanking = setInterval(() => {
      getRankingsCb();
      if (!loggedIn) return;
      getMyRankingCb();
    }, 3600000);

    return () => {
      clearInterval(updateRanking);
    };
  }, [getRankingsCb, getMyRankingCb, loggedIn]);

  const fetchedLeaderboardData = myRanking
    ? [...rankings, myRanking]
    : rankings;

  return (
    <div>
      <div className="flex items-center w-full">
        {displayBoard === false && (
          <div className="hidden sm:block">
            <LeaderboardUser rankings={rankings} />
          </div>
        )}

        {displayBoard === false && (
          <div
            className="cursor-pointer "
            onClick={() => setDisplayBoard(true)}
          >
            <ClosedLeaderboardTab />
          </div>
        )}

        {displayBoard && (
          <div className="flex flex-col flex-1">
            {/* tab */}
            <div
              className="cursor-pointer flex flex-1 hidden sm:block "
              onClick={() => setDisplayBoard(false)}
            >
              <OpenedLeaderboardTab />
            </div>

            <div
              className="cursor-pointer flex flex-1 block sm:hidden"
              onClick={() => setDisplayBoard(false)}
            >
              <OpenedLeaderboardMobileTab />
            </div>

            {/* body */}
            <div className="hidden sm:block">
              <LeaderboardBody
                displayBoard={displayBoard}
                setDisplayBoard={setDisplayBoard}
                leaderboardData={fetchedLeaderboardData}
                loading={loading}
              />
            </div>
            <div className="block sm:hidden">
              <LeaderboardMobileBody
                displayBoard={displayBoard}
                setDisplayBoard={setDisplayBoard}
                leaderboardData={fetchedLeaderboardData}
                loading={loading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
