import { useEffect, useRef } from "react";
import { IDashboardProfile } from "src/types/dashboard";
import { MobileNavBalanceCurrencies } from "./mobile-nav-balance-currencies";
import { FiInfo } from "react-icons/fi";

interface iMobileNavBalance {
  dashboardData: IDashboardProfile | null,
  setBalanceModal: (val: boolean) => void;
}

export const MobileNavBalance = (props: iMobileNavBalance) => {
  const {
    dashboardData,
    setBalanceModal,
  } = props;

  const mainRef = useRef<HTMLDivElement | null>(null);

  const startAutoScroll = useRef(() => {
    autoScroll();
  });

  const autoScroll = () => {
    setTimeout(() => {
      if((mainRef && mainRef.current)) {
        let rowHeight = mainRef.current.getElementsByTagName("div")[0].getElementsByTagName("div")[0].getElementsByTagName("div")[0].clientHeight;
        if(rowHeight > 0) {
          let scrollY = rowHeight + 4;

          if(mainRef.current.scrollTop + scrollY > mainRef.current.scrollHeight / 2) {
            mainRef.current.scrollTop = 0;
          }

          mainRef.current.scrollTo({
            top: mainRef.current.scrollTop + scrollY,
            left: 0,
            behavior: "smooth"
          });
        }

        autoScroll();
      }
    }, 3000);
  };

  useEffect(() => {
    startAutoScroll.current();
  }, [mainRef]);

  return (
    <div
      className="flex flex-row items-center min-w-[70px] md:min-w-[90px] h-[24px] md:h-[40px] border-[0px] border-x-[1px] border-solid border-grey-400 px-space-2 cursor-pointer"
      style={{
        background: "linear-gradient(180deg, rgba(66, 66, 66, 0.1) 0%, rgba(163, 163, 163, 0.3) 50%, rgba(66, 66, 66, 0.1) 100%)"
      }}
      onClick={() => setBalanceModal(true)}
    >
      <div
        className="flex-1 h-full pt-[7px] md:pt-[13px] overflow-hidden"
        ref={mainRef}
      >
        <div className="flex flex-col gap-space-1">
          <MobileNavBalanceCurrencies
            dashboardData={dashboardData}
          />
          <MobileNavBalanceCurrencies
            dashboardData={dashboardData}
          />
        </div>
      </div>
      <div className="px-space-1" />
      <FiInfo className="w-[12px] md:w-[16px] h-[12px] md:h-[16px] text-white" />
    </div>
  );
};
