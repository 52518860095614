import { LeaderboardHeader } from "./leaderboard-header";
import { LeaderboardRow } from "./leaderboard-row";
import { ILeaderboardData } from "src/types/leaderboard";
import { LoadingIconV2 } from "./loading-icon-v2";

interface ILeaderboardBody {
  displayBoard: boolean;
  setDisplayBoard: React.Dispatch<React.SetStateAction<boolean>>;
  leaderboardData: ILeaderboardData[];
  loading: boolean;
}

export const LeaderboardBody = (props: ILeaderboardBody) => {
  const { leaderboardData, loading } = props;
  return (
    <div
      className="bg-black-400 w-[360px]"
      style={{ borderRadius: "0px 0px 8px 8px" }}>
      <div
        className="bg-black-400 p-space-2"
        style={{ borderRadius: "0px 0px 8px 8px" }}>
        <LeaderboardHeader />
        {!loading && leaderboardData && leaderboardData.length > 0 && (
          <div>
            {leaderboardData.map((item: ILeaderboardData, index: number) => {
              return <LeaderboardRow item={item} key={index} />;
            })}
          </div>
        )}

        {loading && (
          <div className="flex justify-center items-center mb-space-2">
            <LoadingIconV2 />
          </div>
        )}
      </div>
    </div>
  );
};

export const LeaderboardMobileBody = (props: ILeaderboardBody) => {
  const { leaderboardData, setDisplayBoard, loading } = props;
  return (
    <div
      className="bg-black-400 w-[304px] ml-space-2"
      style={{ borderRadius: "0px 0px 8px 8px" }}>
      <div
        className="bg-black-400 p-0 sm:px-space-2"
        style={{ borderRadius: "0px 0px 8px 8px" }}>
        <LeaderboardHeader />
        {!loading && leaderboardData && leaderboardData.length > 0 && (
          <div>
            {leaderboardData.map((item: ILeaderboardData, index: number) => {
              return <LeaderboardRow item={item} key={index} />;
            })}
          </div>
        )}
        {loading && (
          <div className="flex justify-center items-center mb-space-2">
            <LoadingIconV2 />
          </div>
        )}
        <div
          className="flex justify-center mt-space-2 cursor-pointer"
          onClick={() => setDisplayBoard(false)}>
          <img
            src="/assets/v4/leaderboard-close.png"
            alt="ranking"
            className="w-[24px] h-[24px]"
          />
        </div>
      </div>
    </div>
  );
};
