import { useEffect, useState } from "react";
import { useGameStore } from "src/stores";
import { IGameSeries } from "src/types/prediction";
import { GameSeriesItem } from "./game-series-item";

interface IPredictionV4GameSeries {
  selectedGameIds: string;
  title?: string;
  gridColsClassName?: string;
  isTurnOnExpandFunction?: boolean;
}

export const PredictionV4GameSeries = (props: IPredictionV4GameSeries) => {
  const { selectedGameIds, title=null, gridColsClassName=null, isTurnOnExpandFunction=false } = props;
  const [games, setGames] = useState<IGameSeries[]>([]);
  const [isExpand, setIsExpand] = useState(false);

  const { getGameSeries } = useGameStore();

  useEffect(() => {
    getGameSeries(selectedGameIds).then((result) => {
      if (!result && typeof result === "string") return;
      setGames(result);
    });
  }, [getGameSeries, selectedGameIds]);
  return (
    <div className="rounded-xs sm:rounded-s p-space-2 sm:p-space-4 gap-space-2 sm:gap-space-4 bg-black-600">
      <div className="flex flex-row items-center gap-space-2">
        <h2 className="flex-[1] text-l sm:text-xxml text-grey-100 leading-[19px] sm:leading-[28px] !font-[900] blender-medium mb-0">{title ? title : "Series"}</h2>

        {isTurnOnExpandFunction && (
          <div
            className="flex flex-row items-center gap-space-1 cursor-pointer"
            onClick={() => setIsExpand(!isExpand)}
          >
            <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
              {isExpand ? "Hide" : "Show"}
            </p>

            <div
              className={`w-[20px] h-[20px] ${
                isExpand ? "transform rotate-[180deg]" : ""
              }`}
            >
              <img
                src="/assets/v4/dropdown.png"
                alt="maximize"
                className="w-full"
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={`grid ${
          !isTurnOnExpandFunction || isExpand
            ? "grid-rows-[1fr] mt-space-2"
            : "grid-rows-[0fr]"
        } transition-all duration-500`}
      >
        {/* List of Games */}
        <div className={`grid ${gridColsClassName ? gridColsClassName : "grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 l:grid-cols-5"} gap-space-2 overflow-hidden`}>
          {games &&
            games.length > 0 &&
            games.map((item: IGameSeries, i: number) => {
              return <GameSeriesItem item={item} key={i} />;
            })}
        </div>
      </div>
    </div>
  );
};
