interface iRaffleV4Card {
  label: string;
  mainTxt: string;
  iconImg: string;
  showMainTxt: boolean;
  showImg: boolean;
  bgImg: string | null;
}

export const RaffleV4Card = (props: iRaffleV4Card) => {
  const { label = "label", mainTxt = "", iconImg = "", showMainTxt, showImg, bgImg } = props;

  return (
    <div
      className="w-[180px] h-[220px] bg-no-repeat mx-auto px-[18px] py-[18px]"
      style={{
        backgroundImage: "url(/assets/v4/raffle-card-bg.png)",
        backgroundSize: "cover",
      }}
    >
      <div
        className="relative w-[144px] h-[158px] bg-[#19191933] border-[1px] border-solid border-black-200 mb-space-2"
      >
        {bgImg && (
          <img
            src={bgImg}
            alt="prize-bg"
            className="absolute top-0 left-0 w-full object-cover"
            style={{
              aspectRatio: "0.91 / 1"
            }}
          />
        )}

        {iconImg && showImg && (
          <img
            src={iconImg}
            alt="prize-logo"
            className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[90%] object-cover aspect-square"
          />
        )}

        {mainTxt !== "" &&
          showMainTxt && (
            <p
              className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[90%] text-grey-600 text-center blender-medium text-[70px]"
              style={{ fontWeight: "700" }}
            >
              {mainTxt}{" "}
            </p>
          )}
      </div>
      <div
        className="text-l text-orange-800 text-center leading-[16px] overflow-hidden text-ellipsis whitespace-nowrap uppercase blender-medium"
        style={{
          fontWeight: 700
        }}
      >
        {label}
      </div>
    </div>
  );
};
