import { ContainerV3 } from "src/components/container-v3";
import { CaseOpeningBoxCardList } from "src/components/case-opening-box-card-list";
import { GoBack } from "src/components/go-back";
import { CaseOpeningMainArea } from "src/components/case-opening-main-area";
import { CaseOpeningRecentWinnings } from "src/components/case-opening-recent-winnings";
import { CaseOpeningPotentialPrizes } from "src/components/case-opening-potential-prizes";
import { CaseOpeningRouletteProvider } from "src/components/case-opening-roulette-provider";

export const CaseOpeningMysteryBox = () => {
  return (
    <ContainerV3>
      <div className="flex flex-col gap-space-2 md:gap-space-4 py-space-2 md:py-space-4">
        <div className="px-space-2 md:px-space-6 flex-1 flex flex-row justify-between items-center">
          <GoBack />
        </div>
        {/* Main Container */}
        <CaseOpeningRouletteProvider>
          <CaseOpeningMainArea />
        </CaseOpeningRouletteProvider>
        <div className="flex flex-col gap-space-4 mx-space-2 md:mx-space-6">
          {/* Recent Winnings */}
          <CaseOpeningRecentWinnings />
          {/* Potential Prizes */}
          <CaseOpeningPotentialPrizes />
          {/* Recommended */}
          <CaseOpeningBoxCardList
            type="geda"
            title={
              <div className="text-l md:text-xxxxxl text-grey-600 blender-medium font-[700] leading-none mb-space-4">
                Recommended
              </div>
            }
          />
        </div>
      </div>
    </ContainerV3>
  );
};
