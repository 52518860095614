import { ContainerV3 } from "src/components/container-v3";
import { GoBack } from "src/components/go-back";
import { FaHashtag } from "react-icons/fa6";
import { BsDiamondFill } from "react-icons/bs";

const Privacy = () => {
  return (
    <ContainerV3>
      <div className="p-space-2 md:px-space-6 md:py-space-4">
        <div className="mb-space-2 md:mb-space-4 flex items-center">
          <GoBack />
        </div>
        <h1 className="text-grey-600">Privacy Policy</h1>
        <p className="text-grey-600 mb-space-2">
          Date Last Modified: October 25, 2024
        </p>
        <article className="grid gap-space-2 text-grey-800">
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-4">
            <FaHashtag /> PRIVACY POLICY TERMS
          </h2>
          <p className="text-grey-800">
            The terms of this Privacy Policy apply to the use of the Website and
            Services offered by geda.com (hereinafter the: "GeDa", "Our", "We"
            or "Us").
          </p>
          <p className="text-grey-800">
            GeDa values the privacy of its online visitors to the Website and
            users of its Services and complies with applicable data protection
            legislation to safeguard your privacy in accordance with the General
            Data Protection Regulation (GDPR) of the European Union.
          </p>
          <p className="text-grey-800">
            We are committed to ensuring that Your Personal data is protected
            and handled responsibly. Our Privacy Policy outlines how We
            Processing (collect, use, disclose, shares and manage) Your Personal
            data when You interact with Our Website and Services. By accessing
            or using Our Website and Services, You consent to the processing of
            Your Personal data as described in this Privacy Policy."
          </p>
          <p className="text-grey-800">
            Your registration or authorization on the Website signifies Your
            acceptance of this Privacy Policy. If You disagree with this Privacy
            Policy, please refrain from using the Services and Website.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Scope of Privacy Policy
          </h2>
          <p className="text-grey-800">
            This Privacy Policy applies to all Services provided by GeDa and
            extends to all pages of the Website.
          </p>
          <p className="text-grey-800">
            Additionally, any other Services created by GeDa that provide a link
            to this Privacy Policy are bound by the regulations outlined herein.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-4">
            <FaHashtag /> Terms and definitions
          </h2>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" /> Personal data -
            </span>
            means any information relating to an identified or identifiable
            individual person - "Data subject".
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" /> Data subject -
            </span>
            means the individual person, whose personal data is processed
            (hereinafter: "Data subject", "You" or/and "User"). Processing -
            means any action or set of actions with personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" /> Profiling -
            </span>
            means any form of automated processing of personal data consisting
            of the use of personal data to evaluate certain personal aspects
            relating to a natural person, in particular to analyze or predict
            aspects concerning that natural person's performance at work,
            economic situation, health, personal preferences, interests,
            reliability, behavior, location or movements.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Controller -
            </span>
            means an individual or legal entity (and others) that determines the
            purposes and methods of personal data processing.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Processor -
            </span>
            means an individual or legal entity, who processes personal data on
            behalf of the controller.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Recipient -
            </span>
            means an individual or legal entity, public authority, agency or
            another body, to which the personal data are disclosed, whether a
            third party or not.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Third party -
            </span>
            means any another person, except the Subject of personal data, the
            Controller or Processor and the Data Protection Authority to whom
            the Controller or Processor transfers personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Consent of the data subject (hereinafter "Consent") -
            </span>
            means any freely given, specific, informed and unambiguous
            indication of the data subject’s wishes by which he or she, by a
            statement or by a clear affirmative action, signifies agreement to
            the processing of personal data relating to him or her.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Personal data breach -
            </span>
            means a breach of security leading to the accidental or unlawful
            destruction, loss, alteration, unauthorized disclosure of, or access
            to, personal data transmitted, stored or otherwise processed.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Co-controller -
            </span>
            means an individual or legal entity (among others) that, in
            collaboration with another controller, defines the purposes and
            methods of processing personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Data Protection Authority -
            </span>
            means a public organization or governmental body that safeguards
            data subjects from unauthorized processing of their data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legal basis for processing -
            </span>
            means one of the legally stipulated reasons for which the processing
            of personal data is permissible.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Cookies -
            </span>
            means small text files that contain any data. Cookies are stored on
            the PC of Data subject to provide access to various features.
            Cookies facilitate the operation of the Website and allow the
            Website to recognize Data subject’ device and store specific
            information about Data subject’ preferences or past actions.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Website -
            </span>
            means a set of interconnected web pages crafted using different
            programming languages. It is accessible on the internet through the
            address https://geda.com and is supervised by the controller.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Service -
            </span>
            means any paid and free existing and future services and offerings
            created by the GeDa team and provided on the website for use,
            including those that may be added, updated, or modified.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Platform -
            </span>
            means Website and Service together.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Social features -
            </span>
            means the social networks that GeDa owns: X, Discord, YouTube,
            Instagram, Twitch, etc.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Principles relating to processing of personal data
          </h2>
          <p className="text-grey-800">
            We handle Your Personal data responsibly and ensure compliance with
            data protection laws, making every effort to do so. Below, We
            outline the principles that guide us in Processing Your Personal
            data.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Principe of lawfulness, fairness, and transparency.
          </h2>
          <p className="text-grey-800">
            GeDa confirms that Your Personal data is processed lawfully, fairly
            and transparently in accordance with the principle of "lawfulness,
            fairness and transparency".
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Principe of purpose limitation.
          </h2>
          <p className="text-grey-800">
            GeDa is committed to the principle that Your Personal data must be
            collected for specified, explicit, and legitimate purposes and not
            further processed in a manner that is incompatible with those
            purposes.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Principe of data minimization.
          </h2>
          <p className="text-grey-800">
            GeDa strives to ensure that Your Personal data remains adequate,
            up-to-date, and confirms that such data should be limited to what is
            necessary for the purposes for which it is processed.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Principe of accuracy.
          </h2>
          <p className="text-grey-800">
            GeDa is dedicated to ensuring that Personal Data is accurate and
            when required regularly updated.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Principe of integrity and confidentiality.
          </h2>
          <p className="text-grey-800">
            GeDa strives to ensure that Your Personal data is processed in a
            manner that guarantees appropriate security, including protection
            against unauthorized or unlawful processing, as well as accidental
            loss, destruction or damage.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            What personal data does the GeDa process?
          </h2>
          <p className="text-grey-800">
            GeDa Processes various types of Your Personal data as part of
            providing Our Services on the Website. This Personal data may
            include, but is not limited to:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" /> Types of personal data
              collected.
            </span>
            Name and Surname, Date of birth, Country of residence, Registered
            address or actual place of residence; Personal photos; Email;
            Information about User social connections and contacts including
            friends, followers, and subscriptions; Payment card data, Bank
            account information, Payment amounts and payment methods used, Data
            on purchases, Transaction history; Device type, Unique device
            identifiers; Network data, Internet connection characteristics, IP
            address, Browser data; Operating system version; Steam64ID, Trade
            link; Profile information on the Website; Login frequency,
            achievements, match statistics, Gaming activity history on the
            Platform, Preferences and interests regarding gaming content, Types
            of items the User prefers to receive when opening cases, Game
            playtime data, Data on participation in various events, contests,
            tournaments, quizzes and other gaming events and any other Personal
            data that may be required to achieve the purposes and not prohibited
            by the applicable legislation.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Purpose of collection.
            </span>
            To identify the User, provide personalized services, and ensure
            account security; To analyze User behavior, improve Website
            functionality, and provide personalized content and Services; To
            process payments for Services and maintain financial records; To
            ensure the security and functionality of the Platform, as well as
            prevent fraud and abuse; To enhance social interaction within Our
            Platform, provide recommendations, and personalized content; To
            analyze the audience, personalize Services, and direct marketing
            efforts; To adapt content and Services to specific devices, ensure
            compatibility, and analyze resource usage; To provide functionality
            related to case opening and item exchange, as well as enhance User
            experience; To personalize offers and recommendations for case
            opening, improving the gaming experience; To analyze player
            behavior, determine preferences and interests, improve gameplay, and
            provide personalized recommendations; To facilitate the
            functionality of the item trading service, record transactions, and
            prevent fraud; To organize and conduct gaming events, award winners,
            and analyze User participation; To analyze User behavior, improve
            Service functionality, and provide personalized recommendations and
            offers; To analyze the effectiveness of marketing campaigns, provide
            personalized offers, and improve User experience; To detect and
            resolve Service functionality issues, improve User experience, and
            product development; To process payments for Services on the
            Platform, keep track of financial transactions, and provide Users
            with a history of their purchases; To address and resolve User
            inquiries, technical issues, and feedback, as well as improve
            customer support Services and User experience.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Source of collection.
            </span>
            Through your personal provision of such data during registration or
            profile activation, Through server logs or cookie files, Through
            your personal input of payment data during payment processing,
            Through your personal provision of such data when interacting with
            Our social accounts, Through automatic transmission of such data by
            your device to Our servers, Through the Steam platform, Through
            transaction logs associated with your account, Through your explicit
            consent to use Our Website and Services, Through your personal
            contact with Our Support, Through payment processing systems, and
            from Third parties.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              PROCESSING OF PERSONAL DATA FROM THIRD PARTIES.
            </span>
            We may receive Personal data about you from Third parties, including
            partners with whom we collaborate on joint services or marketing
            initiatives, as well as from publicly available sources such as
            social media platforms and public databases. This information is
            combined with the Personal data we already hold to enhance our
            services, update and expand our records, and better understand your
            preferences and interests. This allows us to tailor our offerings
            and provide you with more personalized and relevant products and
            services. We may also use this Personal data to optimize our
            advertising and commercial initiatives in line with your interests
            and preferences.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legal basis.
            </span>
            Your explicit consent to the processing of such information through
            registration or use of Our Website or Services, Based on legitimate
            interest, Based on the performance of necessary contractual
            obligations, Based on the need to comply with legislative
            requirements.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Terms.
            </span>
            The retention periods for the above information and personal data
            are fully regulated and comply with the applicable legal
            requirements for the particular case of personal data processing.
          </p>
          <p className="text-grey-800">
            These are just some examples of Personal data that We may Process.
            We strive to ensure that the Processing of Your Personal data
            complies with all applicable norms and data protection laws.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            How does the GeDa share personal data with others?
          </h2>
          <p className="text-grey-800">
            GeDa adheres to high standards of your privacy and data protection.
            In this section, We explain how We Process and make personal
            information available, provided by or collected through the use of
            Our Services and Website.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Partnerships.
            </span>
            We can share Your Personal data with partners We collaborate with to
            enhance Our Services and Website or provide additional features.
            These partners may include payment systems, analytical companies,
            and other services.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legal requirements.
            </span>
            In the event of receiving requests or other written inquiries from
            various government authorities: judicial, law enforcement,
            investigative, supervisory and others, in accordance with applicable
            law, We can provide access to Your Personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Marketing and advertising.
            </span>
            We use Personal data for marketing and advertising purposes,
            including joint actions with advertising partners for more accurate
            targeting of advertising campaigns.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Analytics and research.
            </span>
            Your Personal data may be used for analytical purposes, such as
            studying user behavior and improving Our Services and Website.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Payment processing and transactions.
            </span>
            Your Personal data may be transmitted to Third party payment systems
            and financial institutions for Processing payments and transactions.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Transmission in specific situations.
            </span>
            We can transfer Your Personal data by default to countries
            recognized by the EU Commission as providing an adequate level of
            protection, generally or partially, in specific industries.
          </p>
          <p className="text-grey-800">
            We take all necessary measures to ensure the security and
            confidentiality of Your Personal data when transferring them to
            Third parties. Before providing Your Personal data, it is important
            to carefully review Our Privacy Policy.
          </p>
          <p className="text-grey-800">
            Because we provide Our Services worldwide and Our Website is
            accessed through servers located in different regions, the
            information of a particular Data Subject may not be processed in the
            country in which that Data Subject resides. The level of data
            protection and legislation regarding the Processing of Personal Data
            may differ from country to country. For our part, regardless of
            where your Personal Data is processed, we adhere to all necessary
            security measures and legal requirements governing the Processing of
            Personal Data, including European Commission regulations, Swiss
            regulations and UK regulations.
          </p>
          <p className="text-grey-800">
            The European Commission has ruled that certain countries outside the
            European Economic Area (EEA) provide sufficient protection for
            personal information. This means that data can be transferred from
            European Union (EU) countries, Norway, Liechtenstein and Iceland to
            such countries. The UK and Switzerland have approved similar
            procedures for ensuring the sufficiency of measures. We in turn
            comply directly and without delay with the relevant regulations that
            govern the adequacy of such measures.
          </p>
          <p className="text-grey-800">
            By using Our Website and/or Services, You agree to the terms of this
            Privacy Policy. Your continued use of the Website and/or Services
            indicates Your ongoing consent to the Processing of your personal
            information as outlined in this document.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            How does the GeDa protect personal data?
          </h2>
          <p className="text-grey-800">
            GeDa takes the protection of Your Personal data very seriously and
            adheres to high standards of confidentiality. To protect Your
            Personal data on Website GeDa employs various methods and
            technologies:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Data encryption.
            </span>
            All data transmitted through the Website, including Your Personal
            data is encrypted using modern encryption methods. This helps
            prevent unauthorized access to the Personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Server protection.
            </span>
            GeDa maintains high security standards for its servers to prevent
            unauthorized access to stored Personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Monitoring and detection systems.
            </span>
            The GeDa Website is equipped with monitoring and detection systems
            that constantly track Your activity and identify any suspicious or
            anomalous actions.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Training employees in cybersecurity and data privacy during
              onboarding.
            </span>
            GeDa employees undergo training on data security and confidentiality
            to ensure proper handling and protection of Your Personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Compliance with legislation.
            </span>
            GeDa complies with all applicable laws and regulations related to
            the protection of personal data, including the General Data
            Protection Regulation (GDPR) in the European Union and other
            regional legislative acts.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Privacy Policy.
            </span>
            GeDa has a clear Privacy Policy that outlines what Personal data is
            collected, how it is used and how You can manage Your Personal data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Employees distribute access to personal data.
            </span>
            Employees are assigned access to personal data based on necessity.
            This practice ensures that employees only have access to the
            Personal data required for their specific job duties, following a
            need-to-know basis.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Distribute storing in different databases.
            </span>
            Storing data across multiple databases is a method used to enhance
            data resilience by avoiding reliance on a single point of storage.
            This strategy mitigates the risk of data loss or unavailability due
            to database failures or other unforeseen events, thereby
            strengthening overall data integrity and availability.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Integration with third party's software through official APIs.
            </span>
            Integration with third-party software via official APIs enables
            secure data exchange while minimizing the potential risks associated
            with unauthorized integration. These standardized interfaces
            facilitate secure and controlled interaction between applications,
            ensuring the reliable transmission of data. When integrating with
            third-party software, it's imperative for employees and contractors
            to exclusively utilize the official APIs provided by the third-party
            service. Any use of unauthorized integration methods or non-standard
            APIs is strictly forbidden.
          </p>
          <p className="text-grey-800">
            Overall, GeDa takes all necessary measures to ensure the security
            and confidentiality of Your Personal data, allowing Your to feel
            protected when using the Website.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            How does the GeDa delete personal data?
          </h2>
          <p className="text-grey-800">
            When it comes to deleting Personal data, GeDa follows a meticulous
            Process to ensure compliance with data protection regulations and to
            respect Users' privacy. The steps involved in deleting Personal data
            include:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Identification.
            </span>
            We identify the specific Personal data to be deleted, ensuring
            accuracy and completeness in the Process.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Verification.
            </span>
            Our team verifies the request for deletion to ensure it is
            legitimate and authorized by the User or in accordance with legal
            requirements.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Data removal.
            </span>
            Once verified, We proceed to remove the identified Personal data
            from Our systems, databases and any associated backups.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Permanent erasure.
            </span>
            We ensure that the deleted Personal data is permanently erased from
            Our storage systems to prevent any potential retrieval or
            unauthorized access.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Confirmation.
            </span>
            Upon completion of the deletion process, We provide confirmation to
            the User or Data Protection Authority, acknowledging the successful
            removal of their Personal data.
          </p>
          <p className="text-grey-800 pl-space-4 mt-space-4 font-bold">
            Types of termination of the grounds for personal data processing and
            conditions of exception:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Consent.
            </span>
            If GeDa Processes Personal Data based on the Data Subject’s consent
            and the Data Subject withdraws this consent, GeDa will promptly
            delete this Personal Data in accordance with the requirements of
            legislation provided by GDPR, which regulate procedures, deadlines,
            obligations, and other rights, as well as in accordance with the
            terms specified in this Privacy Policy.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Contractual obligation.
            </span>
            If GeDa Processes Personal Data based on a contract between GeDa and
            the Data Subject, or to establish such a contract and this contract
            is terminated or declined, GeDa will promptly delete this Personal
            Data in accordance with the requirements of legislation provided by
            GDPR, which regulate procedures, deadlines, obligations, and other
            rights, as well as in accordance with the terms specified in this
            Privacy Policy.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legal obligation.
            </span>
            If GeDa Processes Personal Data based on a legal obligation, GeDa
            will promptly delete this Personal Data after the legal obligation
            is cancelled in accordance with the requirements of legislation
            provided by GDPR, which regulate procedures, deadlines, obligations,
            and other rights, as well as in accordance with the terms specified
            in this Privacy Policy.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Vital interest.
            </span>
            If GeDa Processes Personal Data based on the Data Subject’s vital
            interest, GeDa will promptly delete this Personal Data after the
            vital interest ceases in accordance with the requirements of
            legislation provided by GDPR, which regulate procedures, deadlines,
            obligations, and other rights, as well as in accordance with the
            terms specified in this Privacy Policy.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legitimate interest.
            </span>
            If GeDa Processes Personal Data based on GeDa's legitimate interest
            and the Data Subject objects, GeDa will promptly delete this
            Personal Data after receiving the objection in accordance with the
            requirements of legislation provided by GDPR, which regulate
            procedures, deadlines, obligations, and other rights, as well as in
            accordance with the terms specified in this Privacy Policy.
          </p>
          <p className="text-grey-800 pl-space-4 mt-space-4 font-bold">
            Exceptions:
          </p>
          <p className="text-grey-800 pl-space-4">
            The above rules have the following exceptions:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Business needs.
            </span>
            Timeframes may be extended, but not exceeding 6 months, if deleting
            the data would interrupt GeDa's ongoing Services and Website;
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legal requirements.
            </span>
            The retention period may be extended if mandated by laws or
            governmental orders, in which case it will be prolonged accordingly;
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Technical impossibility.
            </span>
            Some information may be technically impossible or disproportionately
            difficult to delete, such as when deletion could compromise system
            integrity or information remains in backup copies. In such cases,
            the information may be retained;
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Anonymization.
            </span>
            The Data Subject’s data may be further processed for GeDa's purposes
            (e.g., marketing) if GeDa fully anonymizes the data after the
            retention period expires, ensuring deletion of all personal
            identifiers and connections.
          </p>
          <p className="text-grey-800 mt-space-4">
            By adhering to these rigorous procedures, We uphold Our commitment
            to safeguarding Users' Personal data and promoting trust and
            transparency in Our data management practices.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            The rights of personal data subjects
          </h2>
          <p className="text-grey-800">
            GeDa is dedicated to safeguarding the rights of Data subjects
            concerning the information gathered about them. As such, GeDa must
            guarantee that the rights of the Data subjects, whose information is
            retained, can be fully exercised. In accordance with current
            legislation, You have the following rights, in particular, in
            accordance with legal requirements:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right to receive information about personal data.
            </span>
            You have the right to receive information about Your Personal data
            held by Us at any time.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right of access by the data subject.
            </span>
            It means that You have the right to obtain a copy of all the
            Personal data We have collected about You based on a request that is
            reasonable and in accordance with the law.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right fo rectification.
            </span>
            When handling Your Personal data, We will strive to ensure its
            accuracy and relevance for the intended purposes through suitable
            measures. Should Your Personal data be inaccurate or incomplete, You
            have the right to request its correction.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right to erasure ("Right to be forgotten").
            </span>
            You may also have the right to request the deletion or restriction
            of Processing of Your Personal data if You deem it necessary. At the
            same time, We will review Your request on this matter and, in
            accordance with the law, make a lawful decision or any other
            decision not prohibited by law.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right to restriction of processing.
            </span>
            The right to control and limit how one's information is processed
            extends to all Parties handling the Personal data, including
            recipients of that data.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right to data portability.
            </span>
            You shall have the right to receive the Personal data concerning
            You, which You have provided to Us, in a structured, commonly used
            and machine-readable format and have the right to transmit those
            data to another controller without hindrance from Us.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right of withdrawal of consent.
            </span>
            If You have consented to the collection, Processing and use of Your
            Personal data, You may withdraw Your consent at any time with a
            future action, but the lawfulness of the Processing carried out on
            the basis of the consent up to the time of withdrawal will not be
            affected.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right to object.
            </span>
            If information is Processed based on a legal basis other than
            Consent, You have the right to object or halt the Processing if
            deemed appropriate.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Automated individual decision-making, including profiling.
            </span>
            You have the right not to be subject to a decision based solely on
            automated processing, including Profiling, which produces legal
            effects concerning for You or similarly significantly affects for
            You.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="inline-flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Right to the protection of the rights.
            </span>
            You also have the right to lodge a complaint with the competent
            supervisory authority if You believe that the Processing of Your
            Personal data is not lawful.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Cookies policy
          </h2>
          <p className="text-grey-800">
            Please carefully review this Cookie Policy to learn more about the
            Cookies We place and the information We collect when You use our
            Website. Our Сookies policy applies only to Our Website, so We
            recommend that You review the cookie policy on other websites You
            visit.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            User settings related to cookies.
          </h2>
          <p className="text-grey-800">
            Please note that Your refusal will not affect the use of the most
            essential Сookies, which are mandatory for the operation of this
            Website. You can disable the use of Сookies at the browser level.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Cookies that are used on Our Website
          </h2>
          <p className="text-grey-800">
            We utilize Cookies on Our Website to enhance Your browsing
            experience. These Cookies serve various purposes such as remembering
            Your preferences, facilitating navigation between pages, and
            ensuring the proper functioning of essential features. Our Cookies
            fall into several categories:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Essential cookie.
            </span>
            These are indispensable for the operation of Our Website. They
            enable access to secure areas and authorization processes.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Performance and analytics cookies.
            </span>
            These gather data on Your Website usage, including which pages You
            visit most frequently and any encountered errors. This information
            aids Us in assessing content effectiveness and improving Website
            performance.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Functionality and customization cookies.
            </span>
            These remember Your preferences and customizations, such as language
            and currency settings, as well as site sound preferences.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Security cookies.
            </span>
            These support security features and help identify and mitigate
            malicious activity.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Third-party's cookies.
            </span>
            We might also utilize Third Party Cookies from trusted service
            providers to improve Our Website and Services. These Cookies assist
            us in analyzing usage patterns on Our Website and delivering content
            from Third Party sources. These Сookies, for example, can be
            analytics from Google Inc.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Google Analytics.
            </span>
            We use Google Analytics, a web analytics service offered by Google
            Inc. Google Analytics utilizes Cookies to analyze user interactions
            with Our Website. To find out more about Google Analytics' privacy
            policy, please visit the{" "}
            <a
              className="text-grey-800 underline"
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Google Analytics Privacy Policy
            </a>{" "}
            page.
          </p>
          <p className="text-grey-800 pl-space-4">
            If You would like to opt-out of tracking with Google Analytics,
            please visit this{" "}
            <a
              className="text-grey-800 underline"
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
            .
          </p>
          <p className="text-grey-800 mt-space-4">
            This Personal data may include:
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Personal data collected.
            </span>
            IP address, referrer ID, timestamps, browser type, user agent.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Purpose of processing.
            </span>
            The purpose of the Personal data Processing is to evaluate the use
            of the Website, to compile reports on Website activities or user
            behavior and to provide Us with further services related to the use
            of the Website and the Internet.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Legal basis.
            </span>
            Consent.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Terms.
            </span>
            During the period of Your use of the Website until certain purposes
            are achieved and as well as in specific cases within the timeframe
            established by GDPR.
          </p>
          <p className="text-grey-800 pl-space-4">
            <span className="flex gap-1 items-center mr-1 font-bold">
              <BsDiamondFill className="w-[8px]" />
              Possibility to withdraw consent and deletion.
            </span>
            You have the right to withdraw Your declaration of Consent at any
            time in accordance with data protection legislation. Withdrawal of
            Consent does not affect the lawfulness of processing carried out on
            the basis of consent up to the time of withdrawal.
          </p>
          <p className="text-grey-800 mt-space-4">
            You can disable the use of Cookies at the browser level.
            Instructions on how to manage and delete Cookies can be found in
            Your browser's help.
          </p>
          <p className="text-grey-800">
            Please note that certain parts and functionalities of Our Websites
            may not work properly if You disable Cookies completely.
          </p>
          <p className="text-grey-800">
            We reserve the right to update this Cookie information to reflect
            changes in Our use of Cookies. Any revised policy will be published
            with the date of the last update. Therefore, We recommend that You
            regularly review it.
          </p>
          <p className="text-grey-800">
            By using Our Website, You acknowledge that You have read and
            understood the information provided regarding the use of Cookies and
            agree to these terms. Your continued use of Our Website indicates
            Your consent to this information.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Privacy Policy update
          </h2>
          <p className="text-grey-800">
            GeDa reserves the right to amend this Privacy Policy at any time.
          </p>
          <p className="text-grey-800">
            GeDa will notify you of any changes by updating this Privacy Policy,
            publishing the revised Privacy Policy, and/or through other means of
            communication.
          </p>
          <p className="text-grey-800">
            Please ensure that you review the updated Privacy Policy. By
            continuing to use the Website and/or Services after GeDa has
            published a revised Privacy Policy, you consent to be bound by the
            modified Privacy Policy.
          </p>
          <p className="text-grey-800">
            If you do not agree to the updated Privacy Policy, you shall
            discontinue using the Website or Services.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Applicable law
          </h2>
          <p className="text-grey-800">
            The Privacy Policy is designed to comply with applicable law,
            including the provisions of the European Union General Data
            Protection Regulation (GDPR).
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Confirmation of acceptance of the Privacy Policy
          </h2>
          <p className="text-grey-800">
            By using the Our Website and/or Services, you confirm that you have
            previously agreed to and accepted the terms of this Privacy Policy.
            Your continued use of the Website and/or Services reaffirms your
            ongoing consent to the processing of your personal information in
            accordance with the provisions outlined herein.
          </p>
          <h2 className="text-orange-800 flex gap-space-1 m-0 mt-space-8">
            <FaHashtag />
            Data controller details
          </h2>
          <p className="text-grey-800">
            The controller of personal data is geda.com.
          </p>
          <p className="text-grey-800">
            More details about the Data Controller are specified in the relevant
            section of the Terms of Use.
          </p>
        </article>
      </div>
    </ContainerV3>
  );
};

export { Privacy };
