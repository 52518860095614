import { useState } from "react";
import { ContainerV3 } from "src/components/container-v3";
import { CaseOpeningBoxCardList } from "src/components/case-opening-box-card-list";
import { ICaseOpeningFilters } from "src/types/case-opening";
import { CaseOpeningFilter } from "src/components/case-opening-filter";
import { Leaderboard } from "src/components/leaderboard";

export const MarketplaceMysteryBoxList = () => {
  const [filters, setFilters] = useState<ICaseOpeningFilters>({});

  return (
    <ContainerV3>
      <div className="flex flex-col md:flex-row p-space-2 md:p-space-4 items-stretch justify-start">
        <div className="md:px-space-4 flex-1 flex flex-col relative ">
          <div className="flex pb-space-4 ">
            <h1 className="text-l md:text-xxxxxl blender-medium text-grey-600 font-[900] leading-none">
              Case Opening
            </h1>
          </div>
          <div className="flex absolute right-0 z-[5001] mt-space-4 ">
            <Leaderboard />
          </div>

          {/* Filtering row */}
          <CaseOpeningFilter filters={filters} setFilters={setFilters} />
          {/* Case Opening Box Card List */}
          <div className="grid gap-space-6">
            <CaseOpeningBoxCardList
              type="usdt"
              title={
                <div className="flex items-center mb-space-4">
                  <img
                    src="/assets/v4/geda-star.png"
                    className="w-[14px] h-[14px] mr-space-1"
                    alt=""
                  />
                  <p className="text-l md:text-xxml blender-medium font-[900] uppercase leading-none">
                    Geda Case (Star)
                  </p>
                </div>
              }
              filters={filters}
            />
            <CaseOpeningBoxCardList
              type="max"
              title={
                <div className="flex items-center mb-space-4">
                  <img
                    src="/assets/v4/case-opening-matr1x-coin-icon.png"
                    className="w-[14px] h-[14px] mr-space-1"
                    alt=""
                  />
                  <p className="text-l md:text-xxml blender-medium font-[900] uppercase leading-none">
                    MATR1X Case (GMAX)
                  </p>
                </div>
              }
              filters={filters}
            />
          </div>
        </div>
      </div>
    </ContainerV3>
  );
};
