import { GamePredictionRowV2 } from "./game-prediction-row-v2";

export const GamePredictionV2 = (props: any) => {
  const { predictionList, address, fetchGame, tab, showEnded } = props;

  if (!predictionList) return null;

  return (
    <div className="flex flex-col flex-nowrap w-full gap-space-4">
      {predictionList.map((item: any, i: any) => {
        return (
          <div key={i} className="w-full">
            <p className="text-s sm:text-l text-grey-100 text-center leading-[14px] sm:leading-[19px] !font-[900] blender-medium mb-space-2 sm:mb-space-4">
              {item?.name}
            </p>
            <div className="flex flex-col gap-space-4 sm:gap-space-5">
              {item?.games.map((game: any, q: number) => {
                if (!showEnded) {
                  if (game?.result) return null;
                }
                return (
                  <GamePredictionRowV2
                    game={game}
                    address={address}
                    fetchGame={fetchGame}
                    tab={tab}
                    key={q}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
