import { history } from "src/stores";
import { IGameSeries } from "src/types/prediction";
import { renderTag } from "src/utils/tag";

interface IGameSeriesItem {
  item: IGameSeries;
}

export const GameSeriesItem = (props: IGameSeriesItem) => {
  const { item } = props;

  return (
    <div
      className="rounded-xs p-space-2 min-h-[225px] relative flex flex-col justify-between cursor-pointer"
      style={{
        backgroundImage: `url("${item?.image}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      onClick={() => history.push(`/prediction/${item?.game_id}`)}
    >
      <div className="flex flex-row gap-space-2 items-start justify-between relative z-[5]">
        <div>
          <h3 className="uppercase text-l text-grey-100 leading-[19px] blender-medium mb-0">{item?.name}</h3>
          {!item?.is_ended && (
            <div className="flex flex-row items-center gap-space-1">
              <div
                className={`rounded-full w-[8px] h-[8px] ${
                  item?.is_live
                    ? "bg-native-red"
                    : "bg-native-blue"
                }`}
              />
              <p className="text-s text-grey-400 leading-[14px] sm:leading-[24px] blender-medium">
                {item?.is_live ? "Live" : "Upcoming"}
              </p>
            </div>
          )}
        </div>
        {/* Tag */}
        {renderTag(item?.tags[0])}
      </div>

      {/* Bottom */}
      <div className="flex flex-col gap-space-2">
        {item?.is_live && (
          <div
            className="w-full px-space-2 py-space-1 rounded-xs border border-native-red text-center"
            style={{
              background:
                "linear-gradient(90deg, rgba(161, 9, 55, 0.8) 0%, rgba(212, 12, 72, 0.8) 75%, rgba(110, 6, 37, 0.8) 100%)",
            }}
          >
            <p className="uppercase text-s text-grey-200 leading-[18px] blender-medium">Live</p>
          </div>
        )}

        <div className="border rounded-xs bg-black-800 border-orange-800 cursor-pointer">
          <p className="text-l text-orange-800 text-center leading-[19px] blender-medium px-space-4 py-space-2">
            View Series
          </p>
        </div>
      </div>

      {/* Shadow */}
      <div className="absolute top-0 left-0 w-full bg-gradient-to-b from-[#000]/50 to-[#000]/0 h-[100px]" />
    </div>
  );
};
