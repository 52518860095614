import { useState } from "react";
import clsx from "clsx";
import { AiOutlineComment } from "react-icons/ai";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaTwitch } from "react-icons/fa6";

interface ILinkIcon {
  name: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  styles: {
    text: string;
    bg: string;
    size: string;
  };
}

const SNSs: ILinkIcon[] = [
  {
    name: "x",
    href: "https://x.com/GEDAEsports",
    icon: FaXTwitter,
    styles: {
      text: "text-white hover:text-white",
      bg: "bg-black",
      size: "w-[24px] h-[24px]",
    },
  },
  {
    name: "discord",
    href: "https://discord.com/invite/gedaesports",
    icon: FaDiscord,
    styles: {
      text: "text-white hover:text-white",
      bg: "bg-[#5865F2]",
      size: "w-[24px] h-[24px]",
    },
  },
  {
    name: "youtube",
    href: "https://www.youtube.com/@gedaesports",
    icon: FaYoutube,
    styles: {
      text: "text-white hover:text-white",
      bg: "bg-[#FF0000]",
      size: "w-[24px] h-[24px]",
    },
  },
  {
    name: "twitch",
    href: "https://www.twitch.tv/gedaesports",
    icon: FaTwitch,
    styles: {
      text: "text-[#9147FF] hover:text-[#9147FF]",
      bg: "bg-white",
      size: "w-[22px] h-[22px]",
    },
  },
];

const LinkIcon = ({ href, icon: Icon, styles }: ILinkIcon) => {
  return (
    <a
      className={clsx(
        "flex w-[36px] h-[36px] items-center justify-center",
        "shadow-md rounded-full",
        "transition-transform	ease-in-out hover:scale-125",
        styles.bg,
        styles.text
      )}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Icon className={clsx(styles.size)} />
    </a>
  );
};

const SNSBar = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <div
      className={clsx(
        "sns-bar",
        "w-full absolute bottom-full flex flex-col items-center gap-space-3 mb-space-3",
        "opacity-0 transition-all duration-300 ease-in-out",
        "md:w-[68px] md:relative md:bottom-auto md:opacity-100 md:mt-space-8",
        {
          "opacity-100": isOpen,
          "invisible md:visible": !isOpen,
        }
      )}
    >
      {SNSs.map((sns) => (
        <LinkIcon key={sns.name} {...sns} />
      ))}
    </div>
  );
};

const QuickSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={clsx(
        "quick-sidebar",
        "fixed right-3 bottom-[95px] z-[1000]",
        "md:right-0 md:top-[300px] md:w-[114px] md:h-[311px] md:flex md:bg-contain md:bg-[url('/assets/v4/sidebar.png')]",
        "md:transition-transform",
        {
          "md:translate-x-[68px]": !isOpen,
        }
      )}
    >
      <div
        className={clsx(
          "group/wapper",
          "md:hidden w-[52px] aspect-square flex items-center justify-center",
          "bg-orange-800 rounded-full cursor-pointer"
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <AiOutlineComment className="w-[36px] h-[36px] text-grey-100 group-hover/wapper:animate-wiggle animate-infinite animate-ease-in-out" />
      </div>
      <p
        className={clsx(
          "text-l cursor-pointer",
          "w-[110px] absolute right-full bottom-[14px]",
          "md:w-auto md:relative md:right-auto md:bottom-auto md:self-start md:py-[48px] md:pt-[14px] md:px-[10px]",
          "md:[writing-mode:vertical-rl] md:rotate-180 md:origin-center",
          "animate-once animate-ease-in-out",
          {
            "animate-flip-up md:animate-none": isOpen,
            "invisible md:visible": !isOpen,
          }
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        CONTACT US
      </p>
      <AiOutlineDoubleLeft
        className={clsx(
          "hidden md:block absolute left-[10px] top-[2px] w-[24px] h-[24px] text-white pointer-events-none",
          "animate-infinite animate-duration-[2000ms] animate-ease-out",
          {
            invisible: isOpen,
            "animate-fade-left -left-[4px]": !isOpen,
          }
        )}
      />
      <AiOutlineDoubleRight
        className={clsx(
          "hidden md:block absolute left-[10px] top-[2px] w-[24px] h-[24px] text-white pointer-events-none",
          "animate-infinite animate-duration-[2000ms] animate-ease-out",
          {
            "animate-fade-right left-[20px]": isOpen,
            invisible: !isOpen,
          }
        )}
      />
      <SNSBar isOpen={isOpen} />
    </div>
  );
};

export { QuickSidebar };
