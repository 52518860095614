import clsx from "clsx";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { getRarityColor } from "src/utils/color";
import { formatName, formatPrice } from "src/utils";
import { history, useMarketplaceStore } from "src/stores";
import { BsArrowRepeat } from "react-icons/bs";
import { CaseOpeningModal } from "src/components/case-opening-modal";
import { useState } from "react";
import { useAuth } from "./auth-context";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { LoadingIconV2 } from "./loading-icon-v2";
import { getCurrencyIcon } from "src/utils/image";
interface ICaseOpeningBoxSingleResultModal {
  className?: string;
  prize: IMysteryBoxPrizeDetail;
  isVisible: boolean;
  onClose: () => void;
  onRetry: () => void;
  bonus: number;
}

const CaseOpeningBoxSingleResultModal = ({
  className,
  prize,
  isVisible,
  onClose,
  onRetry,
  bonus,
}: ICaseOpeningBoxSingleResultModal) => {
  const {
    name = "",
    quality = "",
    property,
    weaponPrice = null,
    imgUrl = "",
    type,
    sellCurrency,
    weaponConvertPrice,
  } = prize || {};
  const [loading, setLoading] = useState<boolean>(false);
  const rarityColor = getRarityColor(quality);
  const calculatedPrice = formatPrice(
    (sellCurrency && sellCurrency !== "USDT"
      ? weaponConvertPrice
      : weaponPrice) || 0
  );

  const { sellItemsById: sellWeapons } = useMarketplaceStore();
  const { getDashboardProfileCb } = useAuth();

  const handleGoToInventory = () => {
    history.push("/dashboard#case");
  };

  const handleSellItem = async () => {
    if (loading) return;

    setLoading(true);
    const result = await sellWeapons({ ids: [prize?._id] });
    setLoading(false);
    if (!result) return openWarningNotification("Failed to sell item.");

    getDashboardProfileCb();
    openSuccessNotification("Item is sold!");
    return onClose();
  };

  return (
    <CaseOpeningModal
      className={className}
      isVisible={isVisible}
      title={<span className="mt-space-2 block">Your Drop</span>}
      bonus={bonus}
      content={
        <div className="relative w-full h-[300px] flex justify-center items-center">
          <img
            className="absolute w-[90%] md:w-auto md:h-full"
            src={imgUrl}
            alt={name}
          />
          <div className="absolute w-full h-full grid md:block pt-space-2 md:pt-space-4 px-space-7">
            {/* [Mobile] Weapon Information */}
            <div className="md:hidden">
              <div className="mb-space-1 text-l text-grey-100 leading-none">
                {formatName(name)}
              </div>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <div
                    className="rounded-full w-[8px] h-[8px] mr-1"
                    style={{ backgroundColor: rarityColor }}
                  />
                  <p className="text-s text-gray-200 leading-none">
                    {formatName(quality)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Loading Indicator */}
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
              <LoadingIconV2 />
            </div>
          )}
        </div>
      }
      footerClassName="pt-space-2 mx-space-4 md:mx-[25px] px-space-2"
      footer={
        <div className="-translate-y-[28px] md:translate-y-0 w-full grid md:flex md:items-stretch md:max-h-[138px] md:gap-space-4">
          {/* Left weapon property area */}
          <div className="hidden md:block basis-1/2 shrink overflow-y-auto max-h-[138px] scrollbar-hide">
            <div className="mb-space-2 text-xxml text-grey-100 leading-none">
              {formatName(name)}
            </div>
            <div className="grid grid-cols-2 grid-rows-5 gap-space-2 text-s text-grey-800 leading-none">
              <span>Weapon Class:</span>
              <span className="text-grey-200 justify-self-end uppercase">
                {type}
              </span>
              <span>Rarity:</span>
              <div className="text-grey-200 justify-self-end flex items-center">
                <div
                  className="rounded-full w-[8px] h-[8px] mr-1"
                  style={{ backgroundColor: rarityColor }}
                />
                <p className="text-s text-gray-200">{formatName(quality)}</p>
              </div>
              <span>Wear rating:</span>
              <span className="text-[#48C1D9] justify-self-end uppercase">
                {property}
              </span>
            </div>
          </div>
          {/* Dividing */}
          <div className="hidden md:flex basis-[1px] shrink-0 case-opening-dividing-line" />
          {/* Right buttons area */}
          <div className="md:basis-1/2 md:shrink grid grid-cols-2 grid-rows-[16px_38px_38px] gap-space-2 md:gap-xs">
            <div className="text-l text-grey-100 leading-none">SELL ITEM:</div>
            <div className="flex items-center justify-end">
              <img
                src={getCurrencyIcon(sellCurrency || "USDT")}
                className="w-[14px] h-[14px] mr-space-1"
                alt=""
              />
              <span className="text-native-green text-l leading-none">
                {calculatedPrice}
              </span>
            </div>
            <div
              className={clsx(
                "case-opening-sell-btn-green",
                "col-span-full",
                "w-full h-full flex items-center justify-center",
                "border rounded-xs border-native-green text-native-green text-l cursor-pointer"
              )}
              onClick={() => handleSellItem()}
            >
              Sell
            </div>
            <div
              className={clsx(
                "group/retry w-full h-full flex items-center justify-center gap-space-1",
                "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-l cursor-pointer"
              )}
              onClick={onRetry}
            >
              <BsArrowRepeat className="text-grey-400 transition-transform duration-300 group-hover/retry:rotate-180" />
              Try Again
            </div>
            <div
              className={clsx(
                "w-full h-full flex items-center justify-center gap-space-1",
                "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-l cursor-pointer"
              )}
              onClick={handleGoToInventory}
            >
              My inventory
              <img
                className="w-[10px] h-[10px]"
                src="/assets/v4/dashboard.png"
                alt=""
              />
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

export { CaseOpeningBoxSingleResultModal };
