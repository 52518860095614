import { useState, useEffect } from "react";

interface ILeaderboardRow {
  item: any;
}

const tStyle = "blender-medium font-[500] text-s text-grey-100";

const getBackgroundColor = (index: number) => {
  if (index === 1) return "#FFC71E1A";
  if (index === 2) return "#C5C5C51A";
  if (index === 3) return "#C36D481A";
  return "#191919CC";
};
export const LeaderboardRow = (props: ILeaderboardRow) => {
  const { item } = props;
  const [hasLine, setHasLine] = useState(false);
  useEffect(() => {
    if (item.rank > 6 && !hasLine) {
      setHasLine(true);
    }
  }, [item.rank, hasLine]);
  return (
    <div>
      {item.rank > 6 && hasLine && (
        <div className="flex justify-center w-full">
          <img
            src="/assets/v4/leaderboard-line.png"
            alt="ranking"
            className="w-[344px]  mb-space-2"
          />
        </div>
      )}
      <div
        className={`flex items-center h-[24px] ${
          item.rank > 6 ? "border-[1px] border-orange-800" : "mb-space-2"
        }`}
        style={{
          backgroundColor: getBackgroundColor(item.rank),
          borderRadius: "16px",
        }}>
        <div className="flex justify-start items-center ">
          <img src={item.avatar} alt="avatar" className="w-[24px] h-[24px]" />
        </div>

        <div className={`flex items-center ml-space-5 sm:ml-space-6 ${tStyle}`}>
          <span>{item.rank}</span>
          {item.rankImage ? (
            <img
              src={item.rankImage}
              alt="ranking"
              className="w-[16px] h-[16px] ml-xs sm:ml-space-5 "
            />
          ) : (
            <div className="w-[16px] h-[16px] ml-xs sm:ml-space-5"></div>
          )}
        </div>

        <div
          className={`flex flex-1 ${tStyle} justify-start ml-space-6 sm:ml-space-7 `}>
          {item.displayName}
        </div>
        <div className={`flex flex-1 justify-center ${tStyle}`}>
          {item.totalAmount}
        </div>
      </div>
    </div>
  );
};
