interface IPredictionV4GameStream {
  streamUrl: string;
}

export const PredictionV4GameStream = (props: IPredictionV4GameStream) => {
  const { streamUrl } = props;
  return (
    <div className="md:px-space-2 md:py-space-4 gap-space-2 md:gap-space-4 flex flex-col">
      <h2 className="text-l sm:text-xxml text-grey-100 leading-[19px] sm:leading-[28px] !font-[900] blender-medium mb-0">Live Stage</h2>

      <iframe
        className="min-h-[200px] xs:min-h-[400px] md:min-h-[538px] rounded-xs"
        src={streamUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={"GeDa Live"}
      />
    </div>
  );
};
