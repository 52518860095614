import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useAuthStore } from "src/stores";
import { IProfile } from "src/types/user";
import { ModalConnectAddress } from "./modal-connect-address";
import { ModalLogin } from "./modal-login";
import { IDashboardProfile } from "src/types/dashboard";

interface IAuthContext {
  loggedIn: boolean;
  setLoggedIn: (val: boolean) => void;
  userInfo: IProfile | null;
  setUserInfo: (val: IProfile | null) => void;
  openLoginModal: boolean;
  setOpenLoginModal: (val: boolean) => void;
  setOpenConnectAddressDialog: (val: boolean) => void;
  getUserProfileCb: () => void;
  dashboardData: IDashboardProfile | null;
  setDashboardData: (val: IDashboardProfile | null) => void;
  getDashboardProfileCb: () => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{
  loggedIn: boolean;
  userInfo: IProfile | null;
  setLoggedIn: (val: boolean) => void;
  setUserInfo: (val: IProfile | null) => void;
  openLoginModal: boolean;
  setOpenLoginModal: (val: boolean) => void;
  children: ReactNode;
}> = ({
  loggedIn,
  setLoggedIn,
  userInfo,
  setUserInfo,
  openLoginModal,
  setOpenLoginModal,
  children,
}) => {
  const [openConnectAddressDialog, setOpenConnectAddressDialog] =
    useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<IDashboardProfile | null>(
    null
  );

  const { getProfileV2 } = useAuthStore();
  const { getDashboardProfile } = useAuthStore();

  const getDashboardProfileCb = useCallback(() => {
    if (!loggedIn) return;
    getDashboardProfile().then((result: any) => {
      if (!result) return;
      setDashboardData(result);
    });
  }, [getDashboardProfile, loggedIn]);

  const getUserProfileCb = useCallback(async () => {
    const result = await getProfileV2();
    if (!result || typeof result === "string") return;
    setUserInfo(result);
    setLoggedIn(true);
  }, [getProfileV2, setLoggedIn, setUserInfo]);

  // Get profile on init
  useEffect(() => {
    const gedaToken = localStorage.getItem("geda-token");
    if (!gedaToken) {
      setLoggedIn(false);
      setUserInfo(null);
      return;
    }

    getUserProfileCb();
    getDashboardProfileCb();
  }, [getUserProfileCb, setLoggedIn, setUserInfo, getDashboardProfileCb]);

  // Check profile - to see if user needs address integration
  useEffect(() => {
    if (localStorage.getItem("_geda_show_connect_wallet_modal") === "no")
      return;
    if (!userInfo) return;
    if (!userInfo?.address) setOpenConnectAddressDialog(true);
  }, [userInfo]);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        userInfo,
        setLoggedIn,
        setUserInfo,
        openLoginModal,
        setOpenLoginModal,
        setOpenConnectAddressDialog,
        getUserProfileCb,
        dashboardData,
        setDashboardData,
        getDashboardProfileCb,
      }}
    >
      {children}

      {openConnectAddressDialog && (
        <ModalConnectAddress
          isModalOpen={openConnectAddressDialog}
          setIsModalOpen={setOpenConnectAddressDialog}
        />
      )}

      {/* Modals */}
      {openLoginModal && (
        <ModalLogin onCancel={() => setOpenLoginModal(false)} />
      )}
    </AuthContext.Provider>
  );
};
